import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import Flag from "../components/Flag";
import SearchChoice from "../components/SearchChoice";
import Song from "../pageElements/Song";
import FileUploader from "../components/FileUploader";
import { fileReader } from "../scripts/fileReader";

function Voting() {
  const jurySystem = {
    0: [12, 10, 8, 7, 6, 5, 4, 3, 2, 1],
    1: [12, 8, 6, 4, 2, 1],
  };
  const { countries, setLoading } = useContext(GlobalContext);
  const [votingInfo, setVotingInfo] = useState({ status: 0 });
  const [votingSettings, setVotingSettings] = useState({
    jury: {},
    tele: null,
  });
  const [image, setImage] = useState({ image: null, file: null });

  console.log(votingInfo);

  const handleJuryVoting = () => {
    setLoading(true);
    if (
      votingInfo.current_show_type === 3 &&
      image.file &&
      image.file instanceof File
    ) {
      let dataToSend = new FormData();
      dataToSend.append("image", image.file, image.file.name);

      api(
        process.env.REACT_APP_API_URL + "upload-spokesperson",
        null,
        dataToSend
      ).then((response) => {
        if (response.positive) {
          api(
            process.env.REACT_APP_API_URL + "cast-jury-vote",
            null,
            votingSettings.jury
          ).then((response) => {
            setLoading(false);
            window.location.reload();
          });
        } else {
          setLoading(false);
        }
      });
    } else {
      api(
        process.env.REACT_APP_API_URL + "cast-jury-vote",
        null,
        votingSettings.jury
      ).then((response) => {
        setLoading(false);
        window.location.reload();
      });
    }
  };
  const handleTeleVoting = () => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "cast-televote",
      null,
      votingSettings.tele
    ).then((response) => {
      setLoading(false);
      window.location.reload();
    });
  };
  useEffect(() => {
    setLoading(true);
    api(process.env.REACT_APP_API_URL + "get-voting-status").then(
      (response) => {
        setLoading(false);
        if (response.positive) {
          setVotingInfo(response.responseData);
          setVotingSettings({
            tele: response.responseData.participants.map((element) => {
              return {
                running_order: element.participation_running_order,
                country_id: element.country_id,
                country_name: element.country_name,
                checked: false,
              };
            }),
            jury: {},
          });
        }
      }
    );
  }, []);
  return (
    <>
      {votingInfo.status ? (
        <>
          {votingInfo.voted ? (
            <div className="defaultFormContainer">
              <div
                className="defaultForm"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <section className="defaultSection">
                  <section className="defaultSection">
                    <p className="hugeHeadline">THANK YOU FOR VOTING!</p>
                  </section>
                  <div className="regularText">
                    You or one of your teammates already voted. Thank you!
                  </div>
                </section>
                <section className="defaultSection">
                  <Link to="/main" className="button1">
                    Main Page
                  </Link>
                </section>
              </div>
            </div>
          ) : (
            <>
              {votingInfo.status === 1 ? (
                <div className="defaultFormContainer">
                  <div
                    className="defaultForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <section className="defaultSection">
                      <section className="defaultSection">
                        <p className="hugeHeadline">
                          {votingInfo.current_show_type === 1
                            ? "VOTING"
                            : "JURY VOTING"}
                        </p>
                      </section>
                      <div className="regularText">
                        {votingInfo.current_show_type === 1
                          ? "Voting"
                          : "Jury voting"}{" "}
                        in the{" "}
                        {votingInfo.current_show_type === 1
                          ? "Heat"
                          : votingInfo.current_show_type === 2
                          ? "Semi Final"
                          : votingInfo.current_show_type === 3
                          ? "the Grand Final"
                          : null}{" "}
                        {votingInfo.current_show_number !== 0 &&
                          votingInfo.current_show_number}{" "}
                        of the edition {votingInfo.edition} of {process.env.REACT_CONTEST_FULL || ""} is open! 
                        Give points to your favourites.
                        Remember you can't vote for your country if it is
                        participating!
                      </div>
                    </section>
                    {votingInfo.country ? (
                      <section className="defaultSection headInfo">
                        You vote as:{" "}
                        <p className="votingCountry">
                          <Flag image={votingInfo.country} />
                          {
                            countries.find(
                              (country) =>
                                country.country_id === votingInfo.country
                            ).country_name
                          }
                        </p>
                      </section>
                    ) : (
                      <>
                        {votingInfo.current_show_type === 1 ? (
                          <section className="defaultSection headInfo">
                            You vote as televoter
                          </section>
                        ) : (
                          <section className="defaultSection headInfo">
                            You vote as backup jury
                          </section>
                        )}
                      </>
                    )}
                    <section className="defaultSection">
                      {jurySystem[votingInfo.jury_type].map((element) => {
                        return (
                          <div className="defaultCenteredContainer">
                            <div className="adminRO">{element}</div>
                            <SearchChoice
                              key={element}
                              nameColumn="country_name"
                              keyColumn="country_id"
                              passToChildKey="country_id"
                              data={votingInfo.participants}
                              passingFunction={(item) => {
                                const newSettings = votingSettings;
                                newSettings.jury[element] = item.country_id;
                                setVotingSettings(newSettings);
                              }}
                              removingFunction={(item) => {
                                const newSettings = votingSettings;
                                newSettings.jury[element] = null;
                                setVotingSettings(newSettings);
                              }}
                            >
                              <Flag />
                            </SearchChoice>
                          </div>
                        );
                      })}
                    </section>
                    {votingInfo.current_show_type === 3 && (
                      <section className="defaultSection">
                        <section className="defaultSection">
                          <FileUploader
                            returnFunction={(item) => {
                              fileReader(item, (dataUrl) => {
                                setImage({ image: dataUrl, file: item });
                              });
                            }}
                            label="Upload spokesperson photo"
                            accept="image/*"
                            maxSize={2*1024 * 1024}
                          />
                        </section>

                        <section className="defaultSection">
                          {image.image && (
                            <img
                              alt="spokesperson"
                              style={{ height: "300px" }}
                              src={image.image}
                            ></img>
                          )}
                        </section>
                      </section>
                    )}
                    <section className="defaultSection">
                      <button
                        className="button1"
                        onClick={() => {
                          handleJuryVoting();
                        }}
                      >
                        Vote
                      </button>
                    </section>
                    {votingInfo.recap && (
                      <section className="defaultSection">
                        <div className="headInfo">Recap:</div>
                        <section className="defaultSection">
                          <iframe
                            width="350"
                            height="200"
                            src={votingInfo.recap}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </section>
                      </section>
                    )}
                    <section className="defaultSection">
                      <div className="headInfo">
                        All participating songs in the{" "}
                        {votingInfo.current_show_type === 1
                          ? "Heat"
                          : votingInfo.current_show_type === 2
                          ? "Semi Final"
                          : votingInfo.current_show_type === 3
                          ? "the Grand Final"
                          : null}{" "}
                        {votingInfo.current_show_number !== 0 &&
                          votingInfo.current_show_number}{" "}
                        of the edition {votingInfo.edition} of the {process.env.REACT_CONTEST_FULL || ""}:
                      </div>
                    </section>

                    <section className="defaultSection">
                      {votingInfo.participants.map((element) => {
                        return (
                          <section className="defaultCenteredContainer">
                            <Song
                              songID={element.song_id}
                              edition={element.participation_running_order}
                            />
                          </section>
                        );
                      })}
                    </section>
                    <section className="defaultSection">
                      <button
                        className="button1"
                        onClick={() => {
                          handleTeleVoting();
                        }}
                      >
                        Vote
                      </button>
                    </section>
                  </div>
                </div>
              ) : (
                <div className="defaultFormContainer">
                  <div
                    className="defaultForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <section className="defaultSection">
                      <section className="defaultSection">
                        <p className="hugeHeadline">TELEVOTING</p>
                      </section>
                      <div className="regularText">
                        Televoting in the{" "}
                        {votingInfo.current_show_type === 1
                          ? "Heat"
                          : votingInfo.current_show_type === 2
                          ? "Semi Final"
                          : votingInfo.current_show_type === 3
                          ? "the Grand Final"
                          : null}{" "}
                        {votingInfo.current_show_number !== 0 &&
                          votingInfo.current_show_number}{" "}
                        of the edition {votingInfo.edition} of {process.env.REACT_CONTEST_FULL || ""} is open! Pick between {votingInfo.tele_min}{" "}
                        and {votingInfo.tele_max} countries. If your country is
                        participating you can support it with your vote!
                      </div>
                    </section>
                    {votingSettings.tele && (
                      <section className="televotingOuterConteiner">
                        <section className="televotingInnerConteiner">
                          {votingSettings.tele.map((element, index) => {
                            return (
                              <div
                                className="teleCountry"
                                onClick={() => {
                                  setVotingSettings({
                                    tele: votingSettings.tele.map(
                                      (element2, index2) => {
                                        if (index === index2) {
                                          element2.checked = !element2.checked;
                                        }
                                        return element2;
                                      }
                                    ),
                                  });
                                }}
                              >
                                {" "}
                                <div
                                  className={
                                    element.checked ? "adminRO" : "checkedTele"
                                  }
                                >
                                  {element.running_order}
                                </div>
                                <Flag image={element.country_id} />
                                {element.country_name}
                              </div>
                            );
                          })}
                        </section>
                      </section>
                    )}
                    <section className="defaultSection">
                      <button
                        className="button1"
                        onClick={() => {
                          handleTeleVoting();
                        }}
                      >
                        Vote
                      </button>
                    </section>
                    {votingInfo.recap && (
                      <section className="defaultSection">
                        <div className="headInfo">Recap:</div>
                        <section className="defaultSection">
                          <iframe
                            width="350"
                            height="200"
                            src={votingInfo.recap}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </section>
                      </section>
                    )}
                    <section className="defaultSection">
                      <div className="headInfo">
                        All participating songs in the{" "}
                        {votingInfo.current_show_type === 1
                          ? "Heat"
                          : votingInfo.current_show_type === 2
                          ? "Semi Final"
                          : votingInfo.current_show_type === 3
                          ? "the Grand Final"
                          : null}{" "}
                        {votingInfo.current_show_number !== 0 &&
                          votingInfo.current_show_number}{" "}
                        of the edition {votingInfo.edition} of {process.env.REACT_CONTEST_FULL || ""}:
                      </div>
                    </section>

                    <section className="defaultSection">
                      {votingInfo.participants.map((element) => {
                        return (
                          <section className="defaultCenteredContainer">
                            <Song
                              songID={element.song_id}
                              edition={element.participation_running_order}
                            />
                          </section>
                        );
                      })}
                    </section>
                    <section className="defaultSection">
                      <button
                        className="button1"
                        onClick={() => {
                          handleTeleVoting();
                        }}
                      >
                        Vote
                      </button>
                    </section>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="defaultFormContainer">
          <div
            className="defaultForm"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">VOTING IS CLOSED</p>
              </section>
              <div className="regularText">
                There is currently no open voting. Follow updates to check when
                voting opens
              </div>
            </section>
            <section className="defaultSection">
              <Link to="/main" className="button1">
                Main Page
              </Link>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default Voting;
