import { Link } from "react-router-dom";
import React from "react";

function ActivationFail() {

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">ACCOUNT ACTIVATION FAIL</p>
          </section>
          <div className="regularText">
            Unexpected error occurred while activating your account.
          </div>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default ActivationFail;
