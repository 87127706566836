import { Link } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import { useLogout } from "../scripts/logout";

import axios from "axios";

function DeleteAccount() {
  const logout = useLogout()
  const {setLoading} = useContext(GlobalContext);
    const { delete_code } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)
        api(
            process.env.REACT_APP_API_URL + "account-deletion",
            source.token,
            {deletionID : delete_code}
          ).then((response) => {
            setLoading(false)
            if (response.positive) {
                logout();
                navigate('/main');
            } else {
                navigate('/internal-server-error');
            }
          });
    }, [delete_code, navigate, setLoading, logout])
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">ACCOUNT DELETION</p>
          </section>
          <div className="regularText">
            You should be automatically redirected to other website. If you are not you can click link below.
          </div>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default DeleteAccount;
