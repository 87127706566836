import React, { useContext } from "react";
import GlobalContext from "./GlobalContext";
import LoginRequired from "../pages/LoginRequired";

function ProtectedRoute({
  children,
  allowedRoles = ["user", "admin", "owner", "visitor"],
  redirectToElement = <LoginRequired />,
  skip = false,
}) {

  const { userData } = useContext(GlobalContext);

  if (allowedRoles.includes(userData.role) || skip) {
    return children;
  }

  return redirectToElement;
}

export default ProtectedRoute;
