export const fileReader = (file, callback) => {
    if (file) {
        const reader = new FileReader();
        
        reader.onload = function(e) {
            callback(e.target.result);
        }
  
        reader.readAsDataURL(file);
    }
}
