import Flag from "./Flag";
import { useContext } from "react";
import GlobalContext from "./GlobalContext";
import SearchChoice from "./SearchChoice";

function RunningOrderSet({
  passingFunction = () => {},
  removingFunction = () => {},
  index,
}) {
  const { countries } = useContext(GlobalContext);
  return (
    <><div className="adminRO">{index + 1}</div>
      <SearchChoice
        nameColumn="country_name"
        keyColumn="country_id"
        passToChildKey="country_id"
        data={countries}
        passingFunction={(item) => {
          passingFunction(item);
        }}
        removingFunction={(item) => {
          removingFunction(item);
        }}
      >
        <Flag />
      </SearchChoice>
    </>
  );
}

export default RunningOrderSet;
