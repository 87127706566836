import { useContext } from "react";
import GlobalContext from "../components/GlobalContext";

function MainSelector({ children }) {
  const { theme } = useContext(GlobalContext);
  return (
    <main style={theme.backgroundImage === '' ? { backgroundColor: `var(--text-contrast)` } : { backgroundImage: `url(${theme.backgroundImage})`, backgroundColor: `var(--text-contrast)`}}>{children}</main>
  );
}

export default MainSelector;
