import React, { useState } from "react";
import "../styles/tabs.css";

function Tabs({ data, passingFunction = (item) => {console.log(item)}, opened = 0}) {
  const [activeTab, setActiveTab] = useState(data[opened]);

  return (
    <div className="tabContainer">
      <div className="tabsContainer">
        {data.map((tab) => {
          return (
            <div key={tab.key} className={activeTab.key === tab.key ? "tab active" : "tab"} onClick={
              () => {setActiveTab(tab);passingFunction(tab.key)}
            }>
              {tab.name}
            </div>
          );
        })}
      </div>
      <div key={activeTab.key} className="tabContent">{activeTab.element}</div>
    </div>
  );
}

export default Tabs;
