export const Search = (searchString, data, SearchKey) => {
    return data.filter(item =>
        String(item[SearchKey]).toLowerCase().includes(searchString.toLowerCase())
    ).map(item => {
        const index = String(item[SearchKey]).toLowerCase().indexOf(searchString.toLowerCase());
        return {
            item,
            index
        };
    }).sort((item1, item2) => {
        if (item1.index !== item2.index) {
            return item1.index - item2.index;
        }
        return String(item1.item[SearchKey]).localeCompare(String(item2.item[SearchKey]));
    });
};
