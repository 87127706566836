import {  useState } from "react";
import { Link } from "react-router-dom";
import Flag from "../components/Flag";
import FileUploader from "../components/FileUploader";
import { fileReader } from "../scripts/fileReader";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import { useContext } from "react";
function SongToAccept({ startSettings, id }) {
  const { setLoading } = useContext(GlobalContext);
  const [settings, setSettings] = useState(startSettings);
  const [image, setImage] = useState(startSettings.image);
  return (
    <section
      style={{ border: "1px solid var(--primary-color)", marginBottom: "50px" }}
    >
      <section className="defaultSection claimedCountry">
        <div className="headInfo">Country:</div>
        <Link to={"/countries/" + settings.country_database_name}>
          <Flag image={settings.country_id} />
          {settings.country_name}
        </Link>
      </section>{" "}
      <section className="defaultSection">
        <div className="headInfo">Required:</div>
        {settings.link.match(
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        ) && (
          <iframe
            width="560"
            height="315"
            src={
              "https://www.youtube.com/embed/" +
              settings.link.match(
                /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
              )[2]
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        )}
        <div className="defaultInputContainer">
          Youtube link:{" "}
          <input
            name="youtube"
            type="text"
            className="defaultInput"
            value={settings.link}
            onChange={(e) => {
              setSettings((prevState) => ({
                ...prevState,
                link: e.target.value,
              }));
            }}
          />
        </div>
        <div className="defaultInputContainer">
          Song name:{" "}
          <input
            name="song"
            type="text"
            className="defaultInput"
            value={settings.song}
            onChange={(e) => {
              setSettings((prevState) => ({
                ...prevState,
                song: e.target.value,
              }));
            }}
          />
        </div>
        <div className="defaultInputContainer">
          Artist name:{" "}
          <input
            name="artist"
            type="text"
            className="defaultInput"
            value={settings.artist}
            onChange={(e) => {
              setSettings((prevState) => ({
                ...prevState,
                artist: e.target.value,
              }));
            }}
          />
        </div>
        <div>
          <div className="defaultInputContainer">
            Timestamp:{" "}
            <input
              name="artist"
              type="number"
              className="defaultInput defaultTSInput"
              min={0}
              value={settings.ts_minute}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  setSettings((prevState) => ({
                    ...prevState,
                    ts_minute: "",
                  }));
                } else {
                  setSettings((prevState) => ({
                    ...prevState,
                    ts_minute: parseInt(e.target.value),
                  }));
                }
              }}
            />
            :{" "}
            <input
              name="artist"
              type="number"
              className="defaultInput defaultTSInput"
              min={0}
              max={61}
              value={settings.ts_seconds}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  setSettings((prevState) => ({
                    ...prevState,
                    ts_seconds: "",
                  }));
                } else if (e.target.value >= 60) {
                  setSettings((prevState) => ({
                    ...prevState,
                    ts_minute: parseInt(prevState.ts_minute) + 1,
                    ts_seconds: 0,
                  }));
                } else {
                  setSettings((prevState) => ({
                    ...prevState,
                    ts_seconds: parseInt(e.target.value),
                  }));
                }
              }}
            />{" "}
            -{" "}
            {settings.ts_seconds < 30
              ? settings.ts_minute
              : settings.ts_minute + 1}
            :
            {settings.ts_seconds < 30
              ? settings.ts_seconds + 30
              : settings.ts_seconds - 30}
          </div>
          
        </div>
        <div className="defaultInputContainer">
          Reject reason:{" "}
          <input
            name="reject"
            type="text"
            className="defaultInput"
            value={settings.reject}
            onChange={(e) => {
              setSettings((prevState) => ({
                ...prevState,
                reject: e.target.value,
              }));
            }}
          />
        </div>
        <div className="headInfo">Optional:</div>
        <section className="defaultSection" style={{ marginBottom: "60px" }}>
          <FileUploader
            id={id + "_input"}
            returnFunction={(item) => {
              setSettings((prevState) => ({
                ...prevState,
                image2: item,
              }));
              fileReader(item, (dataUrl) => {
                setImage(dataUrl);
              });
            }}
            label="Upload singer photo"
            accept="image/*"
            maxSize={1024 * 1024}
          />

          <section className="defaultSection">
            {image && (
              <>
                <img alt="singer" style={{ height: "300px" }} src={image}></img>
              </>
            )}
          </section>
        </section>
        <section className="defaultSection">
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                if (
                  settings.link === null ||
                  settings.song === null ||
                  settings.artist === null ||
                  settings.link === "" ||
                  settings.song === "" ||
                  settings.artist === ""
                ) {
                  alert("All fields must not be empty!");
                } else {
                  setLoading(true);
                  let dataToSend = new FormData();
                  for (let key in settings) {
                    if (settings.hasOwnProperty(key)) {
                      if (key === "image2" && settings.image2) {
                        dataToSend.append(
                          key,
                          settings[key],
                          settings[key].name
                        );
                      } else {
                        dataToSend.append(key, settings[key]);
                      }
                    }
                  }
                  api(
                    process.env.REACT_APP_API_URL + "accept-song",
                    null,
                    dataToSend
                  ).then((response) => {
                    setLoading(false);
                    if (response.positive) {
                      window.location.reload();
                    }
                  });
                }
              }}
            >
              Accept a song
            </button>
            <button
              style={{ marginLeft: "20px" }}
              className="button1"
              onClick={() => {
                setLoading(true);
                api(process.env.REACT_APP_API_URL + "reject-a-song", null, settings).then((response) => {
                  if (response.positive) {
                    setLoading(true);
                    alert("Done!");
                    window.location.reload();
                  } else {
                    alert("Error");
                    window.location.reload();
                  }
                });
              }}
            >
              Reject a song
            </button>
          </section>
        </section>
      </section>
    </section>
  );
}

export default SongToAccept;
