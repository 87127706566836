export const positionNumber = (number) => {
    if( number >= 11 && number <= 13)
    {
        return number + 'th';
    }
    else
    {
        switch (number.toString().slice(-1)) {
            case '1':
                return number + 'st';
            case '2':
                return number + 'nd';
            case '3':
                return number + 'rd';
            default:
                return number + 'th';
          }
    }

}
