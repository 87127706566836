import axios from 'axios';

export const api = async (url, cancelToken = null, data = null, additionalHeaders = {}, method = "POST", timeout = 10000, redirect = false) => {
    try {
        const token = localStorage.getItem('token');

        let headers = {
            'Authorization': `Bearer ${token}`,
            ...additionalHeaders
        };

        const response = await axios({
            method: method,
            url: url,
            headers: headers,
            data: data,
            timeout: timeout,
            cancelToken: cancelToken
        });
        if(response.data.hasOwnProperty("alert")){
            console.log(response.data.alert)
            alert(response.data.alert)
        }
        return {status: response.status, positive: true, responseData: response.data};
    } catch (error) {

        if (error.response && error.response.data && error.response.data.hasOwnProperty("alert")) {
            alert(error.response.data.alert);
        }
        if(redirect){
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else if (error.response) {
                return {status: error.response.status, positive: false, responseData: error.response};
            } else if (error.request) {
                return {status: error.request, positive: false, responseData: error.request};
            }
        }
        else {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else if (error.response) {
                return {status: error.response.status, positive: false, responseData: error.response};
            } else if (error.request) {
                return {status: error.request, positive: false, responseData: error.request};
            }
        }
    }
};
