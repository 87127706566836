import { Link } from "react-router-dom";
import React from "react";

function PageNotFound() {

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">PAGE NOT FOUND</p>
          </section>
          <div className="regularText">
            Page you were looking for doesn't exist.
          </div>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default PageNotFound;
