import React from "react";

function ConnectionFailed() {
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">CONNECTION FAILED</p>
          </section>
          <div className="regularText">
            Unfortunatelly, there is no connection to the server. Visit the webpage again later.
            
          </div>
        </section>
        <section className="defaultSection">
        <button className="button1" onClick={()=>{window.location.reload()}}>
              Reconnect
        </button>
        </section>
      </form>
    </div>
  );
}

export default ConnectionFailed;
