export const validateInput = (input, min = 0, max = 0, required = false) => {
  const regex = /^[a-zA-Z0-9@.]*$/;
  
    if (input === '' && required) {
      return 2;
    }
  
    if (input.length > max && max !== 0) {
      return 3;
    }

    if (input.length < min && min !== 0) {
        return 4;
      }
  
    if (!regex.test(input)) {
      return 5;
    }
  
    return 1;
  }
  