import React, { useEffect, useState } from "react";
import GlobalContext from "./GlobalContext";
import { api } from "../scripts/api";
import axios from "axios";
import { ReactComponent as Loading } from "../assets/images/svg/loading.svg";
import io from "socket.io-client";


function GlobalContextProvider({ children }) {
  
  const [userData, setUserData] = useState({
    role: "guest",
    username: "",
    email: "",
    discordID: "",
    country: "",
    dateOfBirth: "",
    profilePicture: "/assets/images/profile_pictures/default.png",
  });
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [theme, setTheme] = useState({
    primaryColour: "#231d85",
    textColour: "#030303",
    backgroundColour: "#ffffff",
    backgroundImage:
      "/assets/images/background_pictures/templates/background.png",
  });
  const [messages, setMessages] = useState([])

  useEffect(() => {
    const timestamp = new Date().getTime(); // Uzyskaj aktualny znacznik czasu
    fetch(`/config.json?${timestamp}`)       // Dodaj znacznik czasu do URL
      .then((response) => response.json())
      .then((data) => {
        setMaintenanceMode(data.maintenanceMode && (userData.role === "user" ||  userData.role === "guest"));
      });
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null"
    ) {
      setUserData({
        role: "guest",
        username: "",
        email: "",
        discordID: "",
        country: "",
        dateOfBirth: "",
        profilePicture: "/assets/images/profile_pictures/default.png",
      });
      setTheme({
        primaryColour: "#231d85",
        textColour: "#030303",
        backgroundColour: "#ffffff",
        backgroundImage:
          "/assets/images/background_pictures/templates/background.png",
      });
    } else {

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      api(process.env.REACT_APP_API_URL + "user-info", source.token).then(
        (response) => {
          if (response.positive) {
            setUserData(response.responseData.userInfo);
            setTheme(response.responseData.theme);
          } else {
            setUserData({
              role: "guest",
              username: "",
              email: "",
              discordID: "",
              country: "",
              dateOfBirth: "",
              profilePicture: "/assets/images/profile_pictures/default.png",
            });
            setTheme({
              primaryColour: "#231d85",
              textColour: "#030303",
              backgroundColour: "#ffffff",
              backgroundImage:
                "/assets/images/background_pictures/templates/background.png",
            });
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api(
      process.env.REACT_APP_API_URL + "countries",
      source.token,
      null,
      null,
      "GET"
    ).then((response) => {
      if (response.positive) {
        setCountries(response.responseData);
      }
    });

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);
/*
  useEffect(()=>{
    const socket = io(':9000', {
      path: '/api/socket.io'
    });
    socket.on("reload", () => {
      window.location.reload();
    });
  },[])*/
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", theme.primaryColour);
    root.style.setProperty("--text-color", theme.textColour);
    root.style.setProperty("--text-contrast", theme.backgroundColour);
  }, [theme]);


  useEffect(()=>{
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api(
      process.env.REACT_APP_API_URL + "get-messages",
      source.token,
    ).then((response) => {
      if (response.positive) {
        setMessages(response.responseData);
      }
    });

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  },[])
  const [loading, setLoading] = useState(false);

  return (
    <>
      <GlobalContext.Provider
        value={{
          userData,
          setUserData,
          maintenanceMode,
          setMaintenanceMode,
          theme,
          setTheme,
          countries,
          setCountries,
          loading,
          setLoading,
          messages,
          setMessages
        }}
      >
        {children}
      </GlobalContext.Provider>
      {loading && (
        <>
          {" "}
          <div className="loadingBackground"></div>
          <div className="loadingContainer">
            <Loading />
          </div>
        </>
      )}
    </>
  );
}

export default GlobalContextProvider;
