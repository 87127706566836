import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import { validateInput } from "../scripts/validateInput";
import CryptoJS from "crypto-js";


function ChangePassword() {
  const validationMessage = (item, min, max, name, required) => {
    const validationResult = validateInput(item, min, max, required);

    switch (validationResult) {
      case 1:
        return null;
      case 2:
        return `${name} must not be empty`;
      case 3:
        return `${name} is too long`;
      case 4:
        return `${name} is too short`;
      case 5:
        return `${name} must contain only letters and numbers`;
      default:
        return "Unknown error";
    }
  };

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const { setLoading } = useContext(GlobalContext);
  const { change_code } = useParams();
  const navigate = useNavigate();
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <div className="headInfo">Set new password:</div>
          </section>
          <div className="outerFlexContainer">
            <div className="formFields">
              <div className="defaultInputContainer">
                New password:{" "}
                <input
                  type="password"
                  className="defaultInput"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="defaultInputContainer">
                Repeat new password::{" "}
                <input
                  type="password"
                  className="defaultInput"
                  onChange={(e) => {
                    setRepeatPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              if (password === repeatPassword) {
                if (
                  validationMessage(password, 8, 50, "Password", true) !== null
                ) {
                  alert(validationMessage(password, 8, 50, "Password", true));
                } else {
                  if (
                    validationMessage(
                      repeatPassword,
                      8,
                      50,
                      "Password",
                      true
                    ) !== null
                  ) {
                    alert(
                      validationMessage(repeatPassword, 8, 50, "Password", true)
                    );
                  } else {
                    const hashedPassword = CryptoJS.SHA256(password).toString(
                      CryptoJS.enc.Hex
                    );
                    setLoading(true);
                    api(
                      process.env.REACT_APP_API_URL + "password-change",
                      null,
                      { changeID: change_code, password: hashedPassword }
                    ).then((response) => {
                      setLoading(false);
                      if (response.positive) {
                        navigate("/main");
                      } else {
                        navigate("/internal-server-error");
                      }
                    });
                  }
                }
              } else {
                alert("Passwords are not the same");
              }
            }}
          >
            Change password
          </button>
        </section>
      </div>
    </div>
  );
}

export default ChangePassword;
