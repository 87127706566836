import GlobalContext from "./GlobalContext";
import { useContext } from "react";
import { api } from "../scripts/api";

function Message({ message }) {
  const { setLoading, setMessages } = useContext(GlobalContext);
  return (
    <div className="message">
        <div style={{textAlign: 'left'}}>{`${message.day.toString().length == 1 ? `0${message.day}` : message.day}.${message.month.toString().length == 1 ? `0${message.month}` : message.month}.${message.year}`}</div>
      <b className="headInfo">{message.title}</b>
      <section
        className="defaultSection"
        dangerouslySetInnerHTML={{ __html: message.content }}
      />
      <button
        className="button1"
        onClick={() => {
          setLoading(true);
          api(process.env.REACT_APP_API_URL + "remove-message", null, {
            guid: message.guid,
          }).then((response) => {
            api(process.env.REACT_APP_API_URL + "get-messages").then(
              (response) => {
                setLoading(false);
                if (response.positive) {
                  setMessages(response.responseData);
                }
              }
            );
          });
        }}
      >
        Mark as read
      </button>
    </div>
  );
}

export default Message;
