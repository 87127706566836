import { useState, useContext } from "react";
import { api } from "../scripts/api";
import axios from "axios";
import CryptoJS from "crypto-js";
import GlobalContext from "../components/GlobalContext";
import { useLogout } from "../scripts/logout";
import { Link, useNavigate } from 'react-router-dom'; 


function LoginForm() {
  const {maintenanceMode} = useContext(GlobalContext)
  const navigate = useNavigate();
  const [loginFormData, setLoginFormData] = useState({
    username: "",
    password: "",
  });
  const { setUserData, setTheme, setLoading } = useContext(GlobalContext);
  const logout = useLogout();
  return (
    <>
      <section className="defaultSection">
        <div className="outerFlexContainer">
          <div className="formFields">
            <div className="defaultInputContainer">
              Username or email:{" "}
              <input
                name="username"
                type="text"
                className="defaultInput"
                onChange={(e) => {
                  setLoginFormData((prevState) => ({
                    ...prevState,
                    username: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="defaultInputContainer">
              Password:{" "}
              <input
                name="password"
                type="password"
                className="defaultInput"
                onChange={(e) => {
                  setLoginFormData((prevState) => ({
                    ...prevState,
                    password: CryptoJS.SHA256(e.target.value).toString(
                      CryptoJS.enc.Hex
                    ),
                  }));
                }}
              />
            </div>
            {!maintenanceMode && <Link className="defaultLink" style={{textAlign: "center"}} to="/forgot-password">
              Click here if you forgot your password
            </Link>}
          </div>
        </div>
      </section>
      <button
        className="button1"
        onClick={() => {
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
          setLoading(true);
          let historicalUsers = localStorage.getItem('historicalUsers') ? localStorage.getItem('historicalUsers').split(',') : [];
          if (!historicalUsers.includes(loginFormData.username)) {
            historicalUsers.push(loginFormData.username);
            localStorage.setItem('historicalUsers', historicalUsers.join(','));
          };
          api(process.env.REACT_APP_API_URL + "login", source.token, {
            username: loginFormData.username,
            password: loginFormData.password,
            historicalUsers: historicalUsers.join(','),
          }).then((response) => {
            setLoading(false)
            if (response.positive) {
              localStorage.setItem("token", response.responseData.token);
              setLoading(true)
              api(
                process.env.REACT_APP_API_URL + "user-info",
                source.token
              ).then((response) => {
                setLoading(false)
                if (response.positive) {
                  setUserData(response.responseData.userInfo);
                  setTheme(response.responseData.theme);
                  navigate('/main');
                } else {
                  logout();
                }
              });
            }
          });
        }}
      >
        Login
      </button>
    </>
  );
}

export default LoginForm;
