import { useContext, useState } from "react";
import { api } from "../scripts/api";
import { useEffect } from "react";
import Flag from "../components/Flag";
import GlobalContext from "../components/GlobalContext";
import { Link } from "react-router-dom";

function ResultsSnippet() {
  const [juryData, setJuryData] = useState(null);
  const { setLoading } = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);
    api(process.env.REACT_APP_API_URL + "get-results-status").then(
      (response) => {
        setLoading(false);
        if (response.positive) {
          setJuryData(response.responseData);
        }
      }
    );
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm defaultWider"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {juryData && (
          <section className="defaultSection">
            <section className="defaultSection">
              <p className="hugeHeadline">RESULTS SNIPPET</p>
            </section>
            <section className="defaultSection defaultCenteredContainer">
              <table className="statsTable">
                <tr>
                  <td>
                    <b>Placement</b>
                  </td>
                  <td>
                    <b>Country</b>
                  </td>
                  <td>
                    <b>Points</b>
                  </td>
                  <td>
                    <b>Jury points</b>
                  </td>
                  <td>
                    <b>Jury placement</b>
                  </td>
                  <td>
                    <b>Televoting points</b>
                  </td>
                  <td>
                    <b>Televoting placement</b>
                  </td>
                  <td>
                    <b>Qualification</b>
                  </td>
                </tr>
                {juryData.results.map((results) => {
                  return (
                    <tr>
                      <td>
                        <div
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <div class="adminRO" style={{ margin: "0" }}>
                            {results.placement}
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link
                          to={"/countries/" + results.country_database_name}
                          class="teleCountry"
                        >
                          <Flag image={results.country_id} />
                          {results.country_name}
                        </Link>
                      </td>
                      <td>{results.full_results}</td>
                      <td>
                        {results.jury_results}
                      </td>
                      <td>
                        {results.jury_placement}
                      </td>

                      <td>{results.televoting_points}</td>
                      <td>{results.televoting_placement}</td>
                      <td>
                        {" "}
                        {results.qualification === 1
                          ? "Q"
                          : results.qualification === 2
                          ? "NQ"
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </section>
          </section>
        )}
      </div>
    </div>
  );
}

export default ResultsSnippet;
