import { Link } from "react-router-dom";
import React from "react";

function LinkSent() {
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">CHECK YOUR MAILBOX</p>
          </section>
          <div className="regularText">Link has been sent to your email</div>
        </section>
        <section className="defaultSection">
          <Link className="button1" to="/main">
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default LinkSent;
