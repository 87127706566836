import { useContext, useState } from "react";
import { api } from "../scripts/api";
import { useEffect } from "react";
import GlobalContext from "../components/GlobalContext";
import SearchChoice from "../components/SearchChoice";
import Flag from "../components/Flag";
import Voting from "./Voting";

function ManageBackupJury() {
  const { countries } = useContext(GlobalContext);
  const [backups, setBackups] = useState([]);
  const [nonVoters, setNonVoters] = useState([]);
  const [votes, setVotes] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    10: null,
    12: null,
  });

  useEffect(() => {
    api(process.env.REACT_APP_API_URL + "get-backup-jury-info").then(
      (response) => {
        if (response.positive) {
          setNonVoters(response.responseData.non_voters);
          setBackups(response.responseData.backup_juries);
        }
      }
    );
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm defaultWider"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">BACKUP JURIES</p>
          </section>
          <section className="defaultSection">
            <p className="headInfo">Non voters:</p>
            {nonVoters.map((element) => {
              return `${element.country_name} ${element.country_emoji},`;
            })}
          </section>
          <div className="defaultSection defaultCenteredContainer">
            <table className="statsTable">
              <tr>
                <td>Voting Country</td>
                <td>12</td>
                <td>10</td>
                <td>8</td>
                <td>7</td>
                <td>6</td>
                <td>5</td>
                <td>4</td>
                <td>3</td>
                <td>2</td>
                <td>1</td>
              </tr>
              {backups.map((element) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <SearchChoice
                        data={countries}
                        nameColumn="country_name"
                        keyColumn="country_id"
                        passToChildKey="country_id"
                        passingFunction={(item) => {
                          api(
                            process.env.REACT_APP_API_URL +
                              "accept-backup-jury",
                            null,
                            {
                              country: item.country_id,
                              id: element.jury_voting_id,
                            }
                          ).then((response) => {
                            window.location.reload();
                          });
                        }}
                      >
                        <Flag image="" />
                      </SearchChoice>
                    </td>
                    <td>
                      <Flag className="defaultFlag" image={element.p12} />
                    </td>
                    <td>
                      <Flag image={element.p10} />
                    </td>
                    <td>
                      <Flag image={element.p8} />
                    </td>
                    <td>
                      <Flag image={element.p7} />
                    </td>
                    <td>
                      <Flag image={element.p6} />
                    </td>
                    <td>
                      <Flag image={element.p5} />
                    </td>
                    <td>
                      <Flag image={element.p4} />
                    </td>
                    <td>
                      <Flag image={element.p3} />
                    </td>
                    <td>
                      <Flag image={element.p2} />
                    </td>
                    <td>
                      <Flag image={element.p1} />
                    </td>
                    <td>
                      <button className="button1"
                        onClick={() => {
                          api(
                            process.env.REACT_APP_API_URL +
                              "delete-backup-jury",
                            null,
                            {
                              id: element.jury_voting_id,
                            }
                          ).then((response) => {
                            window.location.reload();
                          });
                        }}
                      >
                        Delete vote
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </section>
        <section className="defaultSection">
          {["12", "10", "8", "7", "6", "5", "4", "3", "2", "1"].map(
            (element) => {
              return (
                <div className="defaultCenteredContainer">
                  <div className="adminRO">{element}</div>
                  <SearchChoice
                    key={element}
                    nameColumn="country_name"
                    keyColumn="country_id"
                    passToChildKey="country_id"
                    data={countries}
                    passingFunction={(item) => {
                      const newSettings = votes;
                      newSettings[element] = item.country_id;
                      setVotes(newSettings);
                    }}
                    removingFunction={(item) => {
                      const newSettings = votes;
                      newSettings[element] = null;
                      setVotes(newSettings);
                    }}
                  >
                    <Flag />
                  </SearchChoice>
                </div>
              );
            }
          )}
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              api(
                process.env.REACT_APP_API_URL + "add-backup-jury",
                null,
                votes
              ).then((response) => {
                window.location.reload();
              });
            }}
          >
            Add backup vote
          </button>
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              api(
                process.env.REACT_APP_API_URL + "add-rest-of-the-world-jury-vote",
                null,
                votes
              ).then((response) => {
                window.location.reload();
              });
            }}
          >
            Vote as rest of the world jury
          </button>
        </section>
      </div>
    </div>
  );
}

export default ManageBackupJury;
