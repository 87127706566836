import React from "react";
import SearchChoice from "../components/SearchChoice";
import GlobalContext from "../components/GlobalContext";
import { useContext } from "react";
import Flag from "../components/Flag";
import { useNavigate, Link } from "react-router-dom";

function Countries() {
  const { countries } = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <div className="defaultFormContainer">
      <div className="defaultForm widePage">
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">{(process.env.REACT_CONTEST || "").toUpperCase()} COUNTRIES</p>
          </section>
          <div className="regularText">
            Check info about {process.env.REACT_CONTEST || ""} countries:
          </div>
        </section>
        <section className="defaultSection">
          <div className="defaultInputContainer">
            Choose country:{" "}
            <SearchChoice
              data={countries}
              nameColumn="country_name"
              keyColumn="country_id"
              passingFunction={(item) => {
                navigate("/countries/" + item.country_database_name);
              }}
              passToChildKey="country_id"
            >
              <Flag image="" />
            </SearchChoice>
          </div>
        </section>
        <section className="defaultSection">
            <div className="headInfo">All countries list:</div>
            <section className="defaultSection">
            <div className="templatesContainer" style={{justifyContent: "left"}}>
              {countries.map(country => {return <Link key={country.country_database_name} to={"/countries/" + country.country_database_name} className="countryLink"><Flag image={country.country_id}/>{country.country_name}</Link>})}
            </div>
            </section>

        </section>
      </div>
    </div>
  );
}

export default Countries;
