import { useContext, useState } from "react";
import { api } from "../scripts/api";
import { useEffect } from "react";
import Flag from "../components/Flag";
import GlobalContext from "../components/GlobalContext";

function ManageJuryVotes() {
  const [juryData, setJuryData] = useState(null);
  const { setLoading } = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);
    api(process.env.REACT_APP_API_URL + "get-results-status").then(
      (response) => {
        setLoading(false);
        if (response.positive) {
          setJuryData(response.responseData);
        }
      }
    );
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm defaultWider"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {juryData && (
          <section className="defaultSection">
            <section className="defaultSection">
              <p className="hugeHeadline">MANAGE JURY VOTES</p>
            </section>
            <section className="defaultSection defaultCenteredContainer">
              <table className="statsTable">
                <tr>
                  <td></td>
                  <td></td>

                  <td>
                    <b>Country Voting</b>
                  </td>
                  <td>
                    <b>1</b>
                  </td>
                  <td>
                    <b>2</b>
                  </td>
                  <td>
                    <b>3</b>
                  </td>
                  <td>
                    <b>4</b>
                  </td>
                  <td>
                    <b>5</b>
                  </td>
                  <td>
                    <b>6</b>
                  </td>
                  <td>
                    <b>7</b>
                  </td>
                  <td>
                    <b>8</b>
                  </td>
                  <td>
                    <b>10</b>
                  </td>
                  <td>
                    <b>12</b>
                  </td>
                </tr>
                {juryData.votes.map((vote) => {
                  return (
                    <tr>
                      <td>
                        <button
                          className="button1"
                          onClick={() => {
                            setLoading(true);
                            api(
                              process.env.REACT_APP_API_URL +
                                "remove-jury-vote",
                              null,
                              vote
                            ).then((response) => {
                              setLoading(false);
                              if (response.positive) {
                                alert("Done!");
                              } else {
                                alert("Error!");
                              }
                            });
                          }}
                        >
                          Remove vote
                        </button>
                      </td>
                      <td>
                        <button className="button1" onClick={() => {
                            setLoading(true);
                            api(
                              process.env.REACT_APP_API_URL +
                                "jury-vote-to-backup",
                              null,
                              vote
                            ).then((response) => {
                              setLoading(false);
                              if (response.positive) {
                                alert("Done!");
                              } else {
                                alert("Error!");
                              }
                            });
                          }}>Make backup</button>
                      </td>

                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.country_voting} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p1} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p2} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p3} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p4} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p5} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p6} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p7} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p8} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p10} />
                        </a>
                      </td>
                      <td className="countryLinkFlag">
                        <a>
                          <Flag image={vote.p12} />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </section>
          </section>
        )}
      </div>
    </div>
  );
}

export default ManageJuryVotes;
