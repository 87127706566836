import { useState } from "react";
import { api } from "../scripts/api";
import SimpleText from "../components/SimpleText";
import ComponentsList from "../components/ComponentsList";
import Flag from "../components/Flag";

function AllocationDrawComponent({ type = 1 }) {
  const [settings, setSettings] = useState({
    show_type: type,
    skip_heat: false,
    three_parts: false,
  });
  console.log(settings);
  return (
    <div className="defaultSection">
      <section className="defaultSection">
        <ComponentsList
          passingFunction={(items) => {
            setSettings((prevState) => ({
              ...prevState,
              shows: items,
            }));
          }}
          buttonText="Add new show"
        >
          <SimpleText />
        </ComponentsList>
      </section>
      <section className="defaultSection">
        <div className="defaultCenteredContainer">
          <input
            type="checkbox"
            id="three_parts"
            className="defaultCheckbox"
            onChange={(e) => {
              setSettings((prevState) => ({
                ...prevState,
                three_parts: e.target.checked,
              }));
            }}
          />
          <label htmlFor="three_parts">Divide into 3 parts</label>
        </div>
      </section>

      <section className="defaultSection">
        <div className="defaultCenteredContainer">
          <input
            type="checkbox"
            id="skip"
            className="defaultCheckbox"
            onChange={(e) => {
              setSettings((prevState) => ({
                ...prevState,
                skip_heat: e.target.checked,
              }));
            }}
          />
          <label htmlFor="skip">Skip heats results</label>
        </div>
      </section>

      <section className="defaultSection">
        <button
          className="button1"
          onClick={() => {
            api(
              process.env.REACT_APP_API_URL + "allocation",
              null,
              settings
            ).then((response) => {
              if (response.positive) {
                console.log(response.responseData)
                setSettings((prevState) => ({
                  ...prevState,
                  allocation: response.responseData,
                }));
              } else {
                alert("Error!");
              }
            });
          }}
        >
          Allocate
        </button>
        <button
          className="button1"
          style={{ marginLeft: "20px" }}
          onClick={() => {
            api(
              process.env.REACT_APP_API_URL + "accept-allocation",
              null,
              settings
            ).then((response) => {
              if (response.positive) {
                alert("Done!");
              } else {
                alert("Error!");
              }
            });
          }}
        >
          Accept
        </button>
        <section className="defaultSection">
          {settings?.allocation && (
            <>
              {Object.keys(settings.allocation.shows).map((show) => {
                return (
                  <section className="defaultSection">
                    <p>Show {show}</p>
                    {settings.allocation.shows[show].map((country) => {
                      return (
                        <section className="defaultCenteredContainer">
                          <div className="adminRO" style={{ width: '100px' }}>{country.running_order}</div>
                          <Flag
                            className="defaultFlag"
                            image={country.country_id}
                          />
                          {country.country_name}
                        </section>
                      );
                    })}
                  </section>
                );
              })}

              {Object.keys(settings.allocation.AQ_allocation).map((show) => {
                return (
                  <section className="defaultSection">
                    <p>Semi Final {show} AQ Allocation</p>
                    {settings.allocation.AQ_allocation[show].map((country) => {
                      return (
                        <section className="defaultCenteredContainer">
                          <Flag
                            className="defaultFlag"
                            image={country.country_id}
                          />
                          {country.country_name}
                        </section>
                      );
                    })}
                  </section>
                );
              })}
            </>
          )}
        </section>
      </section>
      <section className="defaultSection"></section>
    </div>
  );
}

export default AllocationDrawComponent;
