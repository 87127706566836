import { useEffect, useState, useContext } from "react";
import { api } from "../scripts/api";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import GlobalContext from "../components/GlobalContext";
import Flag from "../components/Flag";

function Borrowers() {
  const { countries } = useContext(GlobalContext);
  const [borrowers, setBorrowers] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    api(
      process.env.REACT_APP_API_URL + "get-borrowers",
      source.token,
      null,
      null,
      "GET"
    ).then((response) => {
      if (response.positive) {
        setBorrowers(response.responseData);
      } else {
        navigate("/internal-server-error");
      }
    });
  }, []);
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <div className="headInfo">Countries borrowers list:</div>
          <div className="borrowersOuterContainer">

            <div className="borrowersContainer">
          {countries.length !== 0 &&
            borrowers.map((borrowing) => {
              const country = countries.find(
                (country) =>
                  country.country_id === borrowing.borrowing_country_id
              );
              const borrowing_country = countries.find(
                (country) =>
                  country.country_id === borrowing.borrowing_from_country_id
              );
              return (
                <div className="borrower">
                  <div className="countryInline">
                    <Link
                      key={"C" + country.country_id}
                      to={"/countries/" + country.country_database_name}
                      className="countryLink"
                    >
                      <Flag image={country.country_id} />
                      {country.country_name}
                    </Link>
                  </div>
                  borrows from
                  <div className="countryInline">
                    <Link
                      key={"C" + borrowing_country.country_id}
                      to={"/countries/" + borrowing_country.country_database_name}
                      className="countryLink"
                    >
                      <Flag image={borrowing_country.country_id} />
                      {borrowing_country.country_name}
                    </Link>
                  </div>
                </div>
              );
            })}
            </div>
            </div>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Borrowers;
