import { Link } from "react-router-dom";
import React, { useContext } from "react";
import GlobalContext from "../components/GlobalContext";

function Admin() {
  const { userData } = useContext(GlobalContext);
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {userData.role === "owner" && (
          <div>
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">OWNER TOOLS</p>
              </section>
              <div className="regularText">Tools for owner of the contest</div>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/add-country"}
              >
                Add country
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/user-settings"}
              >
                User tools
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/shows-settings"}
              >
                Shows settings
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/countries-settings"}
              >
                Countries settings
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/general-settings"}
              >
                General settings
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/allocation-draw"}
              >
                Allocation draw
              </Link>
            </section>

            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/manage-backup-votes"}
              >
                Manage backup votes
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/manage-televoting-points"}
              >
                Manage televoting points
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/manage-qualification"}
              >
                Manage qualification
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/manage-jury-votes"}
              >
                Manage jury votes
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/results-snippet"}
              >
                Results snippet
              </Link>
            </section>



            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/warnings"}
              >
                Warnings
              </Link>
            </section>


            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/non-submitters"}
              >
                Non submiters
              </Link>
            </section>
          </div>
        )}
        {(userData.role === "owner" || userData.role === "admin") && (
          <section className="defaultSection">
            <section className="defaultSection">
              <p className="hugeHeadline">ADMIN TOOLS</p>
            </section>
            <div className="regularText">Tools for admins</div>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/song-acceptance"}
              >
                Song acceptance
              </Link>
            </section>
            <section className="defaultSection">
              <Link
                className="button1 horizontalMargins"
                to={"/admin/question-of-the-day-queue"}
              >
                Question of the day queue
              </Link>
            </section>
          </section>
        )}
      </div>
    </div>
  );
}

export default Admin;
