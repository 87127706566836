import React, { useEffect, useState } from "react";
import { api } from "../scripts/api";
import SongToAccept from "../pageElements/SongToAccept";

function SongAcceptance() {
    const [toAccept, setToAccept] = useState([]);
  useEffect(()=>{
    api(
        process.env.REACT_APP_API_URL + "songs-to-accept"
      ).then((response) => {
        if (response.positive) {
            setToAccept(response.responseData)
        }
      });
  }, [])
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}

      >
        <p className="hugeHeadline">SONGS ACCEPTANCE</p>
        <section className="defaultSection">
            {toAccept.map((element, index)=>{return <SongToAccept key={`song_acceptance${index}` } id={`song_acceptance${index}`} startSettings={element}/>})}
        </section>
      </form>
    </div>
  );
}

export default SongAcceptance;
