import { useState } from "react";
import { api } from "../scripts/api";
import { useEffect } from "react";
import Flag from "../components/Flag";

function ManageTelevotingResults() {
  const [televotingSettings, setTelevotingSettings] = useState(null);

  useEffect(() => {
    api(process.env.REACT_APP_API_URL + "get-televotes-status").then(
      (response) => {
        if (response.positive) {
          setTelevotingSettings(response.responseData);
          console.log(response.responseData);
        }
      }
    );
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {televotingSettings && (
          <section className="defaultSection">
            <section className="defaultSection">
              <p className="hugeHeadline">MANAGE TELEVOTING RESULTS</p>
            </section>
            <div className="regularText">
              <section className="defaultSection">
                Jury votes: {televotingSettings.jury.jury_votes}
              </section>
              <section className="defaultSection">
                Total jury points: {televotingSettings.jury.total_points}
              </section>

              <section className="defaultSection">
                Total jury televotes: {televotingSettings.jury.total_televotes}
              </section>
            </div>
            <section className="defaultSection defaultCenteredContainer">
              <table className="statsTable">
                <tr>
                  <td>Place</td>
                  <td>Country</td>
                  <td>Votes</td>
                  <td>Points</td>
                </tr>
                {televotingSettings.televoting_results.map((element, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {element.country_name}{" "}
                        <Flag image={element.country_id} />
                      </td>
                      <td>{element.votes}</td>
                      <td>{element.points}</td>
                    </tr>
                  );
                })}
              </table>
            </section>
            <section className="defaultSection">
              <button className="button1" onClick={()=>{
                    api(process.env.REACT_APP_API_URL + "accept-televoting-results").then(
                      (response) => {
                        if (response.positive) {
                          alert("Done!")
                        } else {
                          alert("Error!")
                        }
                      }
                    );
              }}>
                Accept televoting results
              </button>
            </section>

            <section className="defaultSection defaultCenteredContainer">
              <table className="statsTable">
                <tr>
                  <td>User</td>
                  <td>Votes</td>
                  <td>Remove</td>
                </tr>
                {televotingSettings.users_votes.map((element, index) => {
                  return (
                    <tr>
                      <td>
                        {element.user.username} | {element.user.discord_id}
                      </td>
                      <td>
                        {element.votes.map((element2) => {
                          return (
                            <p>
                              {element2.country_name}{" "}
                              <Flag image={element2.country_id} />
                            </p>
                          );
                        })}
                      </td>
                      <td>
                        <button
                          className="button1"
                          onClick={() => {
                            api(
                              process.env.REACT_APP_API_URL + "remove-televote",
                              null,
                              { guid: element.user.user_guid }
                            ).then((response) => {
                              window.location.reload();
                            });
                          }}
                        >
                          Remove vote
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </section>
          </section>
        )}
      </div>
    </div>
  );
}

export default ManageTelevotingResults;
