import React, { useState } from "react";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import { useContext } from "react";

function GeneralSettings() {
  const { setLoading } = useContext(GlobalContext);
  const [edition, setEdition] = useState(null);
  const [datetime, setDatetime] = useState(null);
  const [preclaimingDatetime, setPreclaimingDatetime] = useState(null);
  const [songSubmissionDeadline, setSongSubmissionDeadline] = useState(null);
  const [votingDeadline, setVotingDeadline] = useState(null);
  const [recapLink, setRecapLink] = useState("");
  const [hostCity, setHostCity] = useState("");
  const [hostName, setHostName] = useState("");

  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <div className="headInfo">General settings:</div>
        </section>
        <div className="defaultCenteredContainer">
          Edition:{" "}
          <input
            name="number"
            type="number"
            className="defaultInput"
            onChange={(e) => {
              setEdition(e.target.value);
            }}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(process.env.REACT_APP_API_URL + "change-edition", null, {
                edition: edition,
              }).then((response) => {
                setLoading(false);

                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set edition
          </button>
        </div>
        <div className="defaultCenteredContainer">
          Claiming date:{" "}
          <input
            name="date"
            type="datetime-local"
            className="defaultInput"
            onChange={(e) => {
              setDatetime(e.target.value);
            }}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "change-claiming-date",
                null,
                { datetime: datetime }
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set claiming date
          </button>
        </div>
        <div className="defaultCenteredContainer">
          Preclaiming date:{" "}
          <input
            name="predate"
            type="datetime-local"
            className="defaultInput"
            onChange={(e) => {
              setPreclaimingDatetime(e.target.value);
            }}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "change-preclaiming-date",
                null,
                { datetime: preclaimingDatetime }
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set preclaiming date
          </button>
        </div>
        <div className="defaultCenteredContainer">
          Song submission deadline:{" "}
          <input
            name="date"
            type="datetime-local"
            className="defaultInput"
            onChange={(e) => {
              setSongSubmissionDeadline(e.target.value);
            }}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL +
                  "change-song-submission-deadline",
                null,
                { datetime: songSubmissionDeadline }
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set song submission deadline
          </button>
        </div>
        <div className="defaultCenteredContainer">
          Jury voting deadline:{" "}
          <input
            name="date"
            type="datetime-local"
            className="defaultInput"
            onChange={(e) => {
              setVotingDeadline(e.target.value);
            }}
            value={votingDeadline}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "change-voting-deadline",
                null,
                { datetime: votingDeadline }
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set jury voting deadline
          </button>
        </div>

        <div className="defaultCenteredContainer">
          Set recap link:{" "}
          <input
            name="date"
            type="text"
            className="defaultInput"
            onChange={(e) => {
              setRecapLink(e.target.value);
            }}
            value={recapLink}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(process.env.REACT_APP_API_URL + "update-recap-link", null, {
                recapLink: recapLink,
              }).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set recap link
          </button>
        </div>

        <div className="defaultCenteredContainer">
          Host city:{" "}
          <input
            name="date"
            type="text"
            className="defaultInput"
            onChange={(e) => {
              setHostCity(e.target.value);
            }}
            value={hostCity}
          />
                    Host name:{" "}
          <input
            name="date"
            type="text"
            className="defaultInput"
            onChange={(e) => {
              setHostName(e.target.value);
            }}
            value={hostName}
          />
          <button
            className="button1"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setLoading(true);
              api(process.env.REACT_APP_API_URL + "set-host-city", null, {
                hostCity: hostCity,
                hostName: hostName,
              }).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Set host
          </button>
        </div>

        <div className="defaultCenteredContainer">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(process.env.REACT_APP_API_URL + "start-claiming").then(
                (response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Done!");
                  } else {
                    alert("Error!");
                  }
                }
              );
            }}
          >
            Start Claiming
          </button>
        </div>
        <div className="defaultCenteredContainer">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(process.env.REACT_APP_API_URL + "stop-claiming").then(
                (response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Done!");
                  } else {
                    alert("Error!");
                  }
                }
              );
            }}
          >
            Stop Claiming
          </button>
        </div>
        <div className="defaultCenteredContainer">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(process.env.REACT_APP_API_URL + "stop-song-submission").then(
                (response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Done!");
                  } else {
                    alert("Error!");
                  }
                }
              );
            }}
          >
            Stop song submission
          </button>
        </div>
        <div className="defaultCenteredContainer">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "update-results-tables",
                null,
                null,
                null,
                "POST",
                999999999999
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Update results table
          </button>
        </div>
        <div className="defaultCenteredContainer">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "turn-on-maintenence-mode"
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Turn on maintenece mode
          </button>
        </div>
        <div className="defaultCenteredContainer">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "turn-off-maintenence-mode"
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Turn off maintenece mode
          </button>
        </div>
      </form>
    </div>
  );
}

export default GeneralSettings;
