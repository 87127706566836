import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import { ReactComponent as Message } from "../assets/images/svg/message.svg";
import { ReactComponent as Logout } from "../assets/images/svg/logout.svg";
import { ReactComponent as Setting } from "../assets/images/svg/setting.svg";
import { ReactComponent as Admin } from "../assets/images/svg/wrench.svg";
import GlobalContext from "../components/GlobalContext";
import { useContext } from "react";

import { Link } from 'react-router-dom';

import ProfilePicture from "./ProfilePicture";
import { useLogout } from "../scripts/logout";

function HeaderLogin() {
    const {userData, messages,} = useContext(GlobalContext)
    const logout = useLogout();
    return (
        userData.role === "" || userData.role === null || userData.role === "guest" ?
            <>
                <div className="navLogin">
                    <Link className="button1" to="/login">Login</Link>
                    <Link className="button2" to="/register">Register</Link>
                </div>
            </>
            : <>
                <div className="navLogin">
                    {messages.length > 0 && <Link className="navLoginIcon" to="/messages"><Message /></Link>}
                    {(userData.role === 'owner' || userData.role === 'admin') && <Link className="navLoginIcon" to="/admin"><Admin /></Link>}
                    <Link className="navLoginIcon" to="/settings"><Setting /></Link>
                    <p className="navLoginIcon" onClick={() => {logout()}}><Logout /></p>
                    <div style={{height: '100%', aspectRatio: '1/1'}}>
                    <Link to={`/users/${userData.username.toLowerCase()}`}><ProfilePicture image={userData.profilePicture}></ProfilePicture></Link>
                    </div>
                </div>
            </>
    );
}


function Header() {

    return (<>
        <header>
            <nav>
                <Link className="navLogo" to="/">
                    <Logo />
                </Link>
                <div className="menuButtons">
                    <Link className="menuLink" to="/main">Main Page</Link>
                    <Link className="menuLink" to="/editions">Editions</Link>
                    <Link className="menuLink" to="/countries">Countries</Link>
                    <Link className="menuLink" to="/songs">Songs</Link>
                    <Link className="menuLink" to="/statistics">Statistics</Link>
                    <Link className="menuLink" to="/users">Users</Link>
                    <Link className="menuLink" to="/claiming">Claiming</Link>
                    <Link className="menuLink" to="/song-submission">Song Submission</Link>
                    <Link className="menuLink" to="/voting">Voting</Link>
                </div>
                <HeaderLogin />
            </nav>
        </header>
    </>
    );
}

export default Header;
