import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import GlobalContext from "../components/GlobalContext";
import { api } from "../scripts/api";
import Flag from "../components/Flag";
import ChoiceField from "../components/ChoiceField";

function ManageQualification() {
  const [qualifiersNumber, setQualifiersNumber] = useState(0);
  const [qualifiers, setQualifiers] = useState([]);

  const { setLoading } = useContext(GlobalContext);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">MANAGE QUALIFICATION</p>
          </section>
          <div className="regularText">Manage qualification</div>
        </section>
        <section className="defaultSection">
          <div className="defaultInputContainer">
            Qualifiers number:{" "}
            <input
              name="qualifiers"
              type="number"
              className="defaultInput"
              onChange={(e) => {
                setQualifiersNumber(e.target.value);
              }}
              value={qualifiersNumber}
            />
          </div>
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "load-qualification",
                null,
                { qualifiers: qualifiersNumber },
                null,
                "POST",
                1000000
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  console.log(response.responseData)
                  setQualifiers(response.responseData);
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Load qualification
          </button>
        </section>
        <section className="defaultSection defaultCenteredContainer">
          <table className="statsTable">
            <tr>
              <td>Placement</td>
              <td>Country</td>
              <td>Jury Points</td>
              <td>Televoting Points</td>
              <td>Total Points</td>
              <td>Qualification</td>
            </tr>
            {qualifiers.map((element, index) => {
              return (
                <tr key={`table_tr_${index}`}>
                  <td>{element.placement}</td>
                  <td>
                    {element.country_name} <Flag image={element.country_id} />
                  </td>
                  <td>{element.jury_results}</td>
                  <td>{element.televoting_points}</td>
                  <td>{element.full_results}</td>
                  <td>
                    <ChoiceField
                      data={[
                        { name: "Non-Qualfier", Q: 0 },
                        { name: "Qualfier", Q: 1 },
                      ]}
                      keyColumn="Q"
                      passingFunction={(item) => {
                        const newQualifiers = qualifiers;
                        newQualifiers[index].Q = item.Q;
                        setQualifiers(newQualifiers);
                        console.log(newQualifiers);
                      }}
                      value={
                        [
                          { name: "Non-Qualfier", q: 0 },
                          { name: "Qualfier", q: 1 },
                        ][element.Q]
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "accept-qualification",
                null,
                { qualifiers: qualifiers },
                null,
                "POST",
                1000000
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                    alert("Done!")
                } else {
                  alert("Error!");
                }
              });
            }}
          >
            Accept Qualification
          </button>
        </section>
      </div>
    </div>
  );
}

export default ManageQualification;
