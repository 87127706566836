import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import React from "react";
import LoginForm from "../components/LoginForm";

function LoginRequired() {
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">LOGIN REQUIRED</p>
          </section>
          <Logo className="formLogo" />
          <div className="regularText">
          You need to log in to enter this webpage.
          </div>
        </section>
        <LoginForm />
      </form>
    </div>
  );
}

export default LoginRequired;
