import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import React from "react";
import LoginForm from "../components/LoginForm";

function MaintenanceModePage() {
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">MAINTENANCE MODE</p>
          </section>
          <Logo className="formLogo" />
          <div className="regularText">
          Technical work is currently underway. Please visit the page later. You can log in if you are allowed to enter the page;
          </div>
        </section>
        <LoginForm />
      </form>
    </div>
  );
}

export default MaintenanceModePage;
