import React, { useContext, useState } from "react";
import { api } from "../scripts/api";
import ChoiceField from "../components/ChoiceField";
import GlobalContext from "../components/GlobalContext";
import ComponentsList from "../components/ComponentsList";
import RunningOrderSet from "../components/RunningOrderSet";
import RecapDescription from "../pageElements/RecapDescription";

function ShowSettings() {
  const [settings, setSettings] = useState({});
  const { setLoading } = useContext(GlobalContext);
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <div className="headInfo">Show settings:</div>
        </section>
        <section className="defaultSection">
          <div className="defaultCenteredContainer">
            Edition:{" "}
            <input
              name="number"
              type="number"
              className="defaultInput"
              onChange={(e) => {
                setSettings((prevState) => ({
                  ...prevState,
                  edition: e.target.value,
                  showType: null,
                  showNumber: null,
                }));
              }}
            />
          </div>
        </section>
        {settings.edition && (
          <section className="defaultSection">
            Show type:
            <div className="defaultCenteredContainer">
              <ChoiceField
                data={[
                  {
                    show_id: null,
                    show_name: "None",
                  },
                  {
                    show_id: "1",
                    show_name: "Heat",
                  },
                  {
                    show_id: "2",
                    show_name: "Semi Final",
                  },

                  {
                    show_id: "3",
                    show_name: "Grand Final",
                  },
                ]}
                nameColumn="show_name"
                keyColumn="show_id"
                passingFunction={(item) => {
                  setSettings((prevState) => ({
                    ...prevState,
                    showType: item.show_id,
                    showNumber: item.show_id === "3" ? "0" : null,
                  }));
                }}
              />
            </div>
          </section>
        )}
        {settings.showType && settings.showType !== "3" && (
          <section className="defaultSection">
            Show number:
            <div className="defaultCenteredContainer">
              <ChoiceField
                data={[
                  {
                    show_num: null,
                    show_n_name: "None",
                  },
                  {
                    show_num: "1",
                    show_n_name: "1",
                  },
                  {
                    show_num: "2",
                    show_n_name: "2",
                  },

                  {
                    show_num: "3",
                    show_n_name: "3",
                  },
                  {
                    show_num: "4",
                    show_n_name: "4",
                  },
                  {
                    show_num: "5",
                    show_n_name: "5",
                  },

                  {
                    show_num: "6",
                    show_n_name: "6",
                  },
                ]}
                nameColumn="show_n_name"
                keyColumn="show_num"
                passingFunction={(item) => {
                  setSettings((prevState) => ({
                    ...prevState,
                    showNumber: item.show_num,
                  }));
                }}
              />
            </div>
          </section>
        )}
        {settings.edition && settings.showType && settings.showNumber && (
          <section className="defaultSection">
            <div className="headInfo">Show settings:</div>
            <section className="defaultSection">
              <button
                className="button1"
                onClick={() => {
                  setLoading(true);
                  api(
                    process.env.REACT_APP_API_URL + "change-current-show",
                    null,
                    settings
                  ).then((response) => {
                    setLoading(false);
                    if (response.positive) {
                      alert("Done!");
                    } else {
                      alert("Error!");
                    }
                  });
                }}
              >
                Set as current show
              </button>
            </section>
            <section className="defaultSection borderShown defaultPadding">
              <div className="headInfo">Running order:</div>
              <ComponentsList
                passingFunction={(items) => {
                  setSettings((prevState) => ({
                    ...prevState,
                    running_order: items,
                  }));
                }}
              >
                <RunningOrderSet />
              </ComponentsList>
              <button
                className="button1"
                onClick={() => {
                  setLoading(true);
                  api(
                    process.env.REACT_APP_API_URL + "set-running-order",
                    null,
                    settings
                  ).then((response) => {
                    setLoading(false);
                    if (response.positive) {
                      alert("Done!");
                    } else {
                      alert("Error!");
                    }
                  });
                }}
              >
                Set running order
              </button>
            </section>

            <section className="defaultSection borderShown defaultPadding">
              <div className="headInfo">Video description:</div>
                  <RecapDescription settings={settings}/>
            </section>
          </section>
        )}
        <section className="defaultSection ">
          <div className="headInfo">General show settings:</div>

          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                setLoading(true);
                api(
                  process.env.REACT_APP_API_URL + "start-jury_voting",
                  null,
                  settings
                ).then((response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Done!");
                  } else {
                    alert("Error!");
                  }
                });
              }}
            >
              Start jury voting
            </button>
          </section>
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                setLoading(true);
                api(
                  process.env.REACT_APP_API_URL + "start-tele_voting",
                  null,
                  settings
                ).then((response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Done!");
                  } else {
                    alert("Error!");
                  }
                });
              }}
            >
              Start tele voting
            </button>
          </section>
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                setLoading(true);
                api(
                  process.env.REACT_APP_API_URL + "stop_voting",
                  null,
                  settings
                ).then((response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Done!");
                  } else {
                    alert("Error!");
                  }
                });
              }}
            >
              Stop voting
            </button>
          </section>
        </section>
      </form>
    </div>
  );
}

export default ShowSettings;
