import { useEffect, useState } from "react";
import FileUploader from "../components/FileUploader";
import ProfilePicture from "../components/ProfilePicture";
import { fileReader } from "../scripts/fileReader";

function ProfilePictureUpload({returnFunction, formData}) {
    const [image, setImage] = useState(formData.image)
    useEffect(()=>{
      if (formData.image instanceof File && (formData.image.type.startsWith('image/'))){
        fileReader(formData.image, (dataUrl) => {
          setImage(dataUrl);
      });
      } 

    },[formData.image])
  return (
    <>
        <div className="defaultInputContainer centeredInputContainer">
          <FileUploader
            key={'reader5'}
            returnFunction={(item) => {
                returnFunction(item);
                fileReader(item, (dataUrl) => {
                  setImage(dataUrl);
              });
            }}
            label="Upload profile picture"
            accept="image/*"
            maxSize={2 * 1024 * 1024}
          />
        </div>
        <div className="defaultInputContainer centeredInputContainer">
          <div className="bigProfilePictureContainer">
            <ProfilePicture image={image}></ProfilePicture>
          </div>
        </div>
        <div className="defaultInputContainer centeredInputContainer">
          <button className="button1" onClick={() => {setImage("assets/images/profile_pictures/default.png"); returnFunction("assets/images/profile_pictures/default.png")}}>
            Remove photo
          </button>
        </div>
    </>
  );
}

export default ProfilePictureUpload;