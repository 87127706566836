import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../components/GlobalContext";
import SearchChoice from "../components/SearchChoice";
import ChoiceField from "../components/ChoiceField";
import { api } from "../scripts/api";
import ProfilePicture from "../components/ProfilePicture";
import Flag from "../components/Flag";

function UserSettings() {
  const {countries} = useContext(GlobalContext)
  const [users, setUsers] = useState([]);
  const [chosenUser, setChosenUser] = useState(null);
  const [role, setRole] = useState("user");
  const [activation, setActivation] = useState("1");
  const [country, setCountry] = useState(null);
  useEffect(() => {
    api(
      process.env.REACT_APP_API_URL + "get-users",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      //SELECT `user_guid` as `guid`, `user_username` as `username`, `user_role` as `role`, `user_profile_picture` as `profile_picture`, `active` FROM `users`
      if (response.positive) {
        setUsers(response.responseData.map(element=>{return {...element, username: `${element.username} | ${element.discord_id}` }}));
      } else {
        alert("Error!");
      }
    });
  }, []);
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">USER SETTINGS</p>
          </section>
          <div className="regularText">Tools for owner of the contest</div>
        </section>
        <section className="defaultSection">
          <div className="defaultCenteredContainer">
            <SearchChoice
              nameColumn="username"
              keyColumn="guid"
              passToChildKey="profile_picture"
              data={users}
              passingFunction={(item) => {
                setChosenUser(item);
              }}
              removingFunction={() => {
                setChosenUser(null);
              }}
            >
              <ProfilePicture />
            </SearchChoice>
          </div>
        </section>
        {chosenUser && (
          <div className="outerFlexContainer">
            <div className="formFields">
              <div className="defaultCenteredContainer">
                <ChoiceField
                  data={[
                    {
                      name: "User",
                      key: "user",
                    },
                    {
                      name: "Admin",
                      key: "admin",
                    },
                    {
                      name: "Owner",
                      key: "owner",
                    },
                    {
                      name: "Visitor",
                      key: "visit",
                    },
                  ]}
                  passingFunction={(item) => {
                    setRole(item.key);
                  }}
                />
                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(process.env.REACT_APP_API_URL + "set-role", null, {
                      guid: chosenUser.guid,
                      role: role,
                    }).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Set role
                </button>
              </div>
              <div className="defaultCenteredContainer">
                <ChoiceField
                  data={[
                    {
                      name: "Active",
                      key: "1",
                    },
                    {
                      name: "Not active",
                      key: "0",
                    },
                  ]}
                  passingFunction={(item) => {
                    setActivation(item.key);
                  }}
                />
                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(process.env.REACT_APP_API_URL + "set-status", null, {
                      guid: chosenUser.guid,
                      status: activation,
                    }).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Set status
                </button>
              </div>
              <div className="defaultCenteredContainer">
                <SearchChoice
                  data={countries}
                  nameColumn="country_name"
                  keyColumn="country_id"
                  passingFunction={(item) => {
                    setCountry(item.country_id);
                  }}
                  removingFunction={() => {
                    setCountry(null);
                  }}
                  passToChildKey="country_id"
                >
                  <Flag image="" />
                </SearchChoice>
                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(process.env.REACT_APP_API_URL + "change-someones-country", null, {
                      guid: chosenUser.guid,
                      country: country,
                    }).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Set country
                </button>
              </div>
              <div className="defaultCenteredContainer">
                <button
                  className="button1"
                  onClick={() => {
                    api(process.env.REACT_APP_API_URL + "login-as", null, {
                      guid: chosenUser.guid,
                    }).then((response) => {
                      if (response.positive) {
                        localStorage.setItem(
                          "token",
                          response.responseData.token
                        );
                        window.location.reload();
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Login as
                </button>
                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(
                      process.env.REACT_APP_API_URL + "add-preclaiming",
                      null,
                      {
                        guid: chosenUser.guid,
                      }
                    ).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Add preclaiming
                </button>
                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(
                      process.env.REACT_APP_API_URL + "remove-participation",
                      null,
                      {
                        guid: chosenUser.guid,
                      }
                    ).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Remove participation
                </button>
                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(
                      process.env.REACT_APP_API_URL + "delete-account",
                      null,
                      {
                        guid: chosenUser.guid,
                      }
                    ).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Delete account
                </button>

                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(
                      process.env.REACT_APP_API_URL + "add-warning",
                      null,
                      {
                        guid: chosenUser.guid,
                      }
                    ).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Add warning
                </button>


                <button
                  className="button1"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    api(
                      process.env.REACT_APP_API_URL + "remove-warning",
                      null,
                      {
                        guid: chosenUser.guid,
                      }
                    ).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error!");
                      }
                    });
                  }}
                >
                  Remove warning
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserSettings;
