import "../styles/template.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import GlobalContext from "../components/GlobalContext";

function Template({primary, text, contrast, image = null, title = '', author = '', authorNickname = null, passingFunction = (item) => {console.log(item)} }) {
    const {setTheme} = useContext(GlobalContext);
    const link = '/users/' + author
    return (
        <div className="templateContainer">
            <div className="templateBox" onClick={() => {passingFunction({primaryColour: primary, textColour: text, backgroundColour: contrast, backgroundImage: image});setTheme({primaryColour: primary, textColour: text, backgroundColour: contrast, backgroundImage: image})}} style={image ? {backgroundImage: `url(${image})`, backgroundColor: contrast, borderColor: primary} : {backgroundColor: contrast, borderColor: primary}}>
                <div className="templateElement1" style={{borderColor: primary, backgroundColor: contrast, color: text}}>
                    Text
                </div>
                <div className="templateElement2" style={{borderColor: primary, backgroundColor: primary, color: contrast}}>
                    Text
                </div>
            </div>
            <p className="templateTitle">{title}</p>
            {authorNickname && <Link to={link}>by {authorNickname}</Link>}
        </div>
    );
}

export default Template;
