import { Link } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { api } from "../scripts/api";
import { useState } from "react";
import Flag from "../components/Flag";
import FileUploader from "../components/FileUploader";
import { fileReader } from "../scripts/fileReader";
import GlobalContext from "../components/GlobalContext";

function SongSubmission() {
  const { setLoading } = useContext(GlobalContext);
  const [claimedCountry, setClaimedCountry] = useState({ country_id: null });
  const [submitted, setSubmitted] = useState(false);
  const [settings, setSettings] = useState({
    link: "",
    song: "",
    artist: "",
    ts_minute: 0,
    ts_seconds: 0,
    image: null,
  });
  const [image, setImage] = useState(null);
  useEffect(() => {
    api(process.env.REACT_APP_API_URL + "get-claimed-country").then(
      (response) => {
        if (response.positive) {
          if (response.responseData.found) {
            setClaimedCountry(response.responseData.data);
          } else {
            setClaimedCountry({});
          }
        }
      }
    );
    api(process.env.REACT_APP_API_URL + "get-submission-user-status").then(
      (response) => {
        if (response.positive) {
          setSubmitted(response.responseData);
        }
      }
    );
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">SONG SUBMISSION</p>
          </section>
          {claimedCountry.country_id ? (
            submitted ? (
              <div className="regularText">
                Thank you for submitting your song!
            </div>
            ) : (
              <>
                <section className="defaultSection claimedCountry">
                  <div className="headInfo">Your country:</div>
                  <Link
                    to={"/countries/" + claimedCountry.country_database_name}
                  >
                    <Flag image={claimedCountry.country_id} />
                    {claimedCountry.country_name}
                  </Link>
                </section>{" "}
                <section className="defaultSection">
                  <div className="headInfo">Required:</div>

                  <div className="defaultInputContainer">
                    Youtube link: (If your song  is not on youtube type send it in DM to CinCin and type 'DM'){" "}
                    <input
                      name="youtube"
                      type="text"
                      className="defaultInput"
                      onChange={(e) => {
                        setSettings((prevState) => ({
                          ...prevState,
                          link: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="defaultInputContainer">
                    Song name:{" "}
                    <input
                      name="song"
                      type="text"
                      className="defaultInput"
                      onChange={(e) => {
                        setSettings((prevState) => ({
                          ...prevState,
                          song: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="defaultInputContainer">
                    Artist name:{" "}
                    <input
                      name="artist"
                      type="text"
                      className="defaultInput"
                      onChange={(e) => {
                        setSettings((prevState) => ({
                          ...prevState,
                          artist: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    <div className="defaultInputContainer">
                      Timestamp:{" "}
                      <input
                        name="artist"
                        type="number"
                        className="defaultInput defaultTSInput"
                        min={0}
                        value={settings.ts_minute}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) {
                            setSettings((prevState) => ({
                              ...prevState,
                              ts_minute: 0,
                            }));
                          } else {
                            setSettings((prevState) => ({
                              ...prevState,
                              ts_minute: parseInt(e.target.value),
                            }));
                          }
                        }}
                      />
                      :{" "}
                      <input
                        name="artist"
                        type="number"
                        className="defaultInput defaultTSInput"
                        min={0}
                        max={61}
                        value={settings.ts_seconds}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) {
                            setSettings((prevState) => ({
                              ...prevState,
                              ts_seconds: 0,
                            }));
                          } else if (e.target.value >= 60) {
                            setSettings((prevState) => ({
                              ...prevState,
                              ts_minute: parseInt(prevState.ts_minute) + 1,
                              ts_seconds: 0,
                            }));
                          } else {
                            setSettings((prevState) => ({
                              ...prevState,
                              ts_seconds: parseInt(e.target.value),
                            }));
                          }
                        }}
                      />{" "}
                      {" "}{!isNaN(settings.ts_seconds + settings.ts_minute) && <>-{" "}
                      {(settings.ts_seconds === "" ? 0 : settings.ts_seconds ) < 30
                        ? settings.ts_minute
                        : settings.ts_minute + 1}
                      :
                      {settings.ts_seconds < 30
                        ? settings.ts_seconds + 30
                        : (settings.ts_seconds - 30 < 10 ? `0${settings.ts_seconds - 30}` : settings.ts_seconds - 30)}</>}
                    </div>
                  </div>
                  <div className="headInfo">Optional:</div>
                  <section
                    className="defaultSection"
                    style={{ marginBottom: "60px" }}
                  >
                    <FileUploader
                      key={"reader5"}
                      returnFunction={(item) => {
                        setSettings((prevState) => ({
                          ...prevState,
                          image: item,
                        }));
                        fileReader(item, (dataUrl) => {
                          setImage(dataUrl);
                        });
                      }}
                      label="Upload singer photo"
                      accept="image/*"
                      maxSize={1024 * 1024}
                    />
                    <section className="defaultSection">
                      {image && (
                        <img alt="singer" style={{ height: "300px" }} src={image}></img>
                      )}
                    </section>
                  </section>
                  <section className="defaultSection">
                    <section className="defaultSection">
                      <button
                        className="button1"
                        onClick={() => {
                          if (
                            settings.link === null ||
                            settings.song === null ||
                            settings.artist === null ||
                            settings.link === "" ||
                            settings.song === "" ||
                            settings.artist === ""
                          ) {
                            alert("All fields must not be empty!");
                          } else {
                            setLoading(true);
                            let dataToSend = new FormData();
                            for (let key in settings) {
                              if (settings.hasOwnProperty(key)) {
                                if (key === "image" && settings.image) {
                                  dataToSend.append(
                                    key,
                                    settings[key],
                                    settings[key].name
                                  );
                                } else {
                                  dataToSend.append(key, settings[key]);
                                }
                              }
                            }
                            api(
                              process.env.REACT_APP_API_URL + "submit-a-song",
                              null,
                              dataToSend
                            ).then((response) => {
                              setLoading(false);
                              if (response.positive) {
                                window.location.reload();
                              }
                            });
                          }
                        }}
                      >
                        Submit song
                      </button>
                    </section>
                  </section>
                </section>
              </>
            )
          ) : (
            <div className="regularText">
              You need to claim a country to submit a song
            </div>
          )}
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default SongSubmission;
