import { Link } from "react-router-dom";
import GlobalContext from "../components/GlobalContext";
import { useContext } from "react";
import Message from "../components/Message";

function Messages() {
  const { messages } = useContext(GlobalContext);
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">MESSAGES</p>
          </section>
          <div className="regularText">
            New messages appear here. Old messages are deleted when you click
            "mark as read" or after some period of time
          </div>
        </section>
        <section className="defaultSection">
          {messages.map((element) => {
            return <Message message={element} />;
          })}
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Messages;
