import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import React, { useState, useContext, useCallback, useEffect } from "react";
import SearchChoice from "../components/SearchChoice";
import Tabs from "../components/Tabs";
import CustomTemplate from "../components/CustomTemplate";
import Flag from "../components/Flag";
import Themes from "../pageElements/Themes";
import PhotoLibrary from "../pageElements/PhotoLibrary";
import ProfilePictureUpload from "../pageElements/ProfilePictureUpload";
import { validateInput } from "../scripts/validateInput";
import GlobalContext from "../components/GlobalContext";
import { askForPermission, subscribeUser } from "../scripts/push-notifications";

import { api } from "../scripts/api";
import axios from "axios";

function Settings() {
  const navigate = useNavigate();
  const handleProfilePictureChange = useCallback((item) => {
    setFormData((prevState) => ({
      ...prevState,
      image: item,
    }));
  }, []);

  const handleBackgroundChange = useCallback((item) => {
    setFormData((prevState) => ({
      ...prevState,
      backgroundImage: item,
    }));
  }, []);

  const { theme, countries, setLoading } = useContext(GlobalContext);
  const validationMessage = (item, min, max, name, required) => {
    const validationResult = validateInput(item, min, max, required);

    switch (validationResult) {
      case 1:
        return null;
      case 2:
        return `${name} must not be empty`;
      case 3:
        return `${name} is too long`;
      case 4:
        return `${name} is too short`;
      case 5:
        return `${name} must contain only letters and numbers`;
      default:
        return "Unknown error";
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      setFormData((prevState) => ({
        ...prevState,
        notifications: false,
      }));
    }
  }, []);

  const [formData, setFormData] = useState({
    username: "",
    image: "assets/images/profile_pictures/default.png",
    discordID: "",
    date: "",
    profilePictureType: "",
    primaryColour: theme.primaryColour,
    textColour: theme.textColour,
    backgroundColour: theme.backgroundColour,
    backgroundImage: theme.backgroundImage,
    country: null,
    edition1: null,
    edition2: null,
    edition3: null,
    edition4: null,
    edition5: null,
    edition6: null,
    notifications: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setLoading(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api(process.env.REACT_APP_API_URL + "get_form_settings", source.token).then(
      (response) => {
        setLoading(false);
        if (response.positive) {
          setFormData(response.responseData);
        }
      }
    );
  }, [setLoading]);
  console.log(formData)

  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <Logo className="formLogo" />
          <div className="regularText">
            Here you can change settings of your worlvision account!
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">Required information:</div>
          <div className="outerFlexContainer">
            <div className="formFields">
              <div className="defaultInputContainer">
                Username:{" "}
                <input
                  name="username"
                  type="text"
                  className="defaultInput"
                  onChange={handleInputChange}
                  value={formData.username}
                />
              </div>
              <div className="defaultInputContainer">
                Discord ID:{" "}
                <input
                  name="discordID"
                  type="text"
                  className="defaultInput"
                  value={formData.discordID}
                  onChange={(e) => {
                    handleInputChange(e);
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    const { value } = e.target;
                    api(
                      process.env.REACT_APP_API_URL +
                        "get_participations_by_discord_id",
                      source.token,
                      { discordID: value }
                    ).then((response) => {
                      if (response.positive) {
                        response.responseData.forEach((item) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            ["edition" + item.edition]: countries.find(
                              (country) => country.country_id === item.country
                            ),
                          }));
                        });
                      }
                    });
                  }}
                />
              </div>
              <div className="defaultCenteredContainer">
              <a href="https://youtu.be/mc3cV57m3mM?si=qdE-Ipbg7JBM3f8C" target="_blank" className="defaultLink">
                  Click here to check how to find your discord user ID
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">Optional information:</div>
          <div className="outerFlexContainer">
            <div className="formFields">
            <section className="defaultSection">
                <div className="defaultCenteredContainer">
                  <input
                    type="checkbox"
                    id="not"
                    className="defaultCheckbox"
                    checked={!(formData.notifications == false || formData.notifications == 'false' || formData.notifications == 'null' || !formData.notifications)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        askForPermission((item) => {
                          if(item){
                            subscribeUser((subscription)=>{
                              setFormData((prevState) => ({
                                ...prevState,
                                notifications: subscription,
                              }));
                            })
                          }
                          else {
                            setFormData((prevState) => ({
                              ...prevState,
                              notifications: false,
                            }));
                          }

                        });
                      } else {
                        setFormData((prevState) => ({
                          ...prevState,
                          notifications: false,
                        }));
                      }
                    }}
                  />
                  <label htmlFor="not">
                    Receive important notifications (remember to enable
                    notifications in your web browser)
                  </label>
                </div>
              </section>
              <div className="defaultInputContainer">
                Date of Birth:{" "}
                <input
                  name="date"
                  type="date"
                  className="defaultInput"
                  onChange={handleInputChange}
                  value={
                    formData.date
                      ? new Date(formData.date).toISOString().split("T")[0]
                      : ""
                  }
                />
              </div>
              <div className="defaultInputContainer">
                Country you are from:{" "}
                <SearchChoice
                  value={countries.find(
                    (countries) => countries.country_id === formData.country
                  )}
                  data={countries}
                  nameColumn="country_name"
                  keyColumn="country_id"
                  passingFunction={(item) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      country: item.country_id,
                    }));
                  }}
                  removingFunction={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      country: null,
                    }));
                  }}
                  passToChildKey="country_id"
                >
                  <Flag image="" />
                </SearchChoice>
              </div>
            </div>
          </div>
        </section>
        <section className="defaultSection">
          <section className="defaultSection">
            <div className="headInfo">Profile picture:</div>
          </section>
          <Tabs
            opened={0}
            formData={formData}
            passingFunction={(item) => {
              setFormData((prevState) => ({
                ...prevState,
                profilePictureType: item,
                image: "assets/images/profile_pictures/default.png",
              }));
            }}
            data={[
              {
                key: "default_pfp",
                name: "Don't change",
                element: (
                  <div className="outerFlexContainer">
                    <div className="formFields">
                      <section className="defaultSection">
                        Don't change profile picture
                      </section>
                    </div>
                  </div>
                ),
              },
              {
                key: "noPFP",
                name: "No profile picture",
                element: (
                  <section className="defaultSection">
                    Remove profile picture
                  </section>
                ),
              },
              {
                key: "file",
                name: "Upload from a file",
                element: (
                  <div className="outerFlexContainer">
                    <div className="formFields">
                      <ProfilePictureUpload
                        value={formData.image}
                        formData={formData}
                        returnFunction={(item) => {
                          handleProfilePictureChange(item);
                        }}
                      />
                    </div>
                  </div>
                ),
              },
              {
                key: "library",
                name: "Choose from library",
                element: (
                  <PhotoLibrary
                    passingFunction={(item) => {
                      handleProfilePictureChange(item);
                    }}
                  />
                ),
              },
            ]}
          />
        </section>
        <section className="defaultSection">
          <section className="defaultSection">
            <div className="headInfo">Theme:</div>
          </section>
          <Tabs
            formData={formData}
            passingFunction={(item) => {
              setFormData((prevState) => ({
                ...prevState,
                themeType: item,
              }));
            }}
            data={[
              {
                key: "default",
                name: "Default templates",
                element: (
                  <Themes
                    passingFunction={(item) => {
                      setFormData(item);
                    }}
                  ></Themes>
                ),
              },
              {
                key: "custom",
                name: "Custom theme",
                element: (
                  <div className="outerFlexContainer">
                    <div className="formFields">
                      <CustomTemplate
                        passingFunction={handleInputChange}
                        formData={formData}
                        passImage={(item) => {
                          handleBackgroundChange(item);
                        }}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </section>

        <section className="defaultSection">
          <div className="headInfo">Actions:</div>
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                setLoading(true);
                api(
                  process.env.REACT_APP_API_URL + "create_change_link_user",
                  null
                ).then((response) => {
                setLoading(false);

                  if (response.positive) {
                    navigate("/link-sent");
                  } else {
                    navigate("/internal-server-error");
                  }
                });
              }}
            >
              Change password
            </button>
            <button
              className="button1"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                api(
                  process.env.REACT_APP_API_URL + "create_deletion_link",
                  null
                ).then((response) => {
                  if (response.positive) {
                    navigate("/link-sent");
                  } else {
                    navigate("/internal-server-error");
                  }
                });
              }}
            >
              Delete account
            </button>
          </section>
          <section className="defaultSection marginsSection">
            <button
              className="button1"
              onClick={() => {
                if (
                  validationMessage(
                    formData.username,
                    5,
                    50,
                    "Username",
                    true
                  ) !== null
                ) {
                  alert(
                    validationMessage(
                      formData.username,
                      5,
                      50,
                      "Username",
                      true
                    )
                  );
                } else if (
                  validationMessage(
                    formData.discordID,
                    0,
                    0,
                    "Discord ID",
                    true
                  ) !== null
                ) {
                  alert(
                    validationMessage(
                      formData.discordID,
                      0,
                      0,
                      "Discord ID",
                      true
                    )
                  );
                } else {
                  let dataToSend = new FormData();

                  // Loop through formData to add fields to FormData
                  for (let key in formData) {
                    if (formData.hasOwnProperty(key)) {
                      if (
                        key === "edition1" ||
                        key === "edition2" ||
                        key === "edition3" ||
                        key === "edition4" ||
                        key === "edition5" ||
                        key === "edition6"
                      ) {
                        if (formData[key] !== null) {
                          dataToSend.append(key, formData[key].country_id); // Append other fields to FormData
                        } else {
                          dataToSend.append(key, null); // Append other fields to FormData
                        }
                      } else if (
                        (key === "image" || key === "backgroundImage") &&
                        formData[key] instanceof File
                      ) {
                        dataToSend.append(
                          key,
                          formData[key],
                          formData[key].name
                        ); // Append the file to FormData
                      } else {
                        dataToSend.append(key, formData[key]); // Append other fields to FormData
                      }
                    }
                  }
                  setLoading(true);
                  const CancelToken = axios.CancelToken;
                  const source = CancelToken.source();

                  api(
                    process.env.REACT_APP_API_URL + "update_settings",
                    source.token,
                    dataToSend
                  ).then((response) => {
                    setLoading(false);
                    if (response.positive) {
                      alert("Settings saved!");
                      window.location.reload();
                    }
                  });
                }
              }}
            >
              Save Settings
            </button>
          </section>
        </section>
      </form>
    </div>
  );
}

export default Settings;
