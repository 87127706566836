import { useEffect, useState } from "react";
import Flag from "../components/Flag";
import { Link } from "react-router-dom";
import { api } from "../scripts/api";

function Song({ songID, edition = null }) {
  const [data, setData] = useState({});
  const [url, setUrl] = useState("");
  useEffect(() => {
    api(
      process.env.REACT_APP_API_URL + "songs/" + songID,
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      if (response.positive) {
        setData(response.responseData);
        setUrl(
          "https://www.youtube.com/embed/" +
            response.responseData.link.match(
              /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
            )[2]
        );
      }
    });
  }, [songID]);
  return (
    <div className="song">
      <iframe
        className="songFrame"
        src={url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <div className="song2columns">
        <section className="defaultSection">
          <Link to={"/countries/" + data.country_database_name}>
            <div className="entryTitleLabel">
              <Flag image={data.country}></Flag>
              {data.country_name}
            </div>
          </Link>
        </section>
        {edition ? (
          <a>
            <section className="defaultSection alignCenter songEditionIcon">
              {edition}
            </section>
          </a>
        ) : (
          <Link to={"/editions/" + data.edition}>
            <section className="defaultSection alignCenter songEditionIcon">
              {data.edition}
            </section>
          </Link>
        )}
      </div>

      <div className="entryTitleLabel">
        <Link to={`/songs/${data.display_id}`}>
          <span>
            {data.artist}
            <b> - {data.name}</b>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Song;
