import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../components/GlobalContext";
import { api } from "../scripts/api";

function Warnings() {
  const [warnings, setWarnings] = useState([]);
  const { setLoading } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api(process.env.REACT_APP_API_URL + "get-warnings").then((response) => {
      setLoading(false);
      if (response.positive) {
        setWarnings(response.responseData);
      } else {
        navigate("/internal-server-error");
      }
    });
  }, []);
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">WARNINGS</p>
          </section>
          <section className="defaultCenteredContainer">
            <div style={{ overflowX: "auto" }}>
              <table className="statsTable">
                <tr>
                  <td>
                    <b>User</b>
                  </td>
                  <td>
                    <b>Warnings</b>
                  </td>
                </tr>
                {warnings.map((user) => {
                  return (
                    <tr>
                      <td>
                        {user.user_username} - {user.user_discord_id}
                      </td>
                      <td>{user.warnings}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </section>
        </section>
        <section className="defaultSection">
          <button onClick={()=>{
            setLoading(true);
            api(process.env.REACT_APP_API_URL + "remove-everyones-warnings").then(
              (response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              }
            );
          }}
          className="button1"
          >
            Remove everyone's warnings
          </button>
        </section>
        <section className="defaultSection">
          <button onClick={()=>{
            setLoading(true);
            api(process.env.REACT_APP_API_URL + "punish-non-voters").then(
              (response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              }
            );
          }}
          className="button1"
          >
            Punish Non-Voters
          </button>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Warnings;
