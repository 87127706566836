import React, { useEffect, useState } from "react";

function ComponentsList({
  children,
  buttonText = "Add new",
  defaultListItem = { key: 0, object: {} },
  limit = 9999,
  passingFunction = ()=>{},
  defaultItems = null
}) {
  const [componentsList, setComponentsList] = useState(defaultItems ? defaultItems : []);
  useEffect(()=>{
    passingFunction(componentsList)
  },[componentsList])

  useEffect(()=>{
    if(defaultItems !== null){
      setComponentsList(defaultItems)
    }
  },[defaultItems])

  return (
    <>
      {componentsList.map((item, index) => {
        return (
          <div className="defaultCenteredContainer" key={item.key}>
            {React.cloneElement(React.Children.toArray(children)[0], {
              passingFunction: (newItem) => {
                setComponentsList((previousData) => {
                  return previousData.map((oldItem) => {
                    if (oldItem.key === item.key) {
                      const itemReplacement = defaultListItem;
                      itemReplacement.key = oldItem.key;
                      itemReplacement.object = newItem;
                      return itemReplacement;
                    }
                    return oldItem;
                  });
                });
              },
              removingFunction: () => {
                setComponentsList((previousData) => {
                  return previousData.map((oldItem) => {
                    if (oldItem.key === item.key) {
                      return {
                        key: item.key,
                        object: {},
                      };
                    }
                    return oldItem;
                  });
                });
              },
              key: "inner_element_" + item.key,
              index : index,
              item: item.object
            })}
            <button
              className="button1"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setComponentsList((previousData) => {
                  return previousData.filter(
                    (innerItem) => innerItem.key !== item.key
                  );
                });
              }}
            >
              Remove
            </button>
          </div>
        );
      })}
      <div className="defaultCenteredContainer">
        {componentsList.length < limit && (
          <button
            className="button1"
            onClick={() => {
              if (componentsList.length === 0) {
                setComponentsList([defaultListItem]);
              } else {
                const newItem = defaultListItem;
                newItem.key =
                  Math.max(...componentsList.map((item) => Number(item.key))) +
                  1;
                setComponentsList((previousData) => {
                  return [...previousData, newItem];
                });
              }
            }}
          >
            {buttonText}
          </button>
        )}
      </div>
    </>
  );
}

export default ComponentsList;
