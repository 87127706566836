import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import Tabs from "../components/Tabs";
import { useEffect } from "react";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import Flag from "../components/Flag";
import ProfilePicture from "../components/ProfilePicture";

function Stats() {
  const [stats, setStats] = useState(null);
  const { setLoading } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-stats",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        setStats(response.responseData);
      } else {
        navigate("/internal-server-error");
      }
    });
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm widePage"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">{(process.env.REACT_CONTEST || "").toUpperCase()} STATISTICS</p>
          </section>
        </section>
        <section className="defaultSection">
          {stats && (
            <Tabs
              data={Object.entries(stats).map(([key, value]) => {
                switch (key) {
                  case "countries":
                    return {
                      key: key,
                      name: value.tab_name,
                      element: (
                        <Tabs
                          data={value.stats.map((stat) => {
                            return {
                              key: stat.stat_id,
                              name: stat.stat_tab_name,
                              element: (
                                <section className="defaultSection">
                                  <p className="headInfo">{stat.stat_desc}</p>
                                  <section className="defaultCenteredContainer">
                                    <div style={{ overflowX: "auto" }}>
                                      <table className="statsTable">
                                        <tr>
                                          <td>
                                            <b>Placement</b>
                                          </td>
                                          <td>
                                            <b>Country</b>
                                          </td>
                                          <td>
                                            <b>{stat.stat_label}</b>
                                          </td>
                                        </tr>
                                        {stat.stat.map((entry, index) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    class="adminRO"
                                                    style={{ margin: "0" }}
                                                  >
                                                    {index + 1}
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                {" "}
                                                <Link
                                                  to={
                                                    "/countries/" +
                                                    entry.country_database_name
                                                  }
                                                  class="teleCountry"
                                                >
                                                  <Flag
                                                    image={entry.country_id}
                                                  />
                                                  {entry.country_name}
                                                </Link>
                                              </td>
                                              <td>{entry.stat}</td>
                                            </tr>
                                          );
                                        })}
                                      </table>
                                    </div>
                                  </section>
                                </section>
                              ),
                            };
                          })}
                        />
                      ),
                    };
                  case "users":
                    return {
                      key: key,
                      name: value.tab_name,
                      element: (
                        <Tabs
                          data={value.stats.map((stat) => {
                            return {
                              key: stat.stat_id,
                              name: stat.stat_tab_name,
                              element: (
                                <section className="defaultSection">
                                  <p className="headInfo">{stat.stat_desc}</p>
                                  <section className="defaultCenteredContainer">
                                    <div style={{ overflowX: "auto" }}>
                                      <table className="statsTable">
                                        <tr>
                                          <td>
                                            <b>Placement</b>
                                          </td>
                                          <td>
                                            <b>User</b>
                                          </td>
                                          <td>
                                            <b>{stat.stat_label}</b>
                                          </td>
                                        </tr>
                                        {stat.stat.map((entry, index) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    class="adminRO"
                                                    style={{ margin: "0" }}
                                                  >
                                                    {index + 1}
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="userStatCell">
                                                {" "}
                                                <Link
                                                  to={
                                                    "/users/" +
                                                    entry.user_username.toLowerCase()
                                                  }
                                                  class="teleCountry"
                                                >
                                                  <ProfilePicture
                                                    image={
                                                      entry.user_profile_picture
                                                    }
                                                  />
                                                  {entry.user_username}
                                                </Link>
                                                </div>
                                              </td>
                                              <td>{entry.stat}</td>
                                            </tr>
                                          );
                                        })}
                                      </table>
                                    </div>
                                  </section>
                                </section>
                              ),
                            };
                          })}
                        />
                      ),
                    };
                  case "continents":
                    return {
                      key: key,
                      name: value.tab_name,
                      element: (
                        <Tabs
                          data={value.stats.map((stat) => {
                            return {
                              key: stat.stat_id,
                              name: stat.stat_tab_name,
                              element: (
                                <section className="defaultSection">
                                  <p className="headInfo">{stat.stat_desc}</p>
                                  <section className="defaultCenteredContainer">
                                    <div style={{ overflowX: "auto" }}>
                                      <table className="statsTable">
                                        <tr>
                                          <td>
                                            <b>Running Order</b>
                                          </td>
                                          <td>
                                            <b>Continent</b>
                                          </td>
                                          <td>
                                            <b>{stat.stat_label}</b>
                                          </td>
                                        </tr>
                                        {stat.stat.map((entry, index) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    class="adminRO"
                                                    style={{ margin: "0" }}
                                                  >
                                                    {index + 1}
                                                  </div>
                                                </div>
                                              </td>
                                              <td>{entry.continent_name}</td>
                                              <td>{entry.stat}</td>
                                            </tr>
                                          );
                                        })}
                                      </table>
                                    </div>
                                  </section>
                                </section>
                              ),
                            };
                          })}
                        />
                      ),
                    };
                  case "running_order":
                    return {
                      key: key,
                      name: value.tab_name,
                      element: (
                        <Tabs
                          data={value.stats.map((stat) => {
                            return {
                              key: stat.stat_id,
                              name: stat.stat_tab_name,
                              element: (
                                <section className="defaultSection">
                                  <p className="headInfo">{stat.stat_desc}</p>
                                  <section className="defaultCenteredContainer">
                                    <div style={{ overflowX: "auto" }}>
                                      <table className="statsTable">
                                        <tr>
                                          <td>
                                            <b>Running Order</b>
                                          </td>
                                          <td>
                                            <b>{stat.stat_label}</b>
                                          </td>
                                        </tr>
                                        {stat.stat.map((entry, index) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <div
                                                    class="adminRO"
                                                    style={{ margin: "0" }}
                                                  >
                                                    {index + 1}
                                                  </div>
                                                </div>
                                              </td>
                                              <td>{entry.stat}</td>
                                            </tr>
                                          );
                                        })}
                                      </table>
                                    </div>
                                  </section>
                                </section>
                              ),
                            };
                          })}
                        />
                      ),
                    };
                }
              })}
            />
          )}
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Stats;
