import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { api } from "../scripts/api";

function EditionsPage() {
  const [editionsButtons, setEditionsButtons] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    api(
      process.env.REACT_APP_API_URL + "get-editions-list",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      if (response.positive) {
        setEditionsButtons(response.responseData)
        console.log(response.responseData)
      } else {
        navigate("/internal-server-error");
      }
    });
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">{(process.env.REACT_APP_CONTEST || "").toUpperCase()} EDITIONS</p>
          </section>
          <section className="defaultSection">
            {
              editionsButtons.map((element)=>{
                return <section className="defaultSection">
                {" "}
                <Link className="button1 horizontalMargins" to={"/editions/" + element.edition}>
                  {element.name}
                </Link>
              </section>
              })
            }
          </section>

        </section>
        <br></br>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default EditionsPage;
