import { Link } from "react-router-dom";
import React from "react";

function InternalServerError() {
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">INTERNAL SERVER ERROR</p>
          </section>
          <div className="regularText">
            An unexpected error occurred
          </div>
        </section>
        <section className="defaultSection">
        <Link className="button1" to="/main">
              Main page
        </Link>
        </section>
      </div>
    </div>
  );
}

export default InternalServerError;
