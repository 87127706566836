import ProfilePicture from "../components/ProfilePicture";
import Paging from "../components/Paging";
import { useEffect, useState } from "react";
import { api } from "../scripts/api";
import axios from "axios";
function PhotoLibrary({ passingFunction }) {
  const [Data, setData] = useState([]);
  const [slicedData, setSlicedData] = useState([]);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api(
      process.env.REACT_APP_API_URL + "photo-library",
      source.token,
      null,
      null,
      "GET"
    ).then((response) => {
      if (response.positive) {
        setData(response.responseData)
      }
    });
  }, []);
  const [chosenPFP, setChosenPFP] = useState(null);
  return (
    <section className="defaultSection">
      {!chosenPFP ? (
        <div className="photoLibraryContainer">
          {slicedData.map((element) => (
            <>
              <div className="photoLibraryItem">
                <div className="photoLibraryPicture" onClick={() => {setChosenPFP(element.path);passingFunction(element.path)}} style={{cursor: "pointer"}}>
                  <ProfilePicture key={element.key} image={element.path} />
                </div>

                <div>{element.name}</div>
              </div>
            </>
          ))}
        </div>
      ) : (
        <div>
          <div className="photoLibraryContainer">
            {" "}
            <div className="photoLibraryItem">
              <div className="photoLibraryPicture">
                <ProfilePicture
                  image={
                    chosenPFP
                  }
                />
              </div>
              <button className="button1" onClick={() => {setChosenPFP(null); passingFunction("assets/images/profile_pictures/default.png")}}>Remove profile picture</button>
            </div>
          </div>
        </div>
      )}
      {!chosenPFP && (
        <Paging
          elementsPerPage={4}
          passingFunction={(data1) => {
            setSlicedData(data1);
          }}
          data={Data}
        />
      )}
    </section>
  );
}
export default PhotoLibrary;
