import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../components/GlobalContext";
import { api } from "../scripts/api";
import Flag from "../components/Flag";
import Song from "../pageElements/Song";
import Paging from "../components/Paging";
import Tabs from "../components/Tabs";

function Edition() {
  const params = useParams();
  const edition = params.edition;
  const { setLoading } = useContext(GlobalContext);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-edition-info/" + edition,
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        setData(response.responseData);
        console.log(response.responseData);
      } else {
        if (response.responseData.data.type === "not_found") {
          navigate("/page-not-found");
        } else {
          navigate("/internal-server-error");
        }
      }
    });
  }, []);
  return (
    <>
      {data && (
        <div className="defaultFormContainer">
          <div
            className="defaultForm defaultWider"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">EDITION {edition}</p>

                <p className="headInfo">{data.host_cities.name}</p>
              </section>
              <div className="defaultCenteredContainer">
                <div style={{ overflowX: "auto" }}>
                  <table className="statsTable">
                    <tr>
                      <td>Host country</td>
                      <td>
                        {data.host_countries.map((country) => {
                          return (
                            <Link
                              to={"/countries/" + country.country_database_name}
                              className="textAlignCell"
                            >
                              <div
                                className="countryLink editionHostCountry"
                                style={{ width: "minContent" }}
                              >
                                <Flag image={country.country_id}></Flag>
                                {country.country_name}
                              </div>
                            </Link>
                          );
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Host city</td>
                      <td>{data.host_cities.host_city}</td>
                    </tr>
                    {data.host_cities.slogan && (
                      <tr>
                        <td>Slogan</td>
                        <td>{data.host_cities.slogan}</td>
                      </tr>
                    )}
                    {data.host_cities.hosts && (
                      <tr>
                        <td>Hosts</td>
                        <td>{data.host_cities.hosts}</td>
                      </tr>
                    )}
                    {data.host_cities.artwork_image && (
                      <tr>
                        <td>Artwork</td>
                        <td>
                          <img
                            src={data.host_cities.artwork_image}
                            className="editionImage"
                          />
                        </td>
                      </tr>
                    )}
                    {data.host_cities.stage_image && (
                      <tr>
                        <td>Stage</td>
                        <td>
                          <img
                            src={data.host_cities.stage_image}
                            className="editionImage"
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Participating countries</td>
                      <td>{data.number_of_participants.number}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </section>
            {data.winner && <section className="defaultSection">
              <section className="defaultSection">
                <div className="photoLibraryContainer">
                  <div className="margin_container">
                    <p className="headInfo">Winner</p>
                    <Song songID={data.winner.song_id} />
                  </div>
                  <div className="margin_container">
                    <p className="headInfo">Runner up</p>
                    <Song songID={data.runner_up.song_id} />
                  </div>
                  <div className="margin_container">
                    <p className="headInfo">Third place</p>
                    <Song songID={data.third_place.song_id} />
                  </div>
                </div>
              </section>
            </section>}
            <div className="defaultCenteredContainer">
              {data.host_entries.map((country) => {
                return (
                  <div className="margin_container">
                    <p className="headInfo">Host entry</p>
                    <Song songID={country.song_id} />
                  </div>
                );
              })}
            </div>
            <section className="defaultSection">
              <ResultsBrowser
                results={data.results}
                running_orders={data.running_orders}
                full_results={data.full_results}
                jury_votes={data.jury_votes}
                participating_countries={data.participating_countries}
                debuting_countries={data.debuting_countries}
                returning_countries={data.returning_countries}
                returning_from_previous_editions={
                  data.returning_from_previous_editions
                }
                withdrawing_countries={data.withdrawing_countries}
              />
              <section className="defaultSection"></section>

              <p className="headInfo">
                All songs participating in edition {edition} of {process.env.REACT_CONTEST_FULL || ""}
              </p>
              <AllSongs allSongs={data.all_songs} />
            </section>

            <section className="defaultSection">
              <Link className="button1 horizontalMargins" to={"/main"}>
                Main page
              </Link>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

function AllSongs({ allSongs }) {
  const [displayedSongs, setDisplayedSongs] = useState([]);

  return (
    <section className="defaultSection">
      <section className="photoLibraryContainer">
        {displayedSongs.map((song) => {
          return (
            <div key={song.song_id} className="margin_container">
              <Song songID={song.song_id} />
            </div>
          );
        })}
      </section>

      <Paging
        elementsPerPage={3}
        passingFunction={(data) => {
          setDisplayedSongs(data);
        }}
        data={allSongs}
      />
    </section>
  );
}

function ResultsBrowser({
  running_orders,
  results,
  jury_votes,
  full_results,
  participating_countries,
  debuting_countries,
  returning_countries,
  returning_from_previous_editions,
  withdrawing_countries,
}) {
  let tabs_data = [];
  let participants_tabs = [];

  if (participating_countries.length !== 0) {
    participants_tabs.push({
      key: "participating_countries",
      name: "Participating Countries",
      element: <ParticipationDisplay list={participating_countries} type={1} />,
    });
  }

  if (debuting_countries.length !== 0) {
    participants_tabs.push({
      key: "debuting_countries",
      name: "Debuting Countries",
      element: <ParticipationDisplay list={debuting_countries} type={2} />,
    });
  }

  if (returning_countries.length !== 0) {
    participants_tabs.push({
      key: "returning_countries",
      name: "Returning Countries",
      element: <ParticipationDisplay list={returning_countries} type={3} />,
    });
  }

  if (returning_from_previous_editions.length !== 0) {
    participants_tabs.push({
      key: "returning_from_previous_editions_countries",
      name: "Countries Returning From Previous Editions",
      element: (
        <ParticipationDisplay
          list={returning_from_previous_editions}
          type={4}
        />
      ),
    });
  }

  if (withdrawing_countries.length !== 0) {
    participants_tabs.push({
      key: "withdrawing_countries",
      name: "Withdrawing Countries",
      element: <ParticipationDisplay list={withdrawing_countries} type={5} />,
    });
  }

  if (running_orders.length !== 0) {
    tabs_data.push({
      key: "running_order",
      name: "Running Order",
      element: <RunningOrderBrowser running_orders={running_orders} />,
    });
  }
  if (results.length !== 0) {
    tabs_data.push({
      key: "results",
      name: "Shows Results",
      element: <ResultsTabBrowser results={results} />,
    });
  }
  if (jury_votes.length !== 0) {
    tabs_data.push({
      key: "jury_votes",
      name: "Jury Votes",
      element: <JuryVotesBrowser jury_votes={jury_votes} />,
    });
  }

  if (participants_tabs.length !== 0) {
    tabs_data.push({
      key: "participants",
      name: "Participants",
      element: (
        <div>
          <Tabs data={participants_tabs} />
        </div>
      ),
    });
  }
  if (full_results) {
    tabs_data.push({
      key: "full_results",
      name: "Edition Results",
      element: (
        <section className="defaultCenteredContainer">
          <div style={{ overflowX: "auto" }}>
            <table className="statsTable">
              <tr>
                <td>
                  <b>Placement</b>
                </td>
                <td>
                  <b>Country</b>
                </td>
                <td>
                  <b>Points</b>
                </td>
                <td>
                  <b>Show</b>
                </td>
              </tr>
              {full_results.map((results) => {
                return (
                  <tr>
                    <td>
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <div class="adminRO" style={{ margin: "0" }}>
                          {results.position}
                        </div>
                      </div>
                    </td>
                    <td>
                      <Link
                        to={"/countries/" + results.country_database_name}
                        class="teleCountry"
                      >
                        <Flag image={results.country_id} />
                        {results.country_name}
                      </Link>
                    </td>
                    <td>{results.points}</td>
                    <td>
                      {" "}
                      {results.max_show === 1
                        ? "Heat"
                        : results.max_show === 2
                        ? "Semi Final"
                        : results.max_show === 3
                        ? "Grand Final"
                        : null}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </section>
      ),
    });
  }
  return (
    <>
      {running_orders.length !== 0 && results.length !== 0 && (
        <div>
          <p className="headInfo">Browse edition's data</p>

          <Tabs data={tabs_data} />
        </div>
      )}
    </>
  );
}

function RunningOrderBrowser({ running_orders }) {
  return (
    <div>
      <Tabs
        data={running_orders.map((ro) => {
          switch (ro.show_type) {
            case 1:
              return {
                key: "heats_running_orders",
                name: "Heats",
                element: (
                  <RunningOrderDisplay
                    key="heats_running_orders_display"
                    running_orders={ro.running_orders}
                    show_type={ro.show_type}
                  />
                ),
              };

            case 2:
              return {
                key: "semi_finals_running_orders",
                name: "Semi Finals",
                element: (
                  <RunningOrderDisplay
                    key="semi_finals_running_orders_display"
                    running_orders={ro.running_orders}
                    show_type={ro.show_type}
                  />
                ),
              };

            case 3:
              return {
                key: "grand_final_running_order",
                name: "The Grand Final",
                element: (
                  <RunningOrderDisplay
                    key="grand_final_running_order_running_orders_display"
                    running_orders={ro.running_orders}
                    show_type={ro.show_type}
                  />
                ),
              };
          }
        })}
      />
    </div>
  );
}

function ResultsTabBrowser({ results }) {
  return (
    <div>
      <Tabs
        data={results.map((result) => {
          switch (result.show_type) {
            case 1:
              return {
                key: "heats_results",
                name: "Heats",
                element: (
                  <ResultsDisplay
                    key="heats_running_orders_display"
                    results={result.results}
                    show_type={result.show_type}
                  />
                ),
              };

            case 2:
              return {
                key: "semi_finals_results",
                name: "Semi Finals",
                element: (
                  <ResultsDisplay
                    key="semi_finals_running_orders_display"
                    results={result.results}
                    show_type={result.show_type}
                  />
                ),
              };

            case 3:
              return {
                key: "grand_final_results",
                name: "The Grand Final",
                element: (
                  <ResultsDisplay
                    key="grand_final_running_order_running_orders_display"
                    results={result.results}
                    show_type={result.show_type}
                  />
                ),
              };
          }
        })}
      />
    </div>
  );
}

function RunningOrderDisplay({ running_orders, show_type }) {
  const params = useParams();
  const edition = params.edition;
  const [pagedData, setPagedData] = useState([]);
  return (
    <section className="defaultSection">
      {pagedData.length === 1 && (
        <p className="headInfo">
          Running order of{" "}
          {show_type === 1
            ? "the Heat"
            : show_type === 2
            ? "the Semi Final"
            : show_type === 3
            ? "the Grand Final"
            : null}{" "}
          {pagedData[0].show_number !== 0 && pagedData[0].show_number} of
          edition {edition} of {process.env.REACT_CONTEST_FULL || ""}
        </p>
      )}

      <section className="defaultSection">
        <section className="televotingOuterConteiner">
          <section className="televotingInnerConteiner">
            {pagedData.length === 1 && (
              <>
                {pagedData[0].running_order.map((ro) => {
                  return (
                    <Link
                      to={"/countries/" + ro.country_database_name}
                      class="teleCountry"
                    >
                      {" "}
                      <div class="adminRO">
                        {ro.participation_running_order}
                      </div>
                      <Flag image={ro.country_id} />
                      {ro.country_name}
                    </Link>
                  );
                })}
              </>
            )}
          </section>
        </section>
      </section>

      <Paging
        elementsPerPage={1}
        passingFunction={(data) => {
          setPagedData(data);
        }}
        data={running_orders}
      />
    </section>
  );
}

function ResultsDisplay({ results, show_type }) {
  const params = useParams();
  const edition = params.edition;
  const [pagedData, setPagedData] = useState([]);
  return (
    <section className="defaultSection">
      {pagedData.length === 1 && (
        <p className="headInfo">
          Results of{" "}
          {show_type === 1
            ? "the Heat"
            : show_type === 2
            ? "the Semi Final"
            : show_type === 3
            ? "the Grand Final"
            : null}{" "}
          {pagedData[0].show_number !== 0 && pagedData[0].show_number} of
          edition {edition} of {process.env.REACT_CONTEST_FULL || ""}
        </p>
      )}

      <section className="defaultCenteredContainer">
        <div style={{ overflowX: "auto" }}>
          <table className="statsTable">
            <tr>
              <td>
                <b>Placement</b>
              </td>
              <td>
                <b>Country</b>
              </td>
              <td>
                <b>Points</b>
              </td>
              <td>
                <b>Jury points</b>
              </td>
              <td>
                <b>Jury placement</b>
              </td>
              <td>
                <b>Televoting points</b>
              </td>
              <td>
                <b>Televoting placement</b>
              </td>
              <td>
                <b>Qualification</b>
              </td>
            </tr>
            {pagedData.length === 1 && (
              <>
                {pagedData[0].result.map((results) => {
                  return (
                    <tr>
                      <td>
                        <div
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <div class="adminRO" style={{ margin: "0" }}>
                            {results.placement}
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link
                          to={"/countries/" + results.country_database_name}
                          class="teleCountry"
                        >
                          <Flag image={results.country_id} />
                          {results.country_name}
                        </Link>
                      </td>
                      <td>{results.full_results}</td>
                      <td>
                        {show_type === 1
                          ? "-"
                          : results.jury_results !== null
                          ? results.jury_results
                          : "-"}
                      </td>
                      <td>
                        {show_type === 1
                          ? "-"
                          : results.jury_placement !== null
                          ? results.jury_placement
                          : "-"}
                      </td>

                      <td>{results.televoting_points}</td>
                      <td>{results.televoting_placement}</td>
                      <td>
                        {" "}
                        {results.qualification === 1
                          ? "Q"
                          : results.qualification === 2
                          ? "NQ"
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </table>
        </div>
      </section>

      <Paging
        elementsPerPage={1}
        passingFunction={(data) => {
          setPagedData(data);
        }}
        data={results}
      />
    </section>
  );
}

function JuryVotesBrowser({ jury_votes }) {
  return (
    <div>
      <Tabs
        data={jury_votes.map((jury_vote) => {
          switch (jury_vote.show_type) {
            case 1:
              return {
                key: "heats_jury_votes",
                name: "Heats",
                element: (
                  <JuryVotes
                    key="heats_jury_votes_display"
                    jury_votes={jury_vote.jury_votes}
                    show_type={jury_vote.show_type}
                  />
                ),
              };

            case 2:
              return {
                key: "semi_finals_jury_votes",
                name: "Semi Finals",
                element: (
                  <JuryVotes
                    key="semi_finals_jury_votes_display"
                    jury_votes={jury_vote.jury_votes}
                    show_type={jury_vote.show_type}
                  />
                ),
              };

            case 3:
              return {
                key: "grand_final_jury_votes",
                name: "The Grand Final",
                element: (
                  <JuryVotes
                    key="grand_final_jury_votes_display"
                    jury_votes={jury_vote.jury_votes}
                    show_type={jury_vote.show_type}
                  />
                ),
              };
          }
        })}
      />
    </div>
  );
}

function JuryVotes({ jury_votes, show_type }) {
  const params = useParams();
  const edition = params.edition;
  const [pagedData, setPagedData] = useState([]);
  return (
    <section className="defaultSection">
      {pagedData.length === 1 && (
        <p className="headInfo">
          Jury votes of{" "}
          {show_type === 1
            ? "the Heat"
            : show_type === 2
            ? "the Semi Final"
            : show_type === 3
            ? "the Grand Final"
            : null}{" "}
          {pagedData[0].show_number !== 0 && pagedData[0].show_number} of
          edition {edition} of {process.env.REACT_CONTEST_FULL || ""}
        </p>
      )}

      <section className="defaultCenteredContainer">
        <div style={{ overflowX: "auto" }}>
          <table className="statsTable">
            <tr>
              <td>
                <b>Country voting</b>
              </td>
              <td>
                <b>1</b>
              </td>
              <td>
                <b>2</b>
              </td>
              <td>
                <b>3</b>
              </td>
              <td>
                <b>4</b>
              </td>
              <td>
                <b>5</b>
              </td>
              <td>
                <b>6</b>
              </td>
              <td>
                <b>7</b>
              </td>
              <td>
                <b>8</b>
              </td>
              <td>
                <b>10</b>
              </td>
              <td>
                <b>12</b>
              </td>
            </tr>
            {pagedData.length === 1 && (
              <>
                {pagedData[0].jury_vote.map((results) => {
                  return (
                    <tr>
                      <td className="countryLinkFlag">
                        {results.country_voting === 999 ? (
                          <Link to={""}>
                            <Flag image={results.country_voting} />
                          </Link>
                        ) : (
                          <Link
                            to={
                              "/countries/" +
                              results.voting_country_database_name
                            }
                          >
                            <Flag image={results.country_voting} />
                          </Link>
                        )}
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p1_database_name}>
                          <Flag image={results.p1} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p2_database_name}>
                          <Flag image={results.p2} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p3_database_name}>
                          <Flag image={results.p3} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p4_database_name}>
                          <Flag image={results.p4} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p5_database_name}>
                          <Flag image={results.p5} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p6_database_name}>
                          <Flag image={results.p6} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p7_database_name}>
                          <Flag image={results.p7} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p8_database_name}>
                          <Flag image={results.p8} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p10_database_name}>
                          <Flag image={results.p10} />
                        </Link>
                      </td>

                      <td className="countryLinkFlag">
                        <Link to={"/songs/" + results.p12_database_name}>
                          <Flag image={results.p12} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </table>
        </div>
      </section>

      <Paging
        elementsPerPage={1}
        passingFunction={(data) => {
          setPagedData(data);
        }}
        data={jury_votes}
      />
    </section>
  );
}

function ParticipationDisplay({ list, type }) {
  const params = useParams();
  const edition = params.edition;
  return (
    <section className="defaultSection">
      <p className="headInfo">
        {type === 1
          ? `All participating countries in the edition ${edition} of ${process.env.REACT_CONTEST_FULL || ""}`
          : type === 2
          ? `Countries debuting in ${process.env.REACT_CONTEST_FULL || ""} in edition ${edition}`
          : type === 3
          ? `Countries taking part again in edition ${edition} of ${process.env.REACT_CONTEST_FULL || ""} after they took part in the previous edition`
          : type === 4
          ? `Countries taking part in edition ${edition} of ${process.env.REACT_CONTEST_FULL || ""} after a break`
          : type === 5
          ? `Countries that took part in previous edition but didn't take part in the edition ${edition} of ${process.env.REACT_CONTEST_FULL || ""}`
          : null}
      </p>

      <section className="defaultSection">
        <section className="televotingOuterConteiner">
          <section className="televotingInnerConteiner">
            {list.map((participation) => {
              return (
                <Link
                  to={"/countries/" + participation.country_database_name}
                  class="teleCountry"
                >
                  <Flag image={participation.country_id} />
                  {participation.country_name}
                </Link>
              );
            })}
          </section>
        </section>
      </section>
    </section>
  );
}

export default Edition;
