import { Link } from "react-router-dom";
import React from "react";

function TermsOfUse() {
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <p className="hugeHeadline">Terms of Use & Privacy Policy</p>
        </section>
        <section className="defaultSection">
          <div className="headInfo">1. Introduction</div>
          <div className="regularText">
            Welcome to worldvision.cincin.net.pl. These terms and conditions
            outline the rules and regulations for the use of the website owned
            by Marcin Kamiński.
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">2. Acceptance of Terms</div>
          <div className="regularText">
            By accessing and using worldvision.cincin.net.pl, you accept and
            agree to be bound by the terms and provisions of this agreement.
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">3. Respectful Behavior</div>
          <div className="regularText">
            All users of the website are expected to: 
            <ol className="defaultLit">
              <li>Treat others with respect.</li>
              <li>Refrain from publishing harmful, offensive, or inappropriate content.</li>
              <li>Respect the rights and privacy of others.</li>
            </ol>
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">4. Privacy Policy</div>
          <div className="regularText">
            <ol className="defaultLit">
              <li>Personal Data Collection: The primary personal data we collect is your email address, which is used for logging into our site.</li>
              <li>Data Use: Your email address will be used exclusively for account-related processes, such as logging in, password recovery, and essential notifications.</li>
              <li>Data Protection: We take necessary precautions to protect your personal data and ensure it is not accessed unlawfully.</li>
              <li>Data Deletion: If you wish to delete your account and all associated data, you may do so through the account settings. Alternatively, if you want specific data removed, contact us at worldvision@cincin.net.pl.</li>
              <li>Cookies: Our website may use cookies to enhance user experience. By using our website, you consent to the use of cookies in accordance with our privacy policy.</li>
            </ol>
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">5. Content</div>
          <div className="regularText">
            <ol className="defaultLit">
              <li>You are solely responsible for any content you post, share, or otherwise distribute on worldvision.cincin.net.pl.</li>
              <li>Marcin Kamiński reserves the right to remove or modify any content deemed inappropriate or harmful without prior notice.</li>
            </ol>
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">6. Termination</div>
          <div className="regularText">
            We reserve the right to terminate any account that violates our terms of use or engages in any malicious activities.
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">7. Changes to These Terms</div>
          <div className="regularText">
            We may revise these terms and conditions from time to time. Revised terms and conditions will apply from their date of publication on the website.
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">8. Contact</div>
          <div className="regularText">
            For any concerns, questions, or feedback, please contact: 
            <br/>Marcin Kamiński 
            <br/>Email: worldvision@cincin.net.pl
          </div>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default TermsOfUse;
