import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import React from 'react';

import LoginForm from "../components/LoginForm";

function Login() {

    return (
        <div className="defaultFormContainer">
            <form className="defaultForm" onSubmit={(e) => { e.preventDefault() }}>
                <section className="defaultSection">
                    <Logo className="formLogo" />
                    <div className='regularText'>
                        Use your {process.env.REACT_CONTEST || ""} account to take part in the competition, share your tops and use many other interesting options! If you haven't created your account yet <Link className="defaultLink" to="/register">register here</Link>. Remember you need to be on the Worldvision discord server to make sure everything will work properly
                    </div>
                </section>
                <LoginForm />
            </form>
        </div>

    );
}

export default Login;
