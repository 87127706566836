import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Register from "./pages/Register";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import LoginRequired from "./pages/LoginRequired";
import Unauthorized from "./pages/Unauthorized";
import ConnectionFailed from "./pages/ConnectionFailed";
import InternalServerError from "./pages/InternalServerError";
import TermsOfUse from "./pages/TermsOfUse";
import AccountCreated from "./pages/AccountCreated";
import Activate from "./pages/Activate";
import AccountActivated from "./pages/AccountActivated";
import ActivationFail from "./pages/ActivationFail";
import Settings from "./pages/Settings";
import Countries from "./pages/Countries";
import ForgotPassword from "./pages/ForgotPassword";
import Country from "./pages/Country";
import LinkSent from "./pages/LinkSent";
import DeleteAccount from "./pages/DeleteAccount";
import ChangePassword from "./pages/ChangePassword";
import Admin from "./pages/Admin";
import AddCountry from "./pages/AddCountry";
import Borrowers from "./pages/Borrowers";
import UserSettings from "./pages/UserSettings";
import Claiming from "./pages/Claiming";
import ShowSettings from "./pages/ShowSettings";
import GeneralSettings from "./pages/GeneralSettings";
import SongSubmission from "./pages/SongSubmission";
import CountriesSettings from "./pages/CountriesSettings";
import SongAcceptance from "./pages/SongAcceptance";
import Messages from "./pages/Messages";
import AllocationDraw from "./pages/AllocationDraw";
import Voting from "./pages/Voting";
import ManageBackupJury from "./pages/ManageBackupJury";
import ManageTelevotingResults from "./pages/ManageTelevotingResults";
import ManageQualification from "./pages/ManageQualification";
import QuestionOfTheDayQueue from "./pages/QuestionOfTheDayQueue";
import SongPage from "./pages/SongPage";
import Songs from "./pages/Songs";
import EditionsPage from "./pages/EditionsPage";
import Edition from "./pages/Edition";
import ManageJuryVotes from "./pages/ManageJuryVotes";
import ResultsSnippet from "./pages/ResultsSnippet";
import NonSubmitters from "./pages/NonSubmitters";
import Warnings from "./pages/Warnings";
import UserPage from "./pages/UserWebpage";
import Stats from "./pages/Stats";
import Users from "./pages/Users";
import Main from "./pages/Main";


import Footer from "./components/Footer";
import Header from "./components/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import MainSelector from "./pageElements/MainSelector";
import GlobalContextProvider from "./components/GlobalContextProvider";
import MaintenanceMode from "./components/MaintenanceMode";
import ConnectionChecker from "./components/ConnectionChecker";

import "./styles/index.css";
import "./styles/header.css";
import "./styles/footer.css";
import "./styles/form.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then(function (registration) {
      console.log("Service Worker Registered with scope:", registration.scope);
    })
    .catch(function (error) {
      console.log("Service Worker registration failed:", error);
    });
}

root.render(
  <GlobalContextProvider>
    <BrowserRouter>
      <ConnectionChecker>
        <MaintenanceMode>
          <Header />
          <MainSelector>
            <Routes>
              <Route exact path="/" element={<Navigate to="/main" />} />
              <Route path="/main" element={<Main />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/login-required" element={<LoginRequired />} />
              <Route path="/page-not-found" element={<PageNotFound />} />
              <Route path="/borrowers" element={<Borrowers />} />
              <Route path="/connection-failed" element={<ConnectionFailed />} />
              <Route
                path="/internal-server-error"
                element={<InternalServerError />}
              />
              <Route path="/account_created" element={<AccountCreated />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/account-created" element={<AccountActivated />} />
              <Route path="/activation-fail" element={<ActivationFail />} />
              <Route path="/countries" element={<Countries />} />
              <Route path="/statistics" element={<Stats />} />
              <Route
                path="/claiming"
                element={
                  <ProtectedRoute redirectToElement={<LoginRequired />}>
                    <Claiming />
                  </ProtectedRoute>
                }
              />
              <Route path="/link-sent" element={<LinkSent />} />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner", "admin"]}
                  >
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/add-country"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <AddCountry />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/user-settings"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <UserSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/shows-settings"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <ShowSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/general-settings"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <GeneralSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/countries-settings"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <CountriesSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/allocation-draw"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <AllocationDraw />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/song-acceptance"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner", "admin"]}
                  >
                    <SongAcceptance />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/question-of-the-day-queue"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner", "admin"]}
                  >
                    <QuestionOfTheDayQueue />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/manage-backup-votes"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <ManageBackupJury />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/manage-televoting-points"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <ManageTelevotingResults />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/manage-qualification"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <ManageQualification />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/manage-jury-votes"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <ManageJuryVotes />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/results-snippet"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <ResultsSnippet />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/non-submitters"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <NonSubmitters />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/warnings"
                element={
                  <ProtectedRoute
                    redirectToElement={<Unauthorized />}
                    allowedRoles={["owner"]}
                  >
                    <Warnings />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/song-submission"
                element={
                  <ProtectedRoute redirectToElement={<LoginRequired />}>
                    <SongSubmission />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/voting"
                element={
                  <ProtectedRoute redirectToElement={<LoginRequired />}>
                    <Voting />
                  </ProtectedRoute>
                }
              />
              <Route path="/countries/:country" element={<Country />} />
              <Route path="/users/:user" element={<UserPage />} />
              <Route path="/users" element={<Users />} />

              <Route path="/songs" element={<Songs />} />
              <Route path="/songs/:song" element={<SongPage />} />
              <Route path="/activate/:code" element={<Activate />} />

              <Route path="/editions" element={<EditionsPage />} />
              <Route path="/editions/:edition" element={<Edition />} />

              <Route
                path="/change-password/:change_code"
                element={<ChangePassword />}
              />
              <Route
                path="/delete-account/:delete_code"
                element={<DeleteAccount />}
              />
              <Route
                path="/internal-server-error"
                element={<InternalServerError />}
              />
              <Route
                path="/register"
                element={
                  <ProtectedRoute
                    redirectToElement={<Main />}
                    allowedRoles={["guest"]}
                  >
                    <Register />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <ProtectedRoute
                    redirectToElement={<Main />}
                    allowedRoles={["guest"]}
                  >
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/messages"
                element={
                  <ProtectedRoute redirectToElement={<LoginRequired />}>
                    <Messages />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </MainSelector>
          <Footer />
        </MaintenanceMode>
      </ConnectionChecker>
    </BrowserRouter>
  </GlobalContextProvider>
);
