import React, { useState, useEffect, useRef } from "react";
import "../styles/choiceFields.css";

function ChoiceField({
  children,
  nameColumn = "name",
  keyColumn = "key",
  childProp = "image",
  passToChildKey = "image",
  passingFunction = (item) => {
    console.log(item);
  },
  data = [],
  value = null
}) {
  const [choice, setChoice] = useState(data[0]);
  const [isTextActive, setTextStatus] = useState(false);
  const containerRef = useRef(null);
  const handleClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setTextStatus(false);
    }
  };

  useEffect(() => {
    // Dodawanie event listenera do obiektu window
    window.addEventListener("click", handleClick);
    // Usuwanie event listenera, gdy komponent zostanie odmontowany
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []); // Pusty array ja

  useEffect(()=>{
    if(value !== null){
      setTextStatus(false);
      setChoice(value);
    }
  }, [value])

  return (
    <div className="searchChoiceContainer input_margin" ref={containerRef}>
      <div className="choiceItem chosenItem" key={choice[keyColumn]}>
        {React.Children.count(children) !== 0
          ? React.cloneElement(React.Children.toArray(children)[0], {
              [childProp]: choice[passToChildKey],
            })
          : null}
        <div
          className="choiceItemText"
          style={
            React.Children.count(children) !== 0 ? { marginLeft: "10px" } : {}
          }
          onClick={() => {
            setTextStatus((prevStatus) => !prevStatus);
          }}
        >
          {choice[nameColumn]}
        </div>
      </div>
      <>
        {isTextActive && (
          <div className="choiceItemsContainer">
            {data.map((element) => {
              return (
                <div
                  className="choiceItem"
                  onClick={() => {
                    setChoice(element);
                    passingFunction(element);
                    setTextStatus(false);
                  }}
                  key={element[keyColumn]}
                >
                  {React.Children.count(children) !== 0
                    ? React.cloneElement(React.Children.toArray(children)[0], {
                        [childProp]: element[passToChildKey],
                      })
                    : null}
                  <div
                    className="choiceItemText"
                    style={
                      React.Children.count(children) !== 0
                        ? { marginLeft: "10px" }
                        : {}
                    }
                  >
                    {element[nameColumn]}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    </div>
  );
}

export default ChoiceField;
