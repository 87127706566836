import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../scripts/api";
import Flag from "../components/Flag";
import GlobalContext from "../components/GlobalContext";


function NonSubmitters() {
  const [nonSubmitters, setNonSubmitters] = useState([]);
  const {setLoading} = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api(process.env.REACT_APP_API_URL + "get-non-submitters").then(
      (response) => {
        setLoading(false);
        if (response.positive) {
          setNonSubmitters(response.responseData);
        } else {
          navigate("/internal-server-error");
        }
      }
    );
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">NON SUBMITTERS</p>
          </section>
          <section className="defaultSection">
            {nonSubmitters.map((country) => {
              return <div>
                <Flag image={country.country_id} />
              </div>;
            })}
          </section>
        </section>
        <section className="defaultSection">
          <button onClick={()=>{
            setLoading(true);
            api(process.env.REACT_APP_API_URL + "withdraw-non-submitters").then(
              (response) => {
                setLoading(false);
                if (response.positive) {
                  alert("Done!");
                } else {
                  alert("Error!");
                }
              }
            );
          }}
          className="button1"
          >
            Withdraw and Warn
          </button>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default NonSubmitters;
