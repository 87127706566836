import React, { useContext } from "react";
import GlobalContext from "../components/GlobalContext";
import { useState, useEffect } from "react";
import Tabs from "../components/Tabs";
import CountryClaiming from "../pageElements/CountryClaiming";
import RandomClaiming from "../pageElements/RandomClaiming";
import ComponentsList from "../components/ComponentsList";
import SearchChoice from "../components/SearchChoice";
import ProfilePicture from "../components/ProfilePicture";
import { api } from "../scripts/api";
import Flag from "../components/Flag";
import { Link, json } from "react-router-dom";
import io from "socket.io-client";

function Claiming() {
  const [claimingStatus, setClaimingStatus] = useState({
    status: 0,
    open: false,
  });
  const { countries, setLoading } = useContext(GlobalContext);
  const [claimingOption, setClaimingOption] = useState({
    type: "chosen",
    country1: null,
    country1b: false,
    country2: null,
    country2b: false,
    country3: null,
    country3b: false,
    country4: null,
    country4b: false,
    country5: null,
    country5b: false,
    prefered: 0,
    random: false,
    users: [],
  });
  const [users, setUsers] = useState([]);
  const [claimedCountry, setClaimedCountry] = useState({ country_id: null });
  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-users",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      if (response.positive) {
        setUsers(response.responseData);
      }
    });
    api(process.env.REACT_APP_API_URL + "get-claimed-country").then(
      (response) => {
        if (response.positive) {
          if (response.responseData.found) {
            setClaimedCountry(response.responseData.data);
          } else {
            setClaimedCountry({});
          }
        }
      }
    );
    api(process.env.REACT_APP_API_URL + "get-claiming-status").then(
      (response) => {
        setLoading(false);
        if (response.positive) {
          if (response.responseData) {
            console.log(response.responseData);
            setClaimingStatus(response.responseData);
          } else {
            setClaimingStatus({ status: 0 });
          }
        }
      }
    );
  }, []);
  useEffect(() => {
    console.log(claimingOption);
  }, [claimingOption]);

  useEffect(() => {
    /*
  const socket = io(':8765', {
    path: '/api/socket.io'
  });
    if (claimingStatus.preclaiming) {
      socket.on("preclaiming_wait", (message) => {
        setUnitlClaiming(message);
        console.log(message)
      });
      socket.on("preclaiming_open", () => {
        setClaimingStatus({open: true})
      });
      return () => {
        socket.off("preclaiming_wait");
        socket.off("preclaiming_wait");
      };
    } else {
      socket.on("claiming_wait", (message) => {
        setUnitlClaiming(message);
      });
      socket.on("claiming_open", () => {
        setClaimingStatus({open: true})
      });
      return () => {
        socket.off("claiming_wait");
        socket.off("claiming_open");
      };
    }
    */
    

    const socket = new WebSocket("wss://cincin.net.pl:8766");

    socket.onopen = () => {
      console.log("Connected to server");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Message received:", data);
    
      if (data.type === "claiming_wait") {
        if (!claimingStatus.preclaiming) {
          setUnitlClaiming(data.data);
          console.log(data.type);
        }
      } else if (data.type === "preclaiming_wait") {
        if (claimingStatus.preclaiming) {
          setUnitlClaiming(data.data);
          console.log(data.type);
        }
      } else if (data.type === "claiming_open") {
        if (!claimingStatus.preclaiming) {
          setClaimingStatus({ open: true });
          console.log(data.type);
        }
      } else if (data.type === "preclaiming_open") {
        if (claimingStatus.preclaiming) {
          setClaimingStatus({ open: true });
          console.log(data.type);
        }
      }
    };
    
    socket.onclose = () => {
      console.log("Disconnected from server");
    };

    // Cleanup on unmount
    return () => {
      socket.close();
    };
    
  }, [claimingStatus.preclaiming]);

  const [untilClaiming, setUnitlClaiming] = useState({});

  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {claimingStatus.status === 0 ? (
          <>
            <section className="defaultSection">
              <p className="hugeHeadline">CLAIMING IS CLOSED</p>
            </section>
            <section className="defaultSection">
              Claiming either closed or hasn't opened yet. Follow updates on the server to know when the next claiming opens
            </section>
            <section className="defaultSection">
              <Link to="/main" className="button1">
                Main page
              </Link>
            </section>
          </>
        ) : (
          <>
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">CLAIMING</p>
              </section>
              {!claimingStatus.open && (
                <section className="defaultSection">
                  Claiming opens in:
                  {claimingStatus !== "0" && (
                    <div className="headInfo">
                      {untilClaiming.days} days {untilClaiming.hours} hours{" "}
                      {untilClaiming.minutes} minutes {untilClaiming.seconds}{" "}
                      seconds
                    </div>
                  )}
                </section>
              )}
              <div className="regularText">
                Claim a country to take part in {process.env.REACT_CONTEST_FULL || ""}. If
                you already claimed you can change here your country as well.
              </div>
              {claimedCountry.country_id && (
                <section className="defaultSection claimedCountry">
                  <div className="headInfo">Your current country:</div>
                  <Link
                    to={"/countries/" + claimedCountry.country_database_name}
                  >
                    <Flag image={claimedCountry.country_id} />
                    {claimedCountry.country_name}
                  </Link>
                </section>
              )}
            </section>
            <section className="defaultSection">
              <Tabs
                country={countries}
                passingFunction={(item) => {
                  setClaimingOption({
                    type: "chosen",
                    country1: null,
                    country1b: false,
                    country2: null,
                    country2b: false,
                    country3: null,
                    country3b: false,
                    country4: null,
                    country4b: false,
                    country5: null,
                    country5b: false,
                    prefered: 0,
                    random: false,
                    users: [],
                  });
                  setClaimingOption((prevState) => ({
                    ...prevState,
                    type: item,
                  }));
                }}
                data={[
                  {
                    key: "chosen",
                    name: "Choose country",
                    element: (
                      <CountryClaiming
                        passingFunction={(item) => {
                          setClaimingOption(item);
                        }}
                      />
                    ),
                  },
                  {
                    key: "random",
                    name: "Pick random",
                    element: (
                      <RandomClaiming
                        passingFunction={(item) => {
                          setClaimingOption(item);
                        }}
                      />
                    ),
                  },
                ]}
              />
            </section>
            <section className="defaultSection">
              <section className="defaultSection">
                <div className="headInfo">Add teammates:</div>

                <div className="regularText">
                  Add people that will take part with you
                </div>
              </section>
              <ComponentsList
                passingFunction={(item) => {
                  setClaimingOption((prevState) => ({
                    ...prevState,
                    users: item,
                  }));
                }}
              >
                <SearchChoice
                  nameColumn="username"
                  keyColumn="guid"
                  passToChildKey="profile_picture"
                  data={users}
                >
                  <ProfilePicture />
                </SearchChoice>
              </ComponentsList>
            </section>
            <section className="defaultSection">
              {claimingStatus.open ? (
                <button
                  className="button1"
                  onClick={() => {
                    setLoading(true);
                    api(
                      process.env.REACT_APP_API_URL + "claim",
                      null,
                      claimingOption,
                      null,
                      "POST",
                      120000
                    ).then((response) => {
                      setLoading(false);
                      if (response.positive) {
                        setClaimedCountry(response.responseData.country_data);
                      }
                    });
                  }}
                >
                  <b style={{ fontSize: "50px" }}>CLAIM</b>
                </button>
              ) : (
                <>
                  Claiming button will appear here when claiming will start.
                  Claiming starts in:
                  <div className="headInfo">
                    {untilClaiming.days} days {untilClaiming.hours} hours{" "}
                    {untilClaiming.minutes} minutes {untilClaiming.seconds}{" "}
                    seconds
                  </div>
                </>
              )}
            </section>
          </>
        )}
      </form>
    </div>
  );
}

export default Claiming;
