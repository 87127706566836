import Tabs from "../components/Tabs";
import AllocationDrawComponent from "../pageElements/AllocationDrawComponent";

function AllocationDraw() {
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <div className="headInfo">Allocation draw</div>
        </section>
        <section className="defaultSection">
          <Tabs
            data={[
              {
                key: "heat",
                name: "Heats",
                element: (
                    <AllocationDrawComponent type={1}/>
                ),
              },
              {
                key: "semifinals",
                name: "Semi Finals",
                element: (
                    <AllocationDrawComponent  type={2}/>
                ),
              },
              {
                key: "grandfinal",
                name: "Grand Final",
                element: (
                    <AllocationDrawComponent  type={3}/>
                ),
              },
            ]}
          />
        </section>
      </form>
    </div>
  );
}

export default AllocationDraw;
