import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Flag from "../components/Flag";
import Song from "../pageElements/Song";
import { api } from "../scripts/api";
import { positionNumber } from "../scripts/positionNumber";
import GlobalContext from "../components/GlobalContext";

function Country() {
  const [countryInfo, setCountryInfo] = useState({});
  const params = useParams();
  const country = params.country;
  const navigate = useNavigate();
  const {setLoading} = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "countries-webpages/" + country,
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        setCountryInfo(response.responseData);
      } else {
        if (response.responseData.data.type === "not_found") {
          navigate("/page-not-found");
        } else {
          navigate("/internal-server-error");
        }
      }
    });
  }, [country, navigate, setLoading]);
  return (
    <div className="columnContainer">
      <div className="infoColumn">
        <div className="countryAdditionalInfo">
          <section className="defaultSection">
            <p className="countryLabelInfo">
              <Flag image={countryInfo.id} />
              {countryInfo.name}
            </p>
          </section>
          {countryInfo.debut ? (
            <>
              <div className="countryInfoLeft">
                Debut: Edition {countryInfo.debut}
              </div>
              <div className="countryInfoLeft">
                Most recent participation: Edition {countryInfo.latest_entry}
              </div>
              <div className="countryInfoLeft">
                Number of participations: {countryInfo.number_of_participations}
              </div>
              <div className="countryInfoLeft">🥇: {countryInfo.wins}</div>
              <div className="countryInfoLeft">
                🥈: {countryInfo.second_places}
              </div>
              <div className="countryInfoLeft">
                🥉: {countryInfo.third_places}
              </div>
            </>
          ) : (
            <div className="countryInfoLeft">Country didn't debut yet.</div>
          )}
        </div>
      </div>
      <div className="defaultFormContainer">
        <div className="defaultForm widePage">
          <div
            className="countryPhoto"
            style={{
              backgroundImage: `url("/assets/images/countries_photos/${countryInfo.id}.png")`,
            }}
          >
            <p className="hugeHeadline">
              <Flag image={countryInfo.id} />
              {countryInfo.name}
            </p>
            <div className="countryPhotoOverlay"></div>
          </div>
          <section className="defaultSection">
            {countryInfo.debut ? (
              <>
                {countryInfo.participated && (
                  <>
                    <div className="headInfo biggerInfo">Best result:</div>
                    <div className="headInfo">
                      {positionNumber(countryInfo.best_entry.placement)} in{" "}
                      {countryInfo.best_entry.show_type === 1
                        ? "Heat"
                        : countryInfo.best_entry.show_type === 2
                        ? "Semi Final"
                        : countryInfo.best_entry.show_type === 3
                        ? "the Grand Final"
                        : null}{" "}
                      {countryInfo.best_entry.show_number !== 0 &&
                        countryInfo.best_entry.show_number}{" "}
                      of edition {countryInfo.best_entry.edition}
                    </div>
                    <div className="defaultCenteredContainer">
                      <Song songID={countryInfo.best_entry.id} />
                    </div>
                    <section className="defaultSection marginsSection">
                      <div className="headInfo">
                        Country results statistics:
                      </div>
                      <div className="defaultCenteredContainer">
                        <table className="statsTable">
                          <tr>
                            <td></td>
                            <td>
                              <b>Heats</b>
                            </td>
                            <td>
                              <b>Semi Finals</b>
                            </td>
                            <td>
                              <b>Grand Finals</b>
                            </td>
                            <td>
                              <b>General</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Average placement</td>
                            <td>
                              {countryInfo.heats_stats.place
                                ? parseFloat(countryInfo.heats_stats.place)
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.semifinals_stats.place
                                ? parseFloat(countryInfo.semifinals_stats.place)
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.place
                                ? parseFloat(countryInfo.final_stats.place)
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.general_stats.place
                                ? parseFloat(countryInfo.general_stats.place)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Average televoting placement</td>
                            <td>-</td>
                            <td>
                              {countryInfo.semifinals_stats.televoting_placement
                                ? parseFloat(
                                    countryInfo.semifinals_stats
                                      .televoting_placement
                                  )
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.televoting_placement
                                ? parseFloat(
                                    countryInfo.final_stats.televoting_placement
                                  )
                                : "-"}
                            </td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>Average jury voting placement</td>
                            <td>-</td>
                            <td>
                              {countryInfo.semifinals_stats.jury_place
                                ? parseFloat(
                                    countryInfo.semifinals_stats.jury_place
                                  )
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.jury_place
                                ? parseFloat(countryInfo.final_stats.jury_place)
                                : "-"}
                            </td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>Qualification rate</td>
                            <td>
                              {countryInfo.heats_stats.qualification
                                ? parseFloat(
                                    countryInfo.heats_stats.qualification
                                  ) *
                                    100 +
                                  "%"
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.semifinals_stats.qualification
                                ? parseFloat(
                                    countryInfo.semifinals_stats.qualification
                                  ) *
                                    100 +
                                  "%"
                                : "-"}
                            </td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>Total ammount of points</td>
                            <td>
                              {countryInfo.heats_stats.total_points
                                ? countryInfo.heats_stats.total_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.semifinals_stats.total_points
                                ? countryInfo.semifinals_stats.total_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.total_points
                                ? countryInfo.final_stats.total_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.general_stats.total_points
                                ? countryInfo.general_stats.total_points
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Total ammount of jury points</td>
                            <td>-</td>
                            <td>
                              {countryInfo.semifinals_stats.total_jury_points
                                ? countryInfo.semifinals_stats.total_jury_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.total_jury_points
                                ? countryInfo.final_stats.total_jury_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.general_stats.total_jury_points
                                ? countryInfo.general_stats.total_jury_points
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Total ammount of televoting points</td>
                            <td>-</td>
                            <td>
                              {countryInfo.semifinals_stats
                                .total_televoting_points
                                ? countryInfo.semifinals_stats
                                    .total_televoting_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.total_televoting_points
                                ? countryInfo.final_stats
                                    .total_televoting_points
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.general_stats.total_televoting_points
                                ? countryInfo.general_stats
                                    .total_televoting_points
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>12 points received</td>
                            <td>
                              {countryInfo.heats_stats.number_of_12
                                ? countryInfo.heats_stats.number_of_12
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.semifinals_stats.number_of_12
                                ? countryInfo.semifinals_stats.number_of_12
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.final_stats.number_of_12
                                ? countryInfo.final_stats.number_of_12
                                : "-"}
                            </td>
                            <td>
                              {countryInfo.general_stats.number_of_12
                                ? countryInfo.general_stats.number_of_12
                                : "-"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </section>
                    <section className="defaultSection marginsSection">
                      <div className="headInfo">
                        All results of {countryInfo.name}:
                      </div>
                      <div className="defaultCenteredContainer">
                        <div style={{ overflowX: "auto" }}>
                          <table className="statsTable">
                            <tr>
                              <td>
                                <b>Edition</b>
                              </td>
                              <td>
                                <b>Show</b>
                              </td>
                              <td>
                                <b>Running order</b>
                              </td>
                              <td>
                                <b>Number of 12 points</b>
                              </td>
                              <td>
                                <b>Qualification</b>
                              </td>
                              <td>
                                <b>Jury points</b>
                              </td>
                              <td>
                                <b>Jury placement</b>
                              </td>
                              <td>
                                <b>Televoting points</b>
                              </td>
                              <td>
                                <b>Televoting placement</b>
                              </td>
                              <td>
                                <b>Total Points</b>
                              </td>
                              <td>
                                <b>Placement</b>
                              </td>
                            </tr>
                            {countryInfo.results_history.map((element) => {
                              return (
                                <tr>
                                  <td>{element.edition}</td>
                                  <td className="noWrap">
                                    {element.show_type === 1
                                      ? "Heat"
                                      : element.show_type === 2
                                      ? "Semi Final"
                                      : element.show_type === 3
                                      ? "the Grand Final"
                                      : element.show_type === 4
                                      ? "General result"
                                      : null}{" "}
                                    {element.show_number !== 0 &&
                                      element.show_type !== 4 &&
                                      element.show_number}
                                  </td>
                                  <td>
                                    {element.running_order
                                      ? element.running_order
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.number_of_12s !== null
                                      ? element.number_of_12s
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.qualification === 1
                                      ? "Q"
                                      : element.qualification === 2
                                      ? "NQ"
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.show_type === 1
                                      ? "-"
                                      : element.jury_results !== null
                                      ? element.jury_results
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.show_type === 1
                                      ? "-"
                                      : element.jury_placement !== null
                                      ? element.jury_placement
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.televoting_points !== null
                                      ? element.televoting_points
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.televoting_placement !== null
                                      ? element.televoting_placement
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.full_results !== null
                                      ? element.full_results
                                      : "-"}
                                  </td>
                                  <td>
                                    {element.placement !== null
                                      ? element.placement
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </section>
                    <section className="defaultSection marginsSection">
                      <div className="headInfo">Country voting statistics:</div>
                      <div className="flexColumns">
                        <div className="columnDiv">
                          <p>
                            What countries gave the most points to{" "}
                            {countryInfo.name}:
                          </p>
                          <table className="defaultTable">
                            {countryInfo.getting_points_from.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td className="placementCell paddedCell">
                                      {index + 1}
                                    </td>
                                    <td className="defaultTableCell">
                                      <p className="defaultCountryWithFlag">
                                        {item.id === 999 ? (
                                          // eslint-disable-next-line
                                          <a>
                                            <Flag image={item.id} />
                                            Rest of the world jury
                                          </a>
                                        ) : (
                                          <Link
                                            to={
                                              "/countries/" + item.database_name
                                            }
                                          >
                                            <Flag image={item.id} />
                                            {item.name}
                                          </Link>
                                        )}
                                      </p>
                                    </td>
                                    <td className="defaultTableCell paddedCell">
                                      {item.points}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </table>
                        </div>
                        <div className="columnDiv">
                          <p>
                            What countries got the most points from{" "}
                            {countryInfo.name}:
                          </p>
                          <table className="defaultTable">
                            {countryInfo.giving_points_to.map((item, index) => {
                              return (
                                <tr>
                                  <td className="placementCell paddedCell">
                                    {index + 1}
                                  </td>
                                  <td className="defaultTableCell">
                                    <p className="defaultCountryWithFlag">
                                      <Link
                                        to={"/countries/" + item.database_name}
                                      >
                                        <Flag image={item.id} />
                                        {item.name}
                                      </Link>
                                    </p>
                                  </td>
                                  <td className="defaultTableCell paddedCell">
                                    {item.points}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </section>
                    <section className="defaultSection marginsSection">
                      <div className="headInfo">
                        {countryInfo.name} full voting history:
                      </div>
                      <div className="defaultCenteredContainer">
                        <div style={{ overflowX: "auto" }}>
                          <table className="statsTable">
                            <tr>
                              <td>Edition</td>
                              <td>Show</td>
                              <td>1</td>
                              <td>2</td>
                              <td>3</td>
                              <td>4</td>
                              <td>5</td>
                              <td>6</td>
                              <td>7</td>
                              <td>8</td>
                              <td>10</td>
                              <td>12</td>
                            </tr>
                            {countryInfo.voting_history.map((item) => {
                              return (
                                <tr
                                  key={
                                    countryInfo.name +
                                    "_" +
                                    item.jury_voting_edition +
                                    "_" +
                                    item.jury_voting_show_type +
                                    "_" +
                                    item.jury_voting_show_number
                                  }
                                >
                                  <td>{item.jury_voting_edition}</td>
                                  <td style={{ whiteSpace: "noWrap" }}>
                                    {item.jury_voting_show_type === 1
                                      ? "Heat"
                                      : item.jury_voting_show_type === 2
                                      ? "Semi Final"
                                      : item.jury_voting_show_type === 3
                                      ? "the Grand Final"
                                      : null}{" "}
                                    {item.jury_voting_show_number !== 0 &&
                                      item.jury_voting_show_number}
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p1_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p1} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p2_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p2} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p3_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p3} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p4_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p4} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p5_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p5} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p6_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p6} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p7_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p7} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={"/songs/" + item.p8_database_name + "-" + item.jury_voting_edition}
                                    >
                                      <Flag image={item.p8} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={
                                        "/songs/" + item.p10_database_name + "-" + item.jury_voting_edition
                                      }
                                    >
                                      <Flag image={item.p10} />
                                    </Link>
                                  </td>
                                  <td className="countryLinkFlag">
                                    <Link
                                      to={
                                        "/songs/" + item.p12_database_name + "-" + item.jury_voting_edition
                                      }
                                    >
                                      <Flag image={item.p12} />
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </section>
                  </>
                )}
                <section className="defaultSection">
                  <div className="headInfo">
                    All songs sent by {countryInfo.name}:
                  </div>
                  <div className="photoLibraryContainer">
                    {countryInfo.participations.map((item) => {
                      return (
                        <div key={item.song_id} className="margin_container">
                          <Song songID={item.song_id} />
                        </div>
                      );
                    })}
                  </div>
                </section>
              </>
            ) : (
              <p className="hugeHeadline">
                {countryInfo.name} has not debuted yet
              </p>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default Country;
