import React, { useContext, useEffect } from "react";
import GlobalContext from "../components/GlobalContext";
import { useState } from "react";
import ChoiceField from "../components/ChoiceField";

function CountryClaiming({
  passingFunction = (item) => alert(item.country_name),
}) {
  return (
    <section className="defaultSection">
      <section className="defaultSection">
        <div className="headInfo">Pick random country:</div>
        <div className="regularText">
          Your country will be chosen randomly
        </div>
      </section>

      <section className="defaultSection">
            Prefered continent:
            <div className="defaultCenteredContainer">
              <ChoiceField
                data={[
                  {
                    continent_id: "0",
                    continent_name: "Random continent",
                  },
                  {
                    continent_id: "1",
                    continent_name: "Europe",
                  },
                  {
                    continent_id: "2",
                    continent_name: "Asia",
                  },
                  {
                    continent_id: "3",
                    continent_name: "Africa",
                  },
                  {
                    continent_id: "4",
                    continent_name: "North America",
                  },
                  {
                    continent_id: "5",
                    continent_name: "South America",
                  },
                  {
                    continent_id: "6",
                    continent_name: "Oceania",
                  },
                  {
                    continent_id: "7",
                    continent_name: "Antarctica",
                  },
                ]}
                nameColumn="continent_name"
                keyColumn="continent_id"
                passingFunction={(item) => {
                  passingFunction((prevState) => ({
                    ...prevState,
                    prefered: item.continent_id,
                  }));
                }}
              ></ChoiceField>
            </div>
          </section>



    
      </section>
  );
}

export default CountryClaiming;
