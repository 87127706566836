import { Link } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";

import axios from "axios";

function Acitvate() {
  const {setLoading} = useContext(GlobalContext);
    const { code } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)
        api(
            process.env.REACT_APP_API_URL + "activate-account",
            source.token,
            {activationID : code}
          ).then((response) => {
            setLoading(false)
            if (response.positive) {
                navigate('/account-created');
            } else {
                navigate('/activation-fail');
            }
          });
    }, [code, navigate, setLoading])
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">ACCOUNT ACTIVATION</p>
          </section>
          <div className="regularText">
            You should be automatically redirected to other website. If you are not you can click link below.
          </div>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Acitvate;
