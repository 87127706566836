import React, { useContext, useState } from "react";
import GlobalContext from "../components/GlobalContext";
import SearchChoice from "../components/SearchChoice";
import { api } from "../scripts/api";
import Flag from "../components/Flag";

function CountriesSettings() {
  const { countries } = useContext(GlobalContext);
  const [country, setCountry] = useState(null);
  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">COUNTRIES SETTINGS</p>
          </section>
          <div className="regularText">Tools for owner of the contest</div>
        </section>
        <section className="defaultSection">
          <div className="defaultCenteredContainer">
            <SearchChoice
              data={countries}
              nameColumn="country_name"
              keyColumn="country_id"
              passingFunction={(item) => {
                setCountry(item);
              }}
              removingFunction={() => {
                setCountry(null);
              }}
              passToChildKey="country_id"
            >
              <Flag image="" />
            </SearchChoice>
          </div>
        </section>
        {country && (
          <>
            <section className="defaultSection">
              <section className="defaultSection">
                Country ID: {country.country_id}
              </section>

              <button
                className="button1"
                onClick={() => {
                  api(process.env.REACT_APP_API_URL + "remove-song", null, {
                    country: country.country_id,
                  }).then((response) => {
                    if (response.positive) {
                      alert("Done!");
                    } else {
                      alert("Error");
                    }
                  });
                }}
              >
                Remove song
              </button>
            </section>
            <section className="defaultSection">
              <button
                className="button1"
                onClick={() => {
                  api(process.env.REACT_APP_API_URL + "set-aq", null, {
                    country: country.country_id,
                  }).then((response) => {
                    if (response.positive) {
                      alert("Done!");
                    } else {
                      alert("Error");
                    }
                  });
                }}
              >
                Set as AQ
              </button>
            </section>
            <section className="defaultSection">
              <button
                className="button1"
                onClick={() => {
                  api(process.env.REACT_APP_API_URL + "remove-aq", null, {
                    country: country.country_id,
                  }).then((response) => {
                    if (response.positive) {
                      alert("Done!");
                    } else {
                      alert("Error");
                    }
                  });
                }}
              >
                Remove from AQs
              </button>
              <section className="defaultSection">
                <button
                  className="button1"
                  onClick={() => {
                    api(
                      process.env.REACT_APP_API_URL + "mark-as-host-country",
                      null,
                      {
                        country: country.country_id,
                      }
                    ).then((response) => {
                      if (response.positive) {
                        alert("Done!");
                      } else {
                        alert("Error");
                      }
                    });
                  }}
                >
                  Mark as host country
                </button>
              </section>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default CountriesSettings;
