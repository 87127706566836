import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import GlobalContext from "../components/GlobalContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../scripts/api";
import { useNavigate } from "react-router-dom";
import { positionNumber } from "../scripts/positionNumber";
import Song from "../pageElements/Song";

function SongPage() {
  const [songInfo, setSongInfo] = useState(null);
  const { setLoading } = useContext(GlobalContext);
  const navigate = useNavigate();
  const params = useParams();
  const song = params.song;
  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-song-info/" + song,
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        console.log(response.responseData);
        setSongInfo(response.responseData);
      } else {
        if (response.responseData.data.type === "not_found") {
          navigate("/page-not-found");
        } else {
          navigate("/internal-server-error");
        }
      }
    });
  }, [song, navigate, setLoading]);

  return (
    <>
      {songInfo && (
        <div className="defaultFormContainer">
          <div
            className="defaultForm"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">
                  "{songInfo.song_info.song_name}" by{" "}
                  {songInfo.song_info.song_artist}
                </p>
              </section>
              <div className="headInfo">
                {songInfo.song_info.country_name}{" "}
                {songInfo.song_info.song_edition}
              </div>
              {songInfo.best_result && (
                <div className="headInfo">
                  {positionNumber(songInfo.best_result.placement)} in{" "}
                  {songInfo.best_result.max_show === 1
                    ? "Heat"
                    : songInfo.best_result.max_show === 2
                    ? "Semi Final"
                    : songInfo.best_result.max_show === 3
                    ? "the Grand Final"
                    : null}{" "}
                  {songInfo.best_result.show_number !== 0
                    ? songInfo.best_result.show_number
                    : null}{" "}
                  of edition {songInfo.best_result.edition}
                </div>
              )}
              <div className="defaultCenteredContainer">
                <Song songID={songInfo.song_info.song_id} />
              </div>
              <section className="defaultSection marginsSection">
                <div className="headInfo">
                  All results of "{songInfo.song_info.song_name}" by{" "}
                  {songInfo.song_info.song_artist} (
                  {songInfo.song_info.country_name}{" "}
                  {songInfo.song_info.song_edition})
                </div>
                <div className="defaultCenteredContainer">
                  <div style={{ overflowX: "auto" }}>
                    <table className="statsTable">
                      <tr>
                        <td>
                          <b>Show</b>
                        </td>
                        <td>
                          <b>Running order</b>
                        </td>
                        <td>
                          <b>Number of 12 points</b>
                        </td>
                        <td>
                          <b>Qualification</b>
                        </td>
                        <td>
                          <b>Jury points</b>
                        </td>
                        <td>
                          <b>Jury placement</b>
                        </td>
                        <td>
                          <b>Televoting points</b>
                        </td>
                        <td>
                          <b>Televoting placement</b>
                        </td>
                        <td>
                          <b>Total Points</b>
                        </td>
                        <td>
                          <b>Placement</b>
                        </td>
                      </tr>
                      {songInfo.all_results.map((element) => {
                        return (
                          <tr>
                            <td className="noWrap">
                              {element.show_type === 1
                                ? "Heat"
                                : element.show_type === 2
                                ? "Semi Final"
                                : element.show_type === 3
                                ? "the Grand Final"
                                : element.show_type === 4
                                ? "General result"
                                : null}{" "}
                              {element.show_number !== 0 &&
                                element.show_type !== 4 &&
                                element.show_number}
                            </td>
                            <td>
                              {element.running_order
                                ? element.running_order
                                : "-"}
                            </td>
                            <td>
                              {element.number_of_12s !== null
                                ? element.number_of_12s
                                : "-"}
                            </td>
                            <td>
                              {element.qualification === 1
                                ? "Q"
                                : element.qualification === 2
                                ? "NQ"
                                : "-"}
                            </td>
                            <td>
                              {element.show_type === 1
                                ? "-"
                                : element.jury_results !== null
                                ? element.jury_results
                                : "-"}
                            </td>
                            <td>
                              {element.show_type === 1
                                ? "-"
                                : element.jury_placement !== null
                                ? element.jury_placement
                                : "-"}
                            </td>
                            <td>
                              {element.televoting_points !== null
                                ? element.televoting_points
                                : "-"}
                            </td>
                            <td>
                              {element.televoting_placement !== null
                                ? element.televoting_placement
                                : "-"}
                            </td>
                            <td>
                              {element.full_results !== null
                                ? element.full_results
                                : "-"}
                            </td>
                            <td>
                              {element.placement !== null
                                ? element.placement
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                      {songInfo.best_result  && (
                        <tr>
                          <td>General Results</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>{songInfo.best_result.position }</td>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
              </section>
            </section>
            <section className="defaultSection">
              <Link className="button1 horizontalMargins" to={"/main"}>
                Main page
              </Link>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default SongPage;
