import { useEffect, useState } from "react";
import { api } from "../scripts/api";

function RecapDescription({ settings }) {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    api(process.env.REACT_APP_API_URL + "get-show-songs", null, settings).then(
      (response) => {
        if (response.positive) {
          setEntries(response.responseData);
          console.log(response.responseData);
        }
      }
    );
  }, [settings]);

  return (
    <section className="defaultSection">
      Join our discord server! https://discord.com/invite/ZjvGqPkdXY <br />
      Visit our official website! https://worldvision.cincin.net.pl
      <br />
      <br />
      All songs participating in{" "}
      {["Heat", "Semi Final", "the Grand Final"][settings.showType - 1]}{" "}
      {settings.showNumber !== "0" ? settings.showNumber : ""} of the edition{" "}
      {settings.edition} of {process.env.REACT_CONTEST_FULL || ""}:
      <br />
      {entries.map((element) => {
        return (
          <>
            {element.participation_running_order}) {element.country_name}{" "}
            {element.country_emoji} | {element.song_artist} -{" "}
            {element.song_name}
            <br />
          </>
        );
      })}
      <br />
      Hello! I'm CinCin, a passionate Eurovision enthusiast, and I'm
              thrilled to introduce the Worldvision Song Contest By You! This is
              a unique, virtual competition where you can unleash your musical
              creativity and represent any country you desire! It's a fantastic
              opportunity for Eurovision fans and music lovers worldwide to
              showcase their talents, celebrate diversity, and most importantly,
              enjoy an exhilarating musical experience.
              <br />
              <br />
              Whether you're a singer, a songwriter, or a music aficionado, the
              Worldvision Song Contest By You is the perfect stage for you. Dive
              into a world of music, discover new artists, and join a community
              that shares your passion for the Eurovision spirit.
              <br />
              <br />
              What's more, you can submit your own songs or even those generated
              by AI, making it feel just like the real Eurovision!
              <br />
              <br />
              Ready to embark on this musical journey? To participate, stay
              updated on the latest contest news, or engage in lively
              discussions, be sure to visit our official website
              https://worldvision.cincin.net.pl and join our vibrant Discord
              community at https://discord.com/invite/ZjvGqPkdXY. Let's make the
              Worldvision Song Contest By You an unforgettable musical
              celebration! 🎶🌍🎉
    </section>
  );
}

export default RecapDescription;
