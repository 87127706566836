import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";
import Paging from "../components/Paging";
import Song from "../pageElements/Song";

function Songs() {
  const [data, setData] = useState([]);
  const { setLoading } = useContext(GlobalContext);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-all-songs",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        setData(response.responseData);
      } else {
        navigate("/internal-server-error");
      }
    });
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm defaultWider"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">{(process.env.REACT_CONTEST || "").toUpperCase()} SONGS</p>
          </section>

          <section className="defaultSection">
            {data.map((element) => {
              return (
                <>
                  <section className="defaultSection">
                    {" "}
                    <div className="headInfo">
                      Edition {element.edition}
                    </div>{" "}
                    <EditionContainer allSongs={element.songs} />
                  </section>
                  <br />
                </>
              );
            })}
          </section>
        </section>
        <section className="defaultSection">
          <Link className="button1 horizontalMargins" to={"/main"}>
            Main page
          </Link>
        </section>
      </div>
    </div>
  );
}

function EditionContainer({ allSongs }) {
  const [displayedSongs, setDisplayedSongs] = useState([]);

  return (
    <section className="defaultSection">
      <section className="photoLibraryContainer">
        {displayedSongs.map((song) => {
          return (
            <div key={song.song_id} className="margin_container">
              <Song songID={song.song_id} />
            </div>
          );
        })}
      </section>

      <Paging
        elementsPerPage={3}
        passingFunction={(data) => {
          setDisplayedSongs(data);
        }}
        data={allSongs}
      />
    </section>
  );
}

export default Songs;
