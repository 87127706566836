import React, { useState } from "react";
import ComponentsList from "../components/ComponentsList";
import { api } from "../scripts/api";
import { useEffect } from "react";
import { useContext } from "react";
import GlobalContext from "../components/GlobalContext";

function QuestionOfTheDayQueue() {
  const { setLoading } = useContext(GlobalContext);
  const [televotingQueue, setTelevotingQueue] = useState([]);

  useEffect(() => {
    setLoading(true);
    api(process.env.REACT_APP_API_URL + "get-questions").then((response) => {
      setLoading(false);
      if (response.positive) {
        setTelevotingQueue(
          response.responseData.map((element, index) => ({
            key: index,
            object: {
              question_id: element.question_id,
              question: element.question,
            },
          }))
        );
      }
    });
  }, []);

  return (
    <div className="defaultFormContainer">
      <div
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">QUESTION OF THE DAY QUEUE</p>
          </section>
          <div className="regularText">
            Remember to click save button! Clicking put as 1st makes question
            the first on the line so it will be asked the next day. Before
            clicking click save as well.
          </div>
        </section>
        <section className="defaultSection">
          <ComponentsList
            defaultItems={televotingQueue}
            passingFunction={(item) => {
              setTelevotingQueue(item);
            }}
          >
            <QuestionOfTheDayItem />
          </ComponentsList>
        </section>
        <section className="defaultSection">
          <button className="button1" onClick={()=>{
                  setLoading(true);

                api(process.env.REACT_APP_API_URL + "save-questions", null, {data: televotingQueue}).then((response) => {
                  setLoading(false);
                  if (response.positive) {
                    alert("Saved!")
                  } else {
                    alert("Error!")
                  }
                  window.location.reload();
                });
          }}>Save questions</button>
        </section>
      </div>
    </div>
  );
}

function QuestionOfTheDayItem({ passingFunction, item }) {
  const { setLoading } = useContext(GlobalContext);
  return (
    <>
      <div className="defaultCenteredContainer">
        <input
          type="text"
          className="defaultInput"
          onChange={(e) => {
            passingFunction(e.target.value);
          }}
          value={item.question}
        />
        <button
          className="button1"
          onClick={() => {
            setLoading(true);
            api(
              process.env.REACT_APP_API_URL + "mark-as-first-question",
              null,
              { question_id: item.question_id }
            ).then((response) => {
              window.location.reload();
            });
          }}
        >
          Put as 1st
        </button>
      </div>
    </>
  );
}

export default QuestionOfTheDayQueue;
