import React, { useEffect, useState } from "react";
import SearchChoice from "../components/SearchChoice";
import GlobalContext from "../components/GlobalContext";
import { useContext } from "react";
import Flag from "../components/Flag";
import { useNavigate, Link } from "react-router-dom";
import { api } from "../scripts/api";
import ProfilePicture from "../components/ProfilePicture";

function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const { setLoading } = useContext(GlobalContext);
  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-users",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);

      if (response.positive) {
        setUsers(response.responseData);
      }
    });
  }, []);
  return (
    <div className="defaultFormContainer">
      <div className="defaultForm widePage">
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">{(process.env.REACT_CONTEST || "").toUpperCase()} USERS</p>
          </section>
          <div className="regularText">Check info about {process.env.REACT_CONTEST || ""} users:</div>
        </section>
        <section className="defaultSection">
          <div className="defaultInputContainer">
            Choose user:{" "}
            <SearchChoice
              nameColumn="username"
              keyColumn="guid"
              passToChildKey="profile_picture"
              data={users}
              passingFunction={(item) => {
                navigate("/users/" + item.username.toLowerCase());
              }}
            >
              <ProfilePicture />
            </SearchChoice>
          </div>
        </section>
        <section className="defaultSection">
          <div className="headInfo">All users list:</div>
          <section className="defaultSection">
            {users.map((user) => {
              return (
                <section>
                  <div className="userStatCell">
                    {" "}
                    <Link
                      to={"/users/" + user.username.toLowerCase()}
                      class="teleCountry"
                    >
                      <ProfilePicture image={user.profile_picture} />
                      {user.username}
                    </Link>
                  </div>
                </section>
              );
            })}
          </section>
        </section>
      </div>
    </div>
  );
}

export default Users;
