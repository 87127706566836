import React from 'react';

function FileUploader({ returnFunction, label, accept, maxSize, id = 'fileInput'}) {

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (maxSize && file.size > maxSize) {
        alert("File is too large!");
        return;
      }
      returnFunction(file)
    }
  }

  return (
    <>
      <input id={id} type="file" onChange={handleImageChange} style={{display: 'none'}} accept={accept} />
      <label htmlFor={id} className='button1'>
        {label}
      </label>
    </>
  );
}

export default FileUploader;
