import React, { useContext, useEffect } from "react";
import GlobalContext from "../components/GlobalContext";
import SearchChoice from "../components/SearchChoice";
import Flag from "../components/Flag";
import { useState } from "react";
import ChoiceField from "../components/ChoiceField";

function CountryClaiming({
  passingFunction = (item) => alert(item.country_name),
}) {


  const { countries, setLoading } = useContext(GlobalContext);
  const [random, setRandom] = useState(false);
  return (
    <section className="defaultSection">
      <section className="defaultSection">
        <div className="headInfo">Choose countries:</div>
        <div className="regularText">
          Fill in your claiming list. If your first choice is taken, you get
          second choice. If third is taken you get fourth etc
        </div>
      </section>
      <div className="defaultCenteredContainer countryClaimingChoice">
        <div className="editionCircle">1</div>
        <SearchChoice
          data={countries}
          nameColumn="country_name"
          keyColumn="country_id"
          passToChildKey="country_id"
          passingFunction={(item) => {
            passingFunction((prevState) => ({
              ...prevState,
              country1: item.country_id,
            }));
          }}
          removingFunction={() => {
            passingFunction((prevState) => ({
              ...prevState,
              country1: null,
            }));
          }}
        >
          <Flag image="" />
        </SearchChoice>
        <span className="spanField">
          <input
            type="checkbox"
            id="include_borrowers1"
            className="defaultCheckbox"
            onChange={(e) => {
              passingFunction((prevState) => ({
                ...prevState,
                country1b: e.target.checked,
              }));
            }}
          />
          <label htmlFor="include_borrowers1">Include borrowers</label>
        </span>
      </div>


      <div className="defaultCenteredContainer countryClaimingChoice">
        <div className="editionCircle">2</div>
        <SearchChoice
          data={countries}
          nameColumn="country_name"
          keyColumn="country_id"
          passToChildKey="country_id"
          passingFunction={(item) => {
            passingFunction((prevState) => ({
              ...prevState,
              country2: item.country_id,
            }));
          }}
          removingFunction={() => {
            passingFunction((prevState) => ({
              ...prevState,
              country2: null,
            }));
          }}
        >
          <Flag image="" />
        </SearchChoice>
        <span className="spanField">
          <input
            type="checkbox"
            id="include_borrowers2"
            className="defaultCheckbox"
            onChange={(e) => {
              passingFunction((prevState) => ({
                ...prevState,
                country2b: e.target.checked,
              }));
            }}
          />
          <label htmlFor="include_borrowers2">Include borrowers</label>
        </span>
      </div>





      <div className="defaultCenteredContainer countryClaimingChoice">
        <div className="editionCircle">3</div>
        <SearchChoice
          data={countries}
          nameColumn="country_name"
          keyColumn="country_id"
          passToChildKey="country_id"
          passingFunction={(item) => {
            passingFunction((prevState) => ({
              ...prevState,
              country3: item.country_id,
            }));
          }}
          removingFunction={() => {
            passingFunction((prevState) => ({
              ...prevState,
              country3: null,
            }));
          }}
        >
          <Flag image="" />
        </SearchChoice>
        <span className="spanField">
          <input
            type="checkbox"
            id="include_borrowers3"
            className="defaultCheckbox"
            onChange={(e) => {
              passingFunction((prevState) => ({
                ...prevState,
                country3b: e.target.checked,
              }));
            }}
          />
          <label htmlFor="include_borrowers3">Include borrowers</label>
        </span>
      </div>






      <div className="defaultCenteredContainer countryClaimingChoice">
        <div className="editionCircle">4</div>
        <SearchChoice
          data={countries}
          nameColumn="country_name"
          keyColumn="country_id"
          passToChildKey="country_id"
          passingFunction={(item) => {
            passingFunction((prevState) => ({
              ...prevState,
              country4: item.country_id,
            }));
          }}
          removingFunction={() => {
            passingFunction((prevState) => ({
              ...prevState,
              country4: null,
            }));
          }}
        >
          <Flag image="" />
        </SearchChoice>
        <span className="spanField">
          <input
            type="checkbox"
            id="include_borrowers4"
            className="defaultCheckbox"
            onChange={(e) => {
              passingFunction((prevState) => ({
                ...prevState,
                country4b: e.target.checked,
              }));
            }}
          />
          <label htmlFor="include_borrowers4">Include borrowers</label>
        </span>
      </div>





      <div className="defaultCenteredContainer countryClaimingChoice">
        <div className="editionCircle">5</div>
        <SearchChoice
          data={countries}
          nameColumn="country_name"
          keyColumn="country_id"
          passToChildKey="country_id"
          passingFunction={(item) => {
            passingFunction((prevState) => ({
              ...prevState,
              country5: item.country_id,
            }));
          }}
          removingFunction={() => {
            passingFunction((prevState) => ({
              ...prevState,
              country5: null,
            }));
          }}
        >
          <Flag image="" />
        </SearchChoice>
        <span className="spanField">
          <input
            type="checkbox"
            id="include_borrowers5"
            className="defaultCheckbox"
            onChange={(e) => {
              passingFunction((prevState) => ({
                ...prevState,
                country5b: e.target.checked,
              }));
            }}
          />
          <label htmlFor="include_borrowers5">Include borrowers</label>
        </span>
      </div>






      <section className="defaultSection">
        <div className="defaultCenteredContainer">
          <input
            type="checkbox"
            id="random_chosen"
            className="defaultCheckbox"
            onChange={(e) => {
              setRandom(e.target.checked);
              passingFunction((prevState) => ({
                ...prevState,
                random: true,
              }));
              
            }}
          />
          <label htmlFor="random_chosen">
            Claim random country if none of the chosen 5 is available
          </label>
        </div>
        {random && (
          <section className="defaultSection">
            Prefered continent:
            <div className="defaultCenteredContainer">
              <ChoiceField
                data={[
                  {
                    continent_id: "0",
                    continent_name: "Random continent",
                  },
                  {
                    continent_id: "1",
                    continent_name: "Europe",
                  },
                  {
                    continent_id: "2",
                    continent_name: "Asia",
                  },
                  {
                    continent_id: "3",
                    continent_name: "Africa",
                  },
                  {
                    continent_id: "4",
                    continent_name: "North America",
                  },
                  {
                    continent_id: "5",
                    continent_name: "South America",
                  },
                  {
                    continent_id: "6",
                    continent_name: "Oceania",
                  },
                  {
                    continent_id: "7",
                    continent_name: "Antarctica",
                  },
                ]}
                nameColumn="continent_name"
                keyColumn="continent_id"
                passingFunction={(item) => {
                  passingFunction((prevState) => ({
                    ...prevState,
                    prefered: item.continent_id,
                  }));
                }}
              />
            </div>
          </section>
        )}
      </section>
    </section>
  );
}

export default CountryClaiming;
