import { Route, Routes } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "./GlobalContext";
import ConnectionFailed from "../pages/ConnectionFailed";
import { api } from "../scripts/api";
import axios from "axios";

function ConnectionChecker({ children }) {
  const { setLoading } = useContext(GlobalContext);
  const [connected, setConnected] = useState(false)

  useEffect(()=>{
    setLoading(true)
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    api(
        process.env.REACT_APP_API_URL + "check-connection",
        source.token,
        null,
        null,
        "GET"
      ).then((response) => {
        if (response.positive) {
            setConnected(true)
            setLoading(false)
        } else {
            setConnected(false)
            setLoading(false)
        }
      });
  
      return () => {
        source.cancel("Operation canceled by the user.");
      };

  },[setLoading])
  if (connected) {
    return children;
  } else {
    return (
        <Routes>
            <Route path="*" element={<ConnectionFailed />} />
        </Routes>
        );
  }

}

export default ConnectionChecker;
