import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import { api } from "../scripts/api";
import { useContext, useEffect, useState } from "react";
import Song from "../pageElements/Song";
import GlobalContext from "../components/GlobalContext";
import Paging from "../components/Paging";

function Main() {
  const { setLoading } = useContext(GlobalContext);
  const [settings, setSettings] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "get-main-webpage",
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        setSettings(response.responseData);
      } else {
        navigate("/internal-server-error");
      }
    });
  }, []);
  return (
    <>
      <div
        className="banerContainer"
        style={{ backgroundImage: "url(/assets/images/main_background.png)" }}
      >
        <div className="banerOverlay"></div>
        <div className="mainWebpageContent">
          <div className="mainWebpageContentLogoField">
            <Logo />
          </div>
        </div>
      </div>
      <div className="defaultFormContainer">
        <div
          className="defaultForm"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <section className="defaultSection">
            <section className="defaultSection">
              <p className="hugeHeadline">
                {(process.env.REACT_CONTEST_FULL || "" ).toUpperCase()}
              </p>
            </section>
            <div className="regularText">
            <Logo className="formLogo" />
            <br />
              <br />
              Hello! I'm CinCin, a passionate Eurovision enthusiast, and I'm
              thrilled to introduce the Worldvision Song Contest By You! This is
              a unique, virtual competition where you can unleash your musical
              creativity and represent any country you desire! It's a fantastic
              opportunity for Eurovision fans and music lovers worldwide to
              showcase their talents, celebrate diversity, and most importantly,
              enjoy an exhilarating musical experience.
              <br />
              <br />
              Whether you're a singer, a songwriter, or a music aficionado, the
              Worldvision Song Contest By You is the perfect stage for you. Dive
              into a world of music, discover new artists, and join a community
              that shares your passion for the Eurovision spirit.
              <br />
              <br />
              What's more, you can submit your own songs or even those generated
              by AI, making it feel just like the real Eurovision!
              <br />
              <br />
              Ready to embark on this musical journey? To participate, stay
              updated on the latest contest news, or engage in lively
              discussions, be sure to visit our official website
              https://worldvision.cincin.net.pl and join our vibrant Discord
              community at https://discord.com/invite/ZjvGqPkdXY. Let's make the
              Worldvision Song Contest By You an unforgettable musical
              celebration! 🎶🌍🎉
            </div>
          </section>
          <section className="defaultSection">
            <Link
              className="button1 horizontalMargins"
              to={"https://discord.com/invite/ZjvGqPkdXY"}
            >
              Discord
            </Link>
            <Link
              className="button1 horizontalMargins"
              to={"https://www.youtube.com/channel/UCc_4KH_nM9tpA1RBCXBzhKw"}
            >
              Youtube
            </Link>
          </section>
        </div>
      </div>
      <div className="defaultFormContainer secondFormContainer">
        <div
          className="defaultForm"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <section className="defaultSection">
            <section className="defaultSection">
              <p className="hugeHeadline">
                EXPLORE {(process.env.REACT_CONTEST || "" ).toUpperCase()}
              </p>
            </section>
          </section>
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                api(
                  process.env.REACT_APP_API_URL + "get-random-country",
                  null,
                  null,
                  null,
                  "GET"
                ).then((response) => {
                  if (response.positive) {
                    navigate(`/countries/${response.responseData}`);
                  } else {
                    navigate("/internal-server-error");
                  }
                });
              }}
            >
              Random Country
            </button>
          </section>
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                api(
                  process.env.REACT_APP_API_URL + "get-random-song",
                  null,
                  null,
                  null,
                  "GET"
                ).then((response) => {
                  if (response.positive) {
                    navigate(`/songs/${response.responseData}`);
                  } else {
                    navigate("/internal-server-error");
                  }
                });
              }}
            >
              Random Song
            </button>
          </section>
          <section className="defaultSection">
            <button
              className="button1"
              onClick={() => {
                api(
                  process.env.REACT_APP_API_URL + "get-random-user",
                  null,
                  null,
                  null,
                  "GET"
                ).then((response) => {
                  if (response.positive) {
                    navigate(`/users/${response.responseData}`);
                  } else {
                    navigate("/internal-server-error");
                  }
                });
              }}
            >
              Random User
            </button>
          </section>
        </div>
      </div>

      {settings && (
        <div className="defaultFormContainer secondFormContainer">
          <div
            className="defaultForm"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">SONG OF THE DAY</p>
              </section>
            </section>
            <section className="defaultSection">
              <div className="defaultCenteredContainer">
                <div className="margin_container">
                  <Song songID={settings.settings.song_of_the_day} />
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      {settings && settings.songs && (
        <div className="defaultFormContainer secondFormContainer">
          <div
            className="defaultForm defaultWider"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <section className="defaultSection">
              <section className="defaultSection">
                <p className="hugeHeadline">
                  EDITION {settings.settings.current_edition} SONGS
                </p>
              </section>
            </section>
            <section className="defaultSection">
              <div className="defaultCenteredContainer">
                <EditionContainer allSongs={settings.songs} />
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

function EditionContainer({ allSongs }) {
  const [displayedSongs, setDisplayedSongs] = useState([]);

  return (
    <section className="defaultSection">
      <section className="photoLibraryContainer">
        {displayedSongs.map((song) => {
          return (
            <div key={song.song_id} className="margin_container">
              <Song songID={song.song_id} />
            </div>
          );
        })}
      </section>

      <Paging
        elementsPerPage={3}
        passingFunction={(data) => {
          setDisplayedSongs(data);
        }}
        data={allSongs}
      />
    </section>
  );
}

export default Main;
