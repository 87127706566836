import { Link } from "react-router-dom";
import React from "react";

function Unauthorized() {
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">UNAUTHORIZED</p>
          </section>
          <div className="regularText">
            You are not authorized to access this page.
            
          </div>
        </section>
        <section className="defaultSection">
        <Link className="button1" to="/main">
              Main page
        </Link>
        </section>
      </form>
    </div>
  );
}

export default Unauthorized;
