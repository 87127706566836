import { Link, useNavigate } from "react-router-dom";
import React, { useState, useContext } from "react";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";

function ForgotPassword() {
  const { setLoading } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <section className="defaultSection">
            <p className="hugeHeadline">RESTORING ACCOUNT</p>
          </section>
          <div className="regularText">
            A link with which you can change your password will be sent to your
            email
          </div>
        </section>
        <section className="defaultSection">
          <div className="defaultInputContainer">
            Your email address:{" "}
            <input
              name="username"
              type="text"
              className="defaultInput"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              setLoading(true);
              api(
                process.env.REACT_APP_API_URL + "create_change_link_email",
                null,
                { email: email }
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  navigate("/link-sent");
                } else {
                  if (!("alert" in response.responseData.data)) {
                    navigate("/internal-server-error");
                  }
                }
              });
            }}
          >
            Send link to change password
          </button>
        </section>
        <section className="defaultSection">
          <Link className="button1" to="/main">
            Main page
          </Link>
        </section>
      </form>
    </div>
  );
}

export default ForgotPassword;
