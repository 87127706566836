import { useState, useEffect } from "react";

function Paging({
  data = [],
  elementsPerPage = 1,
  passingFunction = (item) => {
    console.log(item);
  },
  showPages = true,
}) {
  const [page, setPage] = useState(1);
  const [pagedData, setPagedData] = useState([]);
  const [pagesButtons, setPagesButtons] = useState([]);

  useEffect(() => {
    const start = (page - 1) * elementsPerPage;
    const end = start + elementsPerPage;
    const currentPageData = data.slice(start, end);
    passingFunction(currentPageData);
    const allPages = [];
    for (let i = 0; i < data.length; i += elementsPerPage) {
      allPages.push(data.slice(i, i + elementsPerPage));
    }

    if (JSON.stringify(allPages) !== JSON.stringify(pagedData)) {
      setPagedData(allPages);
    }

    const min = 1;
    const max = allPages.length;
    const pages = [];

    let i = 1;
    while (pages.length < 2 && page - i >= min) {
      pages.unshift(page - i);
      i++;
    }

    pages.push(page);

    i = 1;
    while (pages.length < 5 && page + i <= max) {
      pages.push(page + i);
      i++;
    }

    i = 1;
    while (pages.length < 5 && page - i - 2 >= min) {
      pages.unshift(page - i - 2);
      i++;
    }

    setPagesButtons(pages);
  }, [elementsPerPage, page, pagedData, data]);
  // passingFunction,data
  return (
    <div className="pagingElements">
      <button
        onClick={() => {
          page > 1 && setPage((prevPage) => prevPage - 1);
        }}
        className="button1 pagingElement"
      >
        <b>{"<"}</b>
      </button>

      {showPages
        ? pagesButtons.map((pageNumber, index) => {
            return (
              <button
                key={"strona" + index}
                onClick={() => setPage(pageNumber)}
                className={
                  page === pageNumber
                    ? "button2 pagingElement"
                    : "button1 pagingElement"
                }
              >
                {pageNumber}
              </button>
            );
          })
        : null}

      <button
        onClick={() => {
          page < pagedData.length && setPage((prevPage) => prevPage + 1);
        }}
        className="button1 pagingElement"
      >
        <b>{">"}</b>
      </button>
    </div>
  );
}

export default Paging;
