import { useState, useContext, useEffect } from "react";
import FileUploader from "../components/FileUploader";
import PhotoBox from "../components/PhotoBox";
import GlobalContext from "./GlobalContext";
import { fileReader } from "../scripts/fileReader";


function CustomTemplate({ passingFunction, formData, passImage }) {

  const [primaryColour, setPrimaryColour] = useState(formData.primaryColour);
  const [textColour, setTextColour] = useState(formData.textColour);
  const [backgroundColour, setBackgroundColour] = useState(
    formData.backgroundColour
  );
  const { setTheme } = useContext(GlobalContext);
  const handlePrimaryColourChange = (e) => {
    setPrimaryColour(e.target.value);
    passingFunction(e);
    setTheme((prevState) => ({
      ...prevState,
      primaryColour: e.target.value,
    }));
  };

  const handleTextColourChange = (e) => {
    setTextColour(e.target.value);
    passingFunction(e);
    setTheme((prevState) => ({
      ...prevState,
      textColour: e.target.value,
    }));
  };

  const handleBackgroundColourChange = (e) => {
    setBackgroundColour(e.target.value);
    passingFunction(e);
    setTheme((prevState) => ({
      ...prevState,
      backgroundColour: e.target.value,
    }));
  };

  const [image, setImage] = useState(formData.backgroundImage);

  useEffect(()=>{
    if (formData.backgroundImage instanceof File && (formData.backgroundImage.type.startsWith('image/'))){
      fileReader(formData.backgroundImage, (dataUrl) => {
        setImage(dataUrl);
    });
    } 
  },[formData.image])
  return (
    <>
      <div className="defaultInputContainer">
        Primary colour:{" "}
        <input
          name="primaryColour"
          type="color"
          className="defaultInput"
          onChange={handlePrimaryColourChange}
          value={primaryColour}
        />
      </div>
      <div className="defaultInputContainer">
        Text colour:{" "}
        <input
          name="textColour"
          type="color"
          className="defaultInput"
          onChange={handleTextColourChange}
          value={textColour}
        />
      </div>
      <div className="defaultInputContainer">
        Background colour:{" "}
        <input
          name="backgroundColour"
          type="color"
          className="defaultInput"
          onChange={handleBackgroundColourChange}
          value={backgroundColour}
        />
      </div>

      {image === "" ? (
        <div className="defaultInputContainer centeredInputContainer">
          <FileUploader
            id={"backgroundReader"}
            returnFunction={(item) => {
              fileReader(item, (dataUrl) => {
                setTheme((prevState) => ({
                  ...prevState,
                  backgroundImage: dataUrl,
                }));
                setImage(dataUrl);
                passImage(item);
            });
            }}
            label="Upload background image"
            accept="image/*"
            maxSize={2*1024 * 1024}
          />
        </div>
      ) : (
        <>
          <div className="defaultInputContainer centeredInputContainer">
            <button
              className="button1"
              onClick={() => {
                setImage("");
                passImage("");
                setTheme((prevState) => ({
                  ...prevState,
                  backgroundImage: "",
                }));
              }}
            >
              Remove background image
            </button>
          </div>
          <div className="defaultInputContainer centeredInputContainer">
            <PhotoBox image={image} />
          </div>
        </>
      )}
    </>
  );
}

export default CustomTemplate;
