import { useNavigate } from "react-router-dom";
import React, { useState, useContext } from "react";
import ChoiceField from "../components/ChoiceField";
import FileUploader from "../components/FileUploader";
import axios from "axios";
import { api } from "../scripts/api";
import GlobalContext from "../components/GlobalContext";


function AddCountry() {
  const navigate = useNavigate();
  const {setLoading} = useContext(GlobalContext);
  const [countryData, setCountryData] = useState({ continent: "1" });
  return (
    <div className="defaultFormContainer">
      <form
        className="defaultForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="defaultSection">
          <div className="headInfo">Country Info:</div>
          <div className="outerFlexContainer">
            <div className="formFields">
              <div className="defaultInputContainer">
                Country name:{" "}
                <input
                  name="country_name"
                  type="text"
                  className="defaultInput"
                  onChange={(e) => {
                    setCountryData((previous) => ({
                      ...previous,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="defaultInputContainer">
                Country database name:{" "}
                <input
                  name="database_name"
                  type="text"
                  className="defaultInput"
                  onChange={(e) => {
                    setCountryData((previous) => ({
                      ...previous,
                      database_name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="defaultInputContainer">
                Country emoji:{" "}
                <input
                  name="emoji"
                  type="text"
                  className="defaultInput"
                  onChange={(e) => {
                    setCountryData((previous) => ({
                      ...previous,
                      emoji: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="defaultInputContainer">
                Discord emoji:{" "}
                <input
                  name="discord_emoji"
                  type="text"
                  className="defaultInput"
                  onChange={(e) => {
                    setCountryData((previous) => ({
                      ...previous,
                      discord_emoji: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="defaultInputContainer">
                Continent:
                <ChoiceField
                  data={[
                    {
                      name: "Europe",
                      key: "continent1",
                    },
                    {
                      name: "Asia",
                      key: "continent2",
                    },
                    {
                      name: "Africa",
                      key: "continent3",
                    },
                    {
                      name: "North America",
                      key: "continent4",
                    },
                    {
                      name: "South America",
                      key: "continent5",
                    },
                    {
                      name: "Oceania",
                      key: "continent6",
                    },
                    {
                      name: "Antarctica",
                      key: "continent7",
                    },
                  ]}
                  passingFunction={(item) => {
                    setCountryData((previous) => ({
                      ...previous,
                      continent: item.key.replace("continent", ""),
                    }));
                  }}
                />
              </div>
              <div className=" defaultCenteredContainer">
                <FileUploader
                  key={"reader5"}
                  returnFunction={(item) => {
                    setCountryData((previous) => ({ ...previous, flag: item }));
                  }}
                  label="Upload flag"
                  accept="image/*"
                  maxSize={1024 * 1024}
                />
              </div>
              <div className=" defaultCenteredContainer">
                <FileUploader
                  key={"reader6"}
                  returnFunction={(item) => {
                    setCountryData((previous) => ({ ...previous, background: item }));
                  }}
                  label="Upload background photo"
                  accept="image/*"
                  maxSize={1024 * 1024}
                  id={"fileinput2"}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="defaultSection">
          <button
            className="button1"
            onClick={() => {
              let dataToSend = new FormData();

              // Loop through formData to add fields to FormData
              for (let key in countryData) {
                if (countryData.hasOwnProperty(key)) {
                  if (countryData[key] instanceof File) {
                    dataToSend.append(key, countryData[key], countryData[key].name); 
                  } else {
                    dataToSend.append(key, countryData[key] ); 
                  }
                }
              }
              setLoading(true);
              const CancelToken = axios.CancelToken;
              const source = CancelToken.source();

              api(
                process.env.REACT_APP_API_URL + "add-country",
                source.token,
                dataToSend
              ).then((response) => {
                setLoading(false);
                if (response.positive) {
                  navigate("/owner");
                }
                else {
                  alert("Fail!")
                }
              });
            }}
          >
            Add country
          </button>
        </section>
      </form>
    </div>
  );
}

export default AddCountry;
