import { ReactComponent as Logo } from "../assets/images/svg/logo.svg";
import { ReactComponent as Discord } from "../assets/images/svg/discord.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Youtube } from "../assets/images/svg/youtube.svg";


import { Link } from 'react-router-dom';

function Footer() {

    return (
        <footer>
            <div className="footerColumn">
                <a className="textWithIcon" href="https://www.discord.gg/ZjvGqPkdXY" target="_blank" rel="noopener noreferrer"><Discord className="footerIcon"/>Discord Server</a>
                <a className="textWithIcon" href="https://www.youtube.com/channel/UCc_4KH_nM9tpA1RBCXBzhKw" target="_blank" rel="noopener noreferrer"><Youtube className="footerIcon" />@cincinsworldvisionsongcont2399</a>
                <a className="textWithIcon" href="mailto:worldvision@cincin.net.pl" target="_blank" rel="noopener noreferrer"><Mail className="footerIcon" />worldvision@cincin.net.pl</a>
            </div>
            <div className="footerColumn">
                <Link className="footerLogo" to="/main">
                    <Logo />
                </Link>
            </div>
        </footer>
    );
}

export default Footer;
