import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Flag from "../components/Flag";
import Song from "../pageElements/Song";
import { api } from "../scripts/api";
import { positionNumber } from "../scripts/positionNumber";
import GlobalContext from "../components/GlobalContext";
import ProfilePicture from "../components/ProfilePicture";

function UserPage() {
  const [userInfo, setUserInfo] = useState({});
  const params = useParams();
  const user = params.user;
  const navigate = useNavigate();
  const { setLoading } = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);
    api(
      process.env.REACT_APP_API_URL + "user-webpages/" + user,
      null,
      null,
      null,
      "GET"
    ).then((response) => {
      setLoading(false);
      if (response.positive) {
        setUserInfo(response.responseData);
      } else {
        if (response.responseData.data.type === "not_found") {
          navigate("/page-not-found");
        } else {
          navigate("/internal-server-error");
        }
      }
    });
  }, [user, navigate, setLoading]);
  return (
    <>
      {userInfo && (
        <div className="columnContainer">
          <div className="infoColumn">
            <div className="countryAdditionalInfo">
              <section className="defaultSection">
                <p className="countryLabelInfo">
                  <ProfilePicture
                    image={userInfo.pfp}
                    style={{
                      width: "70px",
                      aspectRatio: "1/1",
                      marginRight: "10px",
                      border: "solid 1px var(--text-contrast)",
                    }}
                  />
                </p>
              </section>
              {userInfo.debut ? (
                <>
                                  <div className="countryInfoLeft">
                    User: {userInfo.name}
                  </div>
                  <div className="countryInfoLeft">
                    Debut: Edition {userInfo.debut}
                  </div>
                  <div className="countryInfoLeft">
                    Most recent participation: Edition {userInfo.latest_entry}
                  </div>
                  <div className="countryInfoLeft">
                    Number of participations:{" "}
                    {userInfo.number_of_participations}
                  </div>
                  <div className="countryInfoLeft">🥇: {userInfo.wins}</div>
                  <div className="countryInfoLeft">
                    🥈: {userInfo.second_places}
                  </div>
                  <div className="countryInfoLeft">
                    🥉: {userInfo.third_places}
                  </div>
                </>
              ) : (
                <div className="countryInfoLeft">User didn't debut yet.</div>
              )}
            </div>
          </div>
          <div className="defaultFormContainer">
            <div className="defaultForm widePage">
              <div
                className="countryPhoto userPhoto"
                style={{
                  backgroundImage: `url("${userInfo.pfp}")`,
                }}
              >
                <div className="hugeHeadline">
                  <ProfilePicture
                    image={userInfo.pfp}
                    style={{
                      height: "10vh",
                      aspectRatio: '1/1',
                      marginRight: "10px",
                      border: "solid 1px var(--text-contrast)",
                    }}
                    className="hideOnMobile"
                  />
                  <div 
                  className="wrap"
                  >
                  {userInfo.name}

                  </div>
                </div>
                <div className="countryPhotoOverlay"></div>
              </div>
              <section className="defaultSection">
                {userInfo.debut ? (
                  <>
                    {userInfo.participated && (
                      <>
                        <div className="headInfo biggerInfo">Best result:</div>
                        <div className="headInfo">
                          {positionNumber(userInfo.best_entry.placement)} in{" "}
                          {userInfo.best_entry.show_type === 1
                            ? "Heat"
                            : userInfo.best_entry.show_type === 2
                            ? "Semi Final"
                            : userInfo.best_entry.show_type === 3
                            ? "the Grand Final"
                            : null}{" "}
                          {userInfo.best_entry.show_number !== 0 &&
                            userInfo.best_entry.show_number}{" "}
                          of edition {userInfo.best_entry.edition}
                        </div>
                        <div className="defaultCenteredContainer">
                          <Song songID={userInfo.best_entry.id} />
                        </div>
                        <section className="defaultSection marginsSection">
                          <div className="headInfo">
                            User results statistics:
                          </div>
                          <div className="defaultCenteredContainer">
                            <table className="statsTable">
                              <tr>
                                <td></td>
                                <td>
                                  <b>Heats</b>
                                </td>
                                <td>
                                  <b>Semi Finals</b>
                                </td>
                                <td>
                                  <b>Grand Finals</b>
                                </td>
                                <td>
                                  <b>General</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Average placement</td>
                                <td>
                                  {userInfo.heats_stats.place
                                    ? parseFloat(userInfo.heats_stats.place)
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.semifinals_stats.place
                                    ? parseFloat(
                                        userInfo.semifinals_stats.place
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.place
                                    ? parseFloat(userInfo.final_stats.place)
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.general_stats.place
                                    ? parseFloat(userInfo.general_stats.place)
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Average televoting placement</td>
                                <td>-</td>
                                <td>
                                  {userInfo.semifinals_stats
                                    .televoting_placement
                                    ? parseFloat(
                                        userInfo.semifinals_stats
                                          .televoting_placement
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.televoting_placement
                                    ? parseFloat(
                                        userInfo.final_stats
                                          .televoting_placement
                                      )
                                    : "-"}
                                </td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>Average jury voting placement</td>
                                <td>-</td>
                                <td>
                                  {userInfo.semifinals_stats.jury_place
                                    ? parseFloat(
                                        userInfo.semifinals_stats.jury_place
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.jury_place
                                    ? parseFloat(
                                        userInfo.final_stats.jury_place
                                      )
                                    : "-"}
                                </td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>Qualification rate</td>
                                <td>
                                  {userInfo.heats_stats.qualification
                                    ? parseFloat(
                                        userInfo.heats_stats.qualification
                                      ) *
                                        100 +
                                      "%"
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.semifinals_stats.qualification
                                    ? parseFloat(
                                        userInfo.semifinals_stats.qualification
                                      ) *
                                        100 +
                                      "%"
                                    : "-"}
                                </td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>Total ammount of points</td>
                                <td>
                                  {userInfo.heats_stats.total_points
                                    ? userInfo.heats_stats.total_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.semifinals_stats.total_points
                                    ? userInfo.semifinals_stats.total_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.total_points
                                    ? userInfo.final_stats.total_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.general_stats.total_points
                                    ? userInfo.general_stats.total_points
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Total ammount of jury points</td>
                                <td>-</td>
                                <td>
                                  {userInfo.semifinals_stats.total_jury_points
                                    ? userInfo.semifinals_stats
                                        .total_jury_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.total_jury_points
                                    ? userInfo.final_stats.total_jury_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.general_stats.total_jury_points
                                    ? userInfo.general_stats.total_jury_points
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Total ammount of televoting points</td>
                                <td>-</td>
                                <td>
                                  {userInfo.semifinals_stats
                                    .total_televoting_points
                                    ? userInfo.semifinals_stats
                                        .total_televoting_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.total_televoting_points
                                    ? userInfo.final_stats
                                        .total_televoting_points
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.general_stats
                                    .total_televoting_points
                                    ? userInfo.general_stats
                                        .total_televoting_points
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>12 points received</td>
                                <td>
                                  {userInfo.heats_stats.number_of_12
                                    ? userInfo.heats_stats.number_of_12
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.semifinals_stats.number_of_12
                                    ? userInfo.semifinals_stats.number_of_12
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.final_stats.number_of_12
                                    ? userInfo.final_stats.number_of_12
                                    : "-"}
                                </td>
                                <td>
                                  {userInfo.general_stats.number_of_12
                                    ? userInfo.general_stats.number_of_12
                                    : "-"}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </section>
                        <section className="defaultSection marginsSection">
                          <div className="headInfo">
                            All results of {userInfo.name}:
                          </div>
                          <div className="defaultCenteredContainer">
                            <div style={{ overflowX: "auto" }}>
                              <table className="statsTable">
                                <tr>
                                  <td>
                                    <b>Edition</b>
                                  </td>
                                  <td>
                                    <b>Country</b>
                                  </td>
                                  <td>
                                    <b>Show</b>
                                  </td>
                                  <td>
                                    <b>Running order</b>
                                  </td>
                                  <td>
                                    <b>Number of 12 points</b>
                                  </td>
                                  <td>
                                    <b>Qualification</b>
                                  </td>
                                  <td>
                                    <b>Jury points</b>
                                  </td>
                                  <td>
                                    <b>Jury placement</b>
                                  </td>
                                  <td>
                                    <b>Televoting points</b>
                                  </td>
                                  <td>
                                    <b>Televoting placement</b>
                                  </td>
                                  <td>
                                    <b>Total Points</b>
                                  </td>
                                  <td>
                                    <b>Placement</b>
                                  </td>
                                </tr>
                                {userInfo.results_history.map((element) => {
                                  return (
                                    <tr>
                                      <td>{element.edition}</td>
                                      <td className="countryLinkFlag">
                                        <Link
                                          to={
                                            "/songs/" + element.song_display_id
                                          }
                                        >
                                          <Flag image={element.country_id} />
                                        </Link>
                                      </td>
                                      <td className="noWrap">
                                        {element.show_type === 1
                                          ? "Heat"
                                          : element.show_type === 2
                                          ? "Semi Final"
                                          : element.show_type === 3
                                          ? "the Grand Final"
                                          : element.show_type === 4
                                          ? "General result"
                                          : null}{" "}
                                        {element.show_number !== 0 &&
                                          element.show_type !== 4 &&
                                          element.show_number}
                                      </td>
                                      <td>
                                        {element.running_order
                                          ? element.running_order
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.number_of_12s !== null
                                          ? element.number_of_12s
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.qualification === 1
                                          ? "Q"
                                          : element.qualification === 2
                                          ? "NQ"
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.show_type === 1
                                          ? "-"
                                          : element.jury_results !== null
                                          ? element.jury_results
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.show_type === 1
                                          ? "-"
                                          : element.jury_placement !== null
                                          ? element.jury_placement
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.televoting_points !== null
                                          ? element.televoting_points
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.televoting_placement !== null
                                          ? element.televoting_placement
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.full_results !== null
                                          ? element.full_results
                                          : "-"}
                                      </td>
                                      <td>
                                        {element.placement !== null
                                          ? element.placement
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </section>

                        <section className="defaultSection marginsSection">
                          <div className="headInfo">
                            {userInfo.name} jury voting history:
                          </div>
                          <div className="defaultCenteredContainer">
                            <div style={{ overflowX: "auto" }}>
                              <table className="statsTable">
                                <tr>
                                  <td>Edition</td>
                                  <td>Show</td>
                                  <td>Voting Country</td>
                                  <td>1</td>
                                  <td>2</td>
                                  <td>3</td>
                                  <td>4</td>
                                  <td>5</td>
                                  <td>6</td>
                                  <td>7</td>
                                  <td>8</td>
                                  <td>10</td>
                                  <td>12</td>
                                </tr>
                                {
                                  userInfo.voting_history.map((item) => {
                                    return (
                                      <tr
                                        key={
                                          userInfo.name +
                                          "_" +
                                          item.jury_voting_edition +
                                          "_" +
                                          item.jury_voting_show_type +
                                          "_" +
                                          item.jury_voting_show_number
                                        }
                                      >
                                        <td>{item.jury_voting_edition}</td>
                                        <td style={{ whiteSpace: "noWrap" }}>
                                          {item.jury_voting_show_type === 1
                                            ? "Heat"
                                            : item.jury_voting_show_type === 2
                                            ? "Semi Final"
                                            : item.jury_voting_show_type === 3
                                            ? "the Grand Final"
                                            : null}{" "}
                                          {item.jury_voting_show_number !== 0 &&
                                            item.jury_voting_show_number}
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/countries/" +
                                              item.voting_db
                                            }
                                          >
                                            <Flag image={item.country_voting} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p1_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p1} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p2_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p2} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p3_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p3} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p4_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p4} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p5_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p5} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p6_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p6} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p7_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p7} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p8_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p8} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p10_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p10} />
                                          </Link>
                                        </td>
                                        <td className="countryLinkFlag">
                                          <Link
                                            to={
                                              "/songs/" +
                                              item.p12_database_name +
                                              "-" +
                                              item.jury_voting_edition
                                            }
                                          >
                                            <Flag image={item.p12} />
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div>
                          </div>
                        </section>
                      </>
                    )}
                    <section className="defaultSection">
                      <div className="headInfo">
                        All songs sent by {userInfo.name}:
                      </div>
                      <div className="photoLibraryContainer">
                        {userInfo.participations.map((item) => {
                          return (
                            <div
                              key={item.song_id}
                              className="margin_container"
                            >
                              <Song songID={item.song_id} />
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  </>
                ) : (
                  <p className="hugeHeadline">
                    User has not debuted yet
                  </p>
                )}
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserPage;
