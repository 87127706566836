import Template from "../components/Template";
import Paging from "../components/Paging";
import { useEffect, useState } from "react";
import { api } from "../scripts/api";

function Themes({ passingFunction }) {
  const [Data, setData] = useState([]);
  const [slicedData, setSlicedData] = useState([]);
  useEffect(()=>{
    api(
      process.env.REACT_APP_API_URL + "themes", null, null, null, "GET"
    ).then((response) => {
      if(response.positive){
        setData(response.responseData)
      } 
    });
  },[])
  return (
    <section className="defaultSection">
      <div className="templatesContainer">
        {slicedData.map((element) => (
          <Template
            title={element.name}
            key={element.key}
            primary={element.primary}
            text={element.text}
            contrast={element.contrast}
            image={element.image}
            passingFunction={(item) => {
              passingFunction((prevState) => ({
                ...prevState,
                ...item,
              }));
            }}
          />
        ))}
      </div>
      <Paging
        elementsPerPage={4}
        passingFunction={(data1) => {
          setSlicedData(data1);
        }}
        data={Data}
      />
    </section>
  );
}
export default Themes;
