import { useContext } from "react";
import GlobalContext from "../components/GlobalContext";

// Custom hook for logout
export const useLogout = () => {
  const { setUserData, setTheme } = useContext(GlobalContext);

  const logout = () => {
    localStorage.setItem("token", null);
    setUserData({
      role: "guest",
      username: "",
      email: "",
      discordID: "",
      country: "",
      dateOfBirth: "",
      profilePicture: "assets/images/profile_pictures/default.png",
    });
    setTheme({
      primaryColour: "#231d85",
      textColour: "#030303",
      backgroundColour: "#ffffff",
      backgroundImage: "assets/images/background_pictures/templates/background.png",
    });
  };

  return logout;
};
