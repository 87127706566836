import React, { useMemo, useState, useRef, useEffect } from "react";
import "../styles/choiceFields.css";
import { Search } from "../scripts/search";

function SearchChoice({
  children,
  nameColumn = "name",
  keyColumn = "key",
  childProp = "image",
  passToChildKey = "image",
  passingFunction = (item) => {
    console.log(item);
  },
  removingFunction = (item) => {
    console.log(item);
  },
  data = [],
  value = null
}) {
  const [choice, setChoice] = useState(null);
  const [typedText, setTypedText] = useState("");
  const [isTextActive, setTextStatus] = useState(false);
  const searchValues = useMemo(() => {
    return Search(typedText, data, nameColumn);
  }, [typedText, data, nameColumn]);
  const containerRef = useRef(null);
  const handleClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setTextStatus(false);
    }
  };

  useEffect(() => {
    // Dodawanie event listenera do obiektu window
    window.addEventListener("click", handleClick);

    // Usuwanie event listenera, gdy komponent zostanie odmontowany
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []); // Pusty array ja

  useEffect(()=>{
    if(value !== null){
      setTextStatus(false);
      setChoice(value);
      setTypedText("");
    }
  }, [value])
  return (
    <div className="searchChoiceContainer" ref={containerRef}>
      {choice ? (
        <div key={choice[keyColumn]}>
          <div className="choiceItem chosenItem chosenItemMargin">
            {React.Children.count(children) !== 0
              ? React.cloneElement(React.Children.toArray(children)[0], {
                  [childProp]: choice[passToChildKey],
                })
              : null}
            <div
              className="choiceItemText"
              style={
                React.Children.count(children) !== 0
                  ? { marginLeft: "10px" }
                  : {}
              }
              onClick={() => {
                setChoice(null);
                setTypedText("");
                removingFunction(choice);
              }}
            >
              {choice[nameColumn]}
            </div>
          </div>
        </div>
      ) : (
        <>
          <input
            name="date"
            type="text"
            className="defaultInput"
            value={typedText}
            onChange={(event) => {
              setTypedText(event.target.value);
            }}
            onFocus={() => {
              setTextStatus(true);
            }}
            autocomplete="off"
          />
          {typedText
            ? searchValues.length > 0
              ? isTextActive && (
                  <div className="choiceItemsContainer">
                    {searchValues.map((element) => {
                      return (
                          <div
                            className="choiceItem"
                            onClick={() => {
                              setChoice(element.item);
                              passingFunction(element.item);
                            }}
                            key={element.item[keyColumn]}
                          >
                            {React.Children.count(children) !== 0
                              ? React.cloneElement(
                                  React.Children.toArray(children)[0],
                                  { [childProp]: element.item[passToChildKey] }
                                )
                              : null}
                            <div
                              className="choiceItemText"
                              style={
                                React.Children.count(children) !== 0
                                  ? { marginLeft: "10px" }
                                  : {}
                              }
                            ><span>

                              {element.item[nameColumn].substring(
                                0,
                                element.index
                              )}
                              <b>
                                {element.item[nameColumn].substring(
                                  element.index,
                                  element.index + typedText.length
                                )}
                              </b>
                              {element.item[nameColumn].substring(
                                element.index + typedText.length
                              )}
                            </span>

                            </div>
                          </div>
                      );
                    })}
                  </div>
                )
              : isTextActive && (
                  <div className="choiceItemsContainer">
                    <div className="searchNotFound">Not found</div>
                  </div>
                )
            : null}
        </>
      )}{" "}
    </div>
  );
}

export default SearchChoice;
