function urlBase64ToUint8Array(base64String) {
    // Usuń nagłówki/stopki PEM i białe znaki z klucza publicznego VAPID
    const base64StringWithoutHeaders = base64String
        .replace('-----BEGIN PUBLIC KEY-----', '')
        .replace('-----END PUBLIC KEY-----', '')
        .replace(/\s/g, ''); // Usuwa białe znaki

    const padding = '='.repeat((4 - base64StringWithoutHeaders.length % 4) % 4);
    const base64 = (base64StringWithoutHeaders + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const subscribeUser = async (passing) => {
    const swRegistration = await navigator.serviceWorker.ready;
    const subscription = await swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
            'BEWoq2Sza9hDyrjIDGPpo4cvMiVdNRbTCdFajKY_yimtVweBrcCfBxJYjLxfKVj6AjLuAxJKNQ3c4WvKHb69-Gc'
        )
    });

    passing(JSON.stringify(subscription));
};

export const askForPermission = async (passing) => {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult !== 'granted') {
        passing(false);
    } else {
        passing(true);
    }
};
