function SimpleText({
  passingFunction = () => {},
  removingFunction = () => {},
}) {
  return (
    <div className="defaultCenteredContainer">
      <input
        type="text"
        className="defaultInput"
        onChange={(e)=>{passingFunction(e.target.value)}}
      />
    </div>
  );
}

export default SimpleText;
