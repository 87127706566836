import { Navigate, Route, Routes } from "react-router-dom";
import React, { useContext } from "react";
import GlobalContext from "./GlobalContext";
import MaintenanceModePage from "../pages/MaintenanceModePage";

function MaintenanceMode({ children }) {
  const { role } = useContext(GlobalContext);
  const { maintenanceMode } = useContext(GlobalContext);

  if (role !== "owner" && role !== "visitor" && maintenanceMode) {
    return (
          <MaintenanceModePage />
        );
  } else {
    return children;
  }

  // Przekieruj użytkownika do strony logowania, jeśli nie jest zalogowany.
  //return <Navigate to={} state={{ from: location }} />;
}

export default MaintenanceMode;
